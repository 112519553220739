import React, {useCallback, useEffect, useState} from "react";
import {Modal, List, InputNumber, Button, Row, Col, Skeleton, Avatar, Typography, Card, Select, Form, Space} from "antd";
import {WarehouseProduct, batchCategories} from "../../../types/WarehouseTypes";
import {LabelOwner, PrepOwner} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";

interface ListItemProps {
    item: WarehouseProduct;
    selected: boolean;
    onChange: (item: WarehouseProduct) => void;
}

const ListItem: React.FC<ListItemProps> = ({item, onChange, selected}) => {
    const [form] = Form.useForm<{quantity: number; labelOwner: LabelOwner; prepOwner: PrepOwner}>();

    useEffect(() => {
        form.setFieldsValue({
            quantity: item.quantity,
            labelOwner: item.labelOwner,
            prepOwner: item.prepOwner,
        });
    }, [item, form]);

    const renderStatRow = (item: WarehouseProduct, key: keyof WarehouseProduct, label: string) => {
        let valueNode = <></>;
        if (key === "batchCategory") {
            valueNode = (
                <Select
                    value={item.batchCategory}
                    options={batchCategories.map((category) => ({
                        value: category,
                        label: <span>{category}</span>,
                    }))}
                    onChange={(value) => onChange({...item, batchCategory: value})}
                    style={{width: 150}}
                />
            );
        } else if (key === "invData" || key === "dimensions" || key === "scanDate") {
            valueNode = <></>;
        } else {
            valueNode = <Typography.Text>{item[key] ?? "n/a"}</Typography.Text>;
        }

        return (
            <List.Item>
                <Row style={{width: "100%"}}>
                    <Col
                        span={8}
                        style={{
                            justifyContent: "end",
                            width: "100%",
                        }}
                    >
                        <Typography.Text
                            type="secondary"
                            style={{
                                fontSize: 10,
                            }}
                        >
                            {label}
                        </Typography.Text>
                    </Col>
                    <Col span={16}>{valueNode}</Col>
                </Row>
            </List.Item>
        );
    };
    return (
        <Card
            style={{
                // Add border to every item to prevent the list from jumping, only change color if selected
                border: selected ? "2px solid #28682EFF" : "2px solid #00000000",
                borderRadius: "5px",
            }}
        >
            <Row style={{width: "100%", paddingLeft: 4}}>
                <Col span={10}>
                    <Row>
                        <Space style={{width: "100%", justifyContent: "center"}}>
                            {!item.imgURL ? (
                                <Skeleton.Image style={{height: 150}} />
                            ) : (
                                <Avatar shape="square" size={150} src={item.imgURL} />
                            )}
                        </Space>
                    </Row>
                    <Row
                        style={{
                            marginTop: 10,
                            width: "100%",
                        }}
                    >
                        {selected ? (
                            <Form
                                style={{width: "90%"}}
                                labelCol={{span: 10}}
                                wrapperCol={{span: 14}}
                                form={form}
                                onValuesChange={(changedValues, values) => {
                                    onChange({...item, ...values});
                                }}
                            >
                                <Form.Item label="Quantity" name="quantity">
                                    <InputNumber min={1} style={{width: "100%"}} />
                                </Form.Item>
                                <Form.Item label="Label Owner" name="labelOwner">
                                    <Select
                                        defaultValue={LabelOwner.Seller}
                                        options={Object.values(LabelOwner).map((labelOwner) => ({
                                            value: labelOwner,
                                            label: <span>{labelOwner}</span>,
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item label="Prep Owner" name="prepOwner">
                                    <Select
                                        defaultValue={PrepOwner.Seller}
                                        options={Object.values(PrepOwner).map((prepOwner) => ({
                                            value: prepOwner,
                                            label: <span>{prepOwner}</span>,
                                        }))}
                                    />
                                </Form.Item>
                            </Form>
                        ) : (
                            <Button onClick={() => onChange(item)} style={{width: 150, height: 50}}>
                                Select
                            </Button>
                        )}
                    </Row>
                </Col>
                <Col span={14}>
                    <List dataSource={properties} renderItem={(property) => renderStatRow(item, property.key, property.label)} />
                </Col>
            </Row>
        </Card>
    );
};

interface ExtractedItemsDialogProps {
    items: WarehouseProduct[];
    open: boolean;
    onClose: () => void;
    onOk: (updatedItem: WarehouseProduct) => void;
}

const properties: {label: string; key: keyof WarehouseProduct}[] = [
    {label: "Name", key: "name"},
    {label: "ASIN", key: "asin"},
    {label: "UPC", key: "upc"},
    {label: "SKU", key: "sku"},
    {label: "Inv Quantity", key: "invQuantity"},
    {label: "Batch Category", key: "batchCategory"},
];

const ExtractedItemsDialog: React.FC<ExtractedItemsDialogProps> = ({items, open, onClose, onOk}) => {
    const [selectedItem, setSelectedItem] = useState<WarehouseProduct | null>(null);
    const [itemsLocal, setItemsLocal] = useState<WarehouseProduct[]>(items);

    const handleOk = useCallback(() => {
        if (!selectedItem) return;
        onOk({...selectedItem});
    }, [onOk, selectedItem]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter" && open) {
                handleOk();
            }
        },
        [handleOk, open]
    );

    useEffect(() => {
        if (open) {
            setItemsLocal([...items]);
            if (items.length > 0) {
                setSelectedItem(items[0]);
            } else {
                setSelectedItem(null);
            }
        }
    }, [open, items]);

    useEffect(() => {
        if (open) {
            window.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown, open]);

    return (
        <Modal title="Extracted Items" open={open} onOk={handleOk} onCancel={onClose} width={600}>
            <List
                dataSource={itemsLocal}
                style={{maxHeight: "70vh", overflow: "auto"}}
                renderItem={(item) => (
                    <ListItem
                        item={item}
                        selected={selectedItem?.sku === item.sku}
                        onChange={(newItem) => {
                            setSelectedItem(newItem);
                            setItemsLocal(itemsLocal.map((i) => (i.sku === newItem.sku ? newItem : i)));
                        }}
                    />
                )}
            />
        </Modal>
    );
};

export default ExtractedItemsDialog;
