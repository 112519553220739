import React, {useEffect, useState} from "react";
import {Input, Button, Space} from "antd";
import {CheckCircleOutlined, PrinterOutlined} from "@ant-design/icons";

const PrintButton: React.FC<{onPrintLabel: (quantity: number) => void; printedQuantity?: number; maxQuantity?: number}> = ({
    onPrintLabel,
    printedQuantity,
    maxQuantity,
}) => {
    const [quantity, setQuantity] = useState<number>(1);

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = parseInt(event.target.value);
        setQuantity(newQuantity);
    };

    const handlePrintLabel = () => {
        onPrintLabel(quantity);
    };

    useEffect(() => {
        setQuantity(maxQuantity || 1);
    }, [maxQuantity]);

    return (
        <Space>
            <Input type="number" min={1} style={{width: 60}} value={quantity} onChange={handleQuantityChange} />
            <Button onClick={handlePrintLabel} icon={<PrinterOutlined />} />
            {printedQuantity && maxQuantity && printedQuantity >= maxQuantity && (
                <CheckCircleOutlined style={{fontSize: 25, color: "green"}} />
            )}
        </Space>
    );
};

export default PrintButton;
