import {Column} from "@ant-design/charts";
import {Card} from "antd";
import React from "react";
import {themeConst} from "../../../services/constants";
import {WholesaleItem} from "../../../types/WholesaleItem";
import {sizeIndex} from "../../utilities/common";
import "./Popover.scss";

const curTheme = localStorage.theme === themeConst.LIGHT ? "classic" : "classicDark";

const ParentDistributionPopover: React.FC<{record: WholesaleItem}> = React.memo(({record}) => {
    if (!record.parentDistributionChartData) {
        console.log("No parentDistributionChartData");
        console.log(record);
        return null;
    }
    const chartData = Object.entries(record.parentDistributionChartData)
        .map(([key, value]) => {
            return {
                Size: key,
                Units: value as number,
            };
        })
        .sort((a, b) => sizeIndex(a.Size) - sizeIndex(b.Size));

    const config = {
        data: chartData,
        title: {
            align: "center",
            title: "Units per BuyQTY",
        },
        xField: "Size",
        yField: "Units",
        legend: true,
        label: {
            fill: "white",
            fillOpacity: 1,
            textBaseline: "bottom",
            position: "inside",
            textAlign: "center",
        },
        axis: {
            x: {
                title: "Size",
            },
            y: {
                title: "BuyQTY",
            },
        },

        annotations: chartData.map((entry, _, arr) => {
            const percentageEntry = (entry.Units / arr.reduce((acc, curr) => (acc += curr.Units), 0)) * 100;
            return {
                type: "text",
                data: [entry.Size, entry.Units + 0.1],
                xField: "year",
                yField: "value",
                style: {
                    text: `${percentageEntry.toFixed(2)}%`,
                    textBaseline: "bottom",
                    position: "top",
                    textAlign: "center",
                    fontSize: 14,
                    fill: "white",
                },
                tooltip: false,
            };
        }),
        theme: curTheme,
    };

    return (
        <Card size="small">
            <Column {...config} />
        </Card>
    );
});

export default ParentDistributionPopover;
