import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Modal, Card, DescriptionsProps, Descriptions, Space, Collapse, Table, Row, Col, Button, Popconfirm, Typography, Spin} from "antd";
import {Item, PlacementOption, Shipment} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {useStore} from "../../../../store/useStore";
import {useShallow} from "zustand/react/shallow";

const PlacementInfo: React.FC<{
    placementOption: PlacementOption;
    onSelect: () => void;
}> = ({placementOption, onSelect}) => {
    const discountsAndFees = useMemo(() => {
        return [...placementOption.discounts, ...placementOption.fees].map((item) => ({
            type: item.type,
            amount: item.value.amount,
            description: item.description,
        }));
    }, [placementOption]);

    return (
        <Card>
            <Row>
                <Col span={18}>
                    <Table
                        dataSource={discountsAndFees}
                        columns={[
                            {title: "Type", dataIndex: "type"},
                            {title: "Amount", dataIndex: "amount", render: (amount) => `$${amount.toFixed(2)}`},
                            {title: "Description", dataIndex: "description"},
                        ]}
                        pagination={false}
                    />
                </Col>
                <Col span={6}>
                    <Space
                        direction="vertical"
                        style={{width: "100%", justifyContent: "center", alignItems: "center"}}
                        styles={{item: {justifyContent: "center", alignItems: "center"}}}
                    >
                        <Typography.Text style={{fontSize: 20}}>{placementOption.shipmentIds.length} shipment(s)</Typography.Text>
                        <Button onClick={onSelect}>View</Button>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
};

const ShipmentInfo: React.FC<{
    shipment: Shipment;
    items: Item[];
}> = ({shipment, items}) => {
    const destination = shipment.destination;
    const fields: DescriptionsProps["items"] = [
        // {key: "warehouse_id", label: "Warehouse ID", children: destination.warehouseId},
        {key: "type", label: "Type", children: destination.destinationType},
        {key: "address", label: "Address", children: destination.address?.addressLine1},
        {key: "city", label: "City", children: destination.address?.city},
        {key: "state", label: "State", children: destination.address?.stateOrProvinceCode},
    ];

    return (
        <Card>
            <Space direction="vertical">
                <Descriptions title={`Center ${destination.warehouseId}`} size="small" items={fields} />
                <Collapse>
                    <Collapse.Panel header={`${items.length} items`} key="1">
                        <Table
                            dataSource={items}
                            columns={[
                                {title: "ASIN", dataIndex: "asin"},
                                {title: "SKU", dataIndex: "msku"},
                                {title: "FNSKU", dataIndex: "fnsku"},
                                {title: "Quantity", dataIndex: "quantity"},
                            ]}
                            showHeader={false}
                            pagination={false}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Space>
        </Card>
    );
};

const ShipmentsDialog: React.FC<{
    open: boolean;
    onClose: () => void;
    placementOption: PlacementOption;
    onFinishBatch: () => void;
}> = ({open, onClose, placementOption, onFinishBatch}) => {
    const [loading, setLoading] = useState(true);
    const {receivingData, fetchShipments, confirmPlacementOption} = useStore(
        useShallow((state) => ({
            receivingData: state.packLaterReceivingData,
            fetchShipments: state.fetchShipments,
            confirmPlacementOption: state.confirmPlacementOption,
        }))
    );

    const getShipments = useCallback(async () => {
        if (!receivingData.currentInboundPlanId || !placementOption) {
            return;
        }
        // Check if the shipment is already fetched
        if (receivingData.placementShipments[placementOption.placementOptionId]?.length === placementOption.shipmentIds.length) {
            return;
        }
        setLoading(true);
        await fetchShipments(receivingData.currentInboundPlanId, placementOption.placementOptionId, placementOption.shipmentIds);
        setLoading(false);
    }, [placementOption, fetchShipments, receivingData.currentInboundPlanId, receivingData.placementShipments]);

    useEffect(() => {
        getShipments();
    }, [getShipments]);

    if (!placementOption) {
        return null;
    }

    return (
        <Modal
            title="Shipments"
            open={open}
            width={800}
            style={{maxHeight: "50vh"}}
            footer={
                <>
                    <Button onClick={onClose}>Back</Button>
                    <Popconfirm
                        title="Are you sure you want to confirm this placement?"
                        onConfirm={() => {
                            confirmPlacementOption(placementOption);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button loading={receivingData.confirmPlacementLoading} type="primary" disabled={!!receivingData.confirmedOption}>
                            {receivingData.confirmedOption ? "Confirmed" : "Confirm Placement"}
                        </Button>
                    </Popconfirm>
                    <Button
                        loading={receivingData.finishBatchLoading}
                        type="primary"
                        onClick={onFinishBatch}
                        disabled={!receivingData.confirmedOption}
                    >
                        Finish batch
                    </Button>
                </>
            }
        >
            <Spin spinning={loading}>
                <Space style={{maxHeight: "48vh", overflow: "auto"}} direction="vertical">
                    {receivingData.placementShipments[placementOption.placementOptionId]?.map((shipment, index) => (
                        <ShipmentInfo key={index} shipment={shipment} items={receivingData.placementItems[shipment.shipmentId] || []} />
                    ))}
                </Space>
            </Spin>
        </Modal>
    );
};

const ShipmentPlacementsDialog: React.FC<{
    open: boolean;
    onClose: () => void;
    onFinishBatch: () => Promise<void>;
}> = ({open, onClose, onFinishBatch}) => {
    const {placementOptions} = useStore((state) => ({
        placementOptions: state.packLaterReceivingData.placementOptions,
    }));
    const [chosenPlacement, setChosenPlacement] = useState<PlacementOption | undefined>(undefined);

    return (
        <>
            <Modal title="Placement Options" open={open} width={800} style={{maxHeight: "50vh"}} onCancel={onClose} footer={null}>
                <Space style={{maxHeight: "48vh", overflow: "auto"}} direction="vertical">
                    {placementOptions.map((option, index) => (
                        <PlacementInfo
                            key={index}
                            placementOption={option}
                            onSelect={() => {
                                setChosenPlacement(option);
                            }}
                        />
                    ))}
                </Space>
            </Modal>
            <ShipmentsDialog
                open={!!chosenPlacement}
                onClose={() => setChosenPlacement(undefined)}
                placementOption={chosenPlacement!}
                onFinishBatch={async () => {
                    await onFinishBatch();
                    setChosenPlacement(undefined);
                }}
            />
        </>
    );
};

export default ShipmentPlacementsDialog;
