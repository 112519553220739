import React, {useState} from "react";
import {Modal, Card, Space, Table, Row, Col, Button, Popconfirm, Typography, Tag, notification} from "antd";
import {PackingOption} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {useShallow} from "zustand/react/shallow";
import {useStore} from "../../../store/useStore";

const PackingInfo: React.FC<{
    packingOption: PackingOption;
    onSelect: () => void;
}> = ({packingOption, onSelect}) => {
    const discountsAndFees = React.useMemo(() => {
        return [...packingOption.discounts, ...packingOption.fees].map((item) => ({
            type: item.type,
            amount: item.value.amount,
            description: item.description,
        }));
    }, [packingOption]);

    const statusColor = React.useMemo(() => {
        switch (packingOption.status) {
            case "OFFERED":
                return "processing";
            case "ACCEPTED":
                return "success";
            case "EXPIRED":
                return "error";
            default:
                return "default";
        }
    }, [packingOption.status]);

    const expirationDate = packingOption.expiration ? new Date(packingOption.expiration).toLocaleString() : "No expiration";

    return (
        <Card>
            <Row>
                <Col span={18}>
                    <Typography.Text type="secondary">Expires: {expirationDate}</Typography.Text>
                    <Table
                        dataSource={discountsAndFees}
                        columns={[
                            {title: "Type", dataIndex: "type"},
                            {title: "Amount", dataIndex: "amount", render: (amount) => `$${amount.toFixed(2)}`},
                            {title: "Description", dataIndex: "description"},
                        ]}
                        pagination={false}
                    />
                </Col>
                <Col span={6}>
                    <Space direction="vertical" style={{width: "100%", justifyContent: "center", alignItems: "center"}}>
                        <Tag color={statusColor}>{packingOption.status}</Tag>
                        <Typography.Text style={{fontSize: 20}}>{packingOption.packingGroups.length} group(s)</Typography.Text>
                        <Button onClick={onSelect} disabled={packingOption.status === "EXPIRED"}>
                            View Details
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
};

const GroupItemsDialog: React.FC<{
    open: boolean;
    onClose: () => void;
    packingOption: PackingOption;
    onFinishBatch: () => void;
}> = ({open, onClose, packingOption, onFinishBatch}) => {
    const [loading, setLoading] = useState(false);
    const {packFirstReceivingData, confirmPackingOption} = useStore(
        useShallow((state) => ({
            packFirstReceivingData: state.packFirstReceivingData,
            confirmPackingOption: state.packFirstReceivingActions.confirmPackingOption,
        }))
    );

    return (
        <Modal
            title="Packing Groups"
            open={open}
            width={800}
            style={{maxHeight: "50vh"}}
            footer={
                <>
                    <Button onClick={onClose}>Back</Button>
                    <Popconfirm
                        title="Are you sure you want to confirm this packing option?"
                        onConfirm={async () => {
                            if (!packFirstReceivingData.currentInboundPlanId) {
                                throw new Error("No inbound plan ID set");
                            }
                            await confirmPackingOption(packFirstReceivingData.currentInboundPlanId, packingOption.packingOptionId);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            loading={packFirstReceivingData.isConfirming}
                            disabled={packingOption.status === "ACCEPTED" || packingOption.status === "EXPIRED"}
                        >
                            {packingOption.status === "ACCEPTED" ? "Confirmed" : "Confirm Packing"}
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        onClick={async () => {
                            setLoading(true);
                            await onFinishBatch();
                            setLoading(false);
                        }}
                        disabled={packingOption.status !== "ACCEPTED"}
                        loading={loading}
                    >
                        Finish batch
                    </Button>
                </>
            }
        >
            <Space style={{maxHeight: "48vh", overflow: "auto", width: "100%"}} direction="vertical">
                {packingOption.packingGroups.map((groupId) => (
                    <Card key={groupId} title={`Group ${groupId}`}>
                        <Table
                            dataSource={packFirstReceivingData.packingGroupItems[groupId] || []}
                            columns={[
                                {title: "ASIN", dataIndex: "asin"},
                                {title: "SKU", dataIndex: "msku"},
                                {title: "FNSKU", dataIndex: "fnsku"},
                                {title: "Quantity", dataIndex: "quantity"},
                            ]}
                            pagination={false}
                        />
                    </Card>
                ))}
            </Space>
        </Modal>
    );
};

const PackingOptionsDialog: React.FC<{
    open: boolean;
    onClose: () => void;
    onFinishBatch: (packingOption: PackingOption) => Promise<void>;
}> = ({open, onClose, onFinishBatch}) => {
    const {packingOptions} = useStore((state) => ({
        packingOptions: state.packFirstReceivingData.packingOptions,
    }));
    const [selectedOption, setSelectedOption] = useState<PackingOption | undefined>(undefined);

    return (
        <>
            <Modal title="Packing Options" open={open} width={800} style={{maxHeight: "50vh"}} onCancel={onClose} footer={null}>
                <Space style={{maxHeight: "48vh", overflow: "auto", width: "100%"}} direction="vertical">
                    {packingOptions.map((option, index) => (
                        <PackingInfo key={index} packingOption={option} onSelect={() => setSelectedOption(option)} />
                    ))}
                </Space>
            </Modal>
            {selectedOption && (
                <GroupItemsDialog
                    open={!!selectedOption}
                    onClose={() => setSelectedOption(undefined)}
                    packingOption={selectedOption}
                    onFinishBatch={async () => {
                        try {
                            await onFinishBatch(selectedOption);
                            setSelectedOption(undefined);
                        } catch (error: any) {
                            notification.error({
                                message: "Error",
                                description: error.message,
                            });
                        }
                    }}
                />
            )}
        </>
    );
};

export default PackingOptionsDialog;
