import {
    Button,
    Divider,
    Dropdown,
    Form,
    Image,
    Input,
    InputNumber,
    InputRef,
    Modal,
    Popover,
    Select,
    Space,
    Statistic,
    Table,
    Tooltip,
} from "antd";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {FormInstance} from "antd/lib/form";
import {ColumnType} from "antd/lib/table";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {SEASONAL_TAGS} from "../../types/OmegaTypes";
import {WholesaleItem} from "../../types/WholesaleItem";
import {CellInputType} from "../omega/table/EditableCell";
import "./Statistic.scss";
import UserCollectionsDropdown from "./table/UserCollectionsDropdown";
import UserColumnPresetsDropdown from "./table/UserColumnPresetsDropdown";
import {WholesaleDataType} from "./table/WholesaleColumns";
import {SUPPLIER_PREFIX} from "../../services/WholesaleService";
import {extraFilterClickHandlers} from "./table/WholesaleUtilities";
import {DownOutlined, InfoCircleFilled} from "@ant-design/icons";

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = React.memo(({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
});

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof WholesaleItem;
    inputType?: CellInputType;
    inputAllowedOptions?: string[];
    record: WholesaleItem;
    handleSave: (record: WholesaleItem) => void;
}

const EditableCell: React.FC<EditableCellProps> = React.memo(
    ({title, editable, children, dataIndex, inputType, inputAllowedOptions, record, handleSave, ...restProps}) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef<InputRef>(null);
        const inputNumberRef = useRef<HTMLInputElement>(null);
        const form = useContext(EditableContext)!;

        useEffect(() => {
            if (editing) {
                if (inputType?.includes("int")) {
                    inputNumberRef?.current && inputNumberRef.current!.focus();
                } else {
                    inputRef?.current && inputRef.current!.focus();
                }
            }
        }, [editing, inputType]);

        const toggleEdit = () => {
            setEditing(!editing);
            let value = record[dataIndex];
            if (inputType === "seasonal tags") {
                form.setFieldsValue({
                    [dataIndex]: value ? value.split(";") : [],
                });
            } else {
                if (inputType?.includes("int")) {
                    form.setFieldValue(dataIndex, value);
                } else {
                    form.setFieldsValue({
                        [dataIndex]: !Number.isNaN(value) ? (Number.isInteger(value) ? value.toFixed(0) : value.toFixed(2)) : value,
                    });
                }
            }
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();

                if (inputType === "seasonal tags") {
                    values[dataIndex] = values[dataIndex].join(";");
                }

                handleSave({...record, ...values});
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{margin: 0}}
                    name={dataIndex}
                    rules={[
                        {
                            required: inputType === "seasonal tags" ? false : true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    {inputType === "seasonal tags" ? (
                        <Select
                            mode="multiple"
                            allowClear
                            options={(inputAllowedOptions ?? (SEASONAL_TAGS as unknown as string[])).map((tag: string) => ({
                                label: tag,
                                value: tag,
                            }))}
                            onBlur={save}
                            autoFocus
                            defaultOpen
                        />
                    ) : inputType?.includes("int") ? (
                        <InputNumber
                            ref={inputNumberRef}
                            onPressEnter={save}
                            onBlur={save}
                            min={inputType.includes("positive") ? 1 : undefined}
                            // parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                        />
                    ) : (
                        <Input
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                            onChange={(val) => {
                                // console.log(record[dataIndex], parseInt(val.target.value))
                                // form.setFieldsValue({
                                //     [dataIndex]: parseInt(val.target.value)
                                // })
                                const value = parseInt(val.target.value);
                                if (!isNaN(value)) {
                                    record[dataIndex] = value;
                                }
                            }}
                        />
                    )}
                </Form.Item>
            ) : (
                <div
                    onClick={editable && !editing ? toggleEdit : undefined}
                    className="editable-cell-value-wrap"
                    style={{paddingRight: 12}}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    }
);

function getDominantTag(items: WholesaleDataType[]) {
    const tags: {[key: string]: number} = {};

    for (const item of items) {
        for (const tag of item.Tags) {
            tags[tag] = (tags[tag] ?? 0) + 1;
        }
    }

    let winningTag: [string, number] = ["", 0];

    Object.entries(tags).forEach(([tag, encounters]) => {
        if (encounters > winningTag[1]) {
            winningTag = [tag, encounters];
        }
    });

    return winningTag[0];
}

const filterDataByPreset = (data: WholesaleDataType[], preset: string): WholesaleDataType[] => {
    return data.filter((row) => {
        if (row.recentlyEdited) {
            return true;
        }

        switch (preset) {
            case "SUPPRESSED":
                return row.currentBuyBoxPrice === -1;
            case "STAGE_01":
                return row.Stages.includes("STAGE_01") && !row.saved;
            case "STAGE_02":
                return row.Stages.includes("STAGE_02");
            case "STAGE_03":
                return row.Stages.includes("STAGE_03");
            case "STAGE_04":
                return row.saved && row.BuyQTY > 0 && row.AvgSellPrice < 0.9 * row.MinimumViableSellPrice;
            case "REP_STAGE_01":
                return row.DIS > 0 && row.Orders30D > 0;
            case "REP_STAGE_02":
                return row.DIS > 0 && row.OrdersAll > 0 && (!row.saved || row.BuyQTY === 0);
            case "REP_STAGE_03":
                return row.DIS > 0 && row.OrdersAll > 0 && (!row.saved || row.BuyQTY === 0);
            case "REP_STAGE_04":
                return row.DIS > 0 && row.OrdersAll > 0;
            case "STAGE_X":
                return row.Stages.includes("STAGE_X");
            case "STAGE_Y":
                return row.Stages.includes("STAGE_Y");
            default:
                return true;
        }
    });
};

const sortDataByPreset = (data: WholesaleDataType[], preset: string): WholesaleDataType[] => {
    const sorterPresets = ["REP_STAGE_01", "REP_STAGE_02", "REP_STAGE_03", "REP_STAGE_04"];
    if (sorterPresets.includes(preset)) {
        return [...data].sort((a, b) => {
            switch (preset) {
                case "REP_STAGE_01":
                    return b.DIS - a.DIS || b.OrdersAll - a.OrdersAll;
                case "REP_STAGE_02":
                    return b.DIS - a.DIS || b.OrdersAll - a.OrdersAll;
                case "REP_STAGE_03":
                    return b.Velocity - a.Velocity;
                case "REP_STAGE_04":
                    return a.replenROI - b.replenROI;
                default:
                    return 1;
            }
        });
    } else {
        return data;
    }
};

export const filterTableData = (filterInfo: Record<string, FilterValue | null>, columns: ColumnType<any>[], data: any[]) => {
    // @ts-ignore
    const filterEntries: [string, (string | number | boolean)[]][] = Object.entries(filterInfo).filter(([_, value]) => value !== null);
    if (filterEntries.length > 0) {
        const filtered = data.filter((record) =>
            filterEntries.every(([key, value]) => {
                const filterLogic = columns.find((column: ColumnType<any>) => column.dataIndex === key)?.onFilter;
                return filterLogic && filterLogic(value[0], record);
            })
        );

        return filtered;
    } else {
        return data;
    }
};

const SeparatedTable: React.FC<{
    tableData: WholesaleDataType[];
    columns: ColumnType<WholesaleDataType>[];
    allColumns: ColumnType<WholesaleDataType>[];
    setFilteredData: Dispatch<SetStateAction<number[]>>;
    setFilteredColumns: Dispatch<SetStateAction<string[]>>;
    setHiddenColumns: Dispatch<SetStateAction<string[]>>;
    setSelectedColumnPresetArray: React.Dispatch<React.SetStateAction<string[]>>;
    selectedColumnPreset: string;
    selectedFilterPreset: string;
}> = ({
    tableData,
    columns,
    setFilteredData,
    setFilteredColumns,
    setHiddenColumns,
    allColumns,
    selectedColumnPreset,
    selectedFilterPreset,
    setSelectedColumnPresetArray,
}) => {
    const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
    const [sortedInfo, setSortedInfo] = useState<{
        [key: string]: SorterResult<WholesaleDataType>;
    }>({});
    const [localFiltered, setLocalFiltered] = useState<number[]>([]);
    const [localData, setLocalData] = useState<WholesaleDataType[]>([]);

    // console.log('Rendering new SeparatedTable with', tableData)

    useEffect(() => {
        const presetData = sortDataByPreset(filterDataByPreset(tableData, selectedFilterPreset), selectedFilterPreset);
        setLocalData(presetData);
    }, [tableData, selectedFilterPreset]);

    useEffect(() => {
        const result = filterTableData(filteredInfo, columns, localData).map((item) => item.key);
        setFilteredData(result);
        setLocalFiltered(result);
        setFilteredColumns(
            Object.entries(filteredInfo)
                .filter(([col, val]) => val !== null)
                .map(([col, _]) => col)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localData, filteredInfo]);

    const dominantTag = getDominantTag(
        tableData.filter((row) => (localFiltered.length === 0 || localFiltered.includes(row.key as number)) && row.Tags.length > 0)
    );

    return (
        <>
            <Table<WholesaleDataType>
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                rowClassName={() => "editable-row"}
                scroll={{y: "82vh", x: "max-content"}}
                sticky={true}
                bordered={true}
                size="small"
                tableLayout={"auto"}
                title={() => (
                    <Space
                        style={{
                            justifyContent: "center",
                            alignContent: "center",
                            width: "100%",
                        }}
                        split={<Divider type="vertical" />}
                    >
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: "1",
                                        label: "Size Guide",
                                        onClick: () => {
                                            Modal.info({
                                                title: "Size Guide",
                                                mask: false,
                                                styles: {
                                                    content: {
                                                        width: "800px",
                                                        height: "auto",
                                                    },
                                                },
                                                content: (
                                                    <Image src="https://res.cloudinary.com/mooregroup/image/upload/f_auto,q_auto/v1/Info/SizeInfo"></Image>
                                                ),
                                            });
                                        },
                                    },
                                    {
                                        key: "2",
                                        disabled: true,
                                        label: "Size Distribution",
                                        onClick: () => {},
                                    },
                                ],
                            }}
                        >
                            <Button>
                                <Space onClick={(e) => e.preventDefault()}>
                                    WS Helpers
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                        <UserCollectionsDropdown
                            columns={columns}
                            allColumns={allColumns}
                            filteredInfo={filteredInfo}
                            setFilteredInfo={setFilteredInfo}
                            setHiddenColumns={setHiddenColumns}
                        />
                        <UserColumnPresetsDropdown
                            columns={columns}
                            allColumns={allColumns}
                            setHiddenColumns={setHiddenColumns}
                            setSelectedColumnPresetArray={setSelectedColumnPresetArray}
                        />
                        <Statistic title="Saved SKUs" value={`${tableData.filter((row) => row.saved).length} out of ${tableData.length}`} />
                        <Statistic
                            title="Saved units"
                            value={tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.BuyQTY), 0)}
                        />
                        <Statistic
                            title="Saved spend"
                            value={tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.BuyQTY * curr.AskPrice), 0)}
                            precision={2}
                            prefix={"$"}
                        />
                        <Statistic
                            title={
                                <Popover
                                    content="Based on saved items, averaged by Order Amount, it is a measure of how much profit is generated yearly for every dollar invested in inventory."
                                    trigger="hover"
                                    title={null}
                                >
                                    <span>
                                        GMROI <InfoCircleFilled style={{fontSize: "12px"}} />
                                    </span>
                                </Popover>
                            }
                            value={
                                tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.wsGMROI * curr.OrderAmount), 0) /
                                (tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.OrderAmount), 0) || 1)
                            }
                            precision={2}
                        />
                        <Statistic
                            title={
                                <Popover
                                    content="Based on saved items, averaged by Order Amount, it is a measure of how many days it will take to sell through the inventory."
                                    trigger="hover"
                                    title={null}
                                >
                                    <span>
                                        TTS <InfoCircleFilled style={{fontSize: "12px"}} />
                                    </span>
                                </Popover>
                            }
                            value={(() => {
                                const boxplotData = [...tableData.filter((row) => row.saved)]
                                    .sort((a, b) => a.wsGMROITTS - b.wsGMROITTS)
                                    .slice(
                                        Math.floor(tableData.filter((row) => row.saved).length * 0.25),
                                        Math.floor(tableData.filter((row) => row.saved).length * 0.75)
                                    );

                                return (
                                    boxplotData.reduce((acc, curr) => (acc += curr.wsGMROITTS * curr.OrderAmount), 0) /
                                    (boxplotData.reduce((acc, curr) => (acc += curr.OrderAmount), 0) || 1)
                                );
                            })()}
                            precision={2}
                        />
                        <Statistic
                            title="Average ROI"
                            value={
                                (tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.Profit * curr.BuyQTY), 0) /
                                    (tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.AskPrice * curr.BuyQTY), 0) ||
                                        1)) *
                                100
                            }
                            precision={2}
                            suffix={"%"}
                        />
                        <Statistic
                            title="Est. Profit"
                            value={tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.Profit * curr.BuyQTY), 0)}
                            precision={2}
                            prefix={"$"}
                        />

                        <Divider type="vertical"></Divider>
                        <Tooltip trigger={"hover"} title="[FILTERED] sum of (min of BuyQTY and OH) * AskPrice">
                            <Statistic
                                title="F. spend"
                                value={localData
                                    .filter((row) => localFiltered.length === 0 || localFiltered.includes(row.key as number))
                                    .reduce((acc, curr) => (acc += Math.min(curr.BuyQTY!, Math.max(curr.onHand, 0)) * curr.AskPrice), 0)}
                                precision={2}
                                prefix={"$"}
                            />
                        </Tooltip>
                        <Tooltip trigger={"hover"} title="[FILTERED] sum of (min of BuyQTY and OH)">
                            <Statistic
                                title="F. units"
                                value={localData
                                    .filter((row) => localFiltered.length === 0 || localFiltered.includes(row.key as number))
                                    .reduce((acc, curr) => (acc += Math.min(curr.BuyQTY!, Math.max(curr.onHand, 0))), 0)}
                                precision={0}
                            />
                        </Tooltip>
                        <Tooltip trigger={"hover"} title="[FILTERED] sum of (minimum of EstSales/onHand) * AskPrice">
                            <Statistic
                                title="F. spend (30D)"
                                value={localData
                                    .filter(
                                        (row) =>
                                            (localFiltered.length === 0 || localFiltered.includes(row.key as number)) && row.EstSales! >= 0
                                    )
                                    .reduce((acc, curr) => (acc += Math.min(curr.EstSales!, Math.max(curr.onHand, 0)) * curr.AskPrice), 0)}
                                precision={2}
                                prefix={"$"}
                            />
                        </Tooltip>
                        <Tooltip trigger={"hover"} title="[FILTERED] sum of (minimum of EstSales/onHand)">
                            <Statistic
                                title="F. sold (30D)"
                                value={localData
                                    .filter(
                                        (row) =>
                                            (localFiltered.length === 0 || localFiltered.includes(row.key as number)) && row.EstSales! >= 0
                                    )
                                    .reduce((acc, curr) => (acc += Math.min(curr.EstSales!, Math.max(curr.onHand, 0))), 0)}
                                precision={0}
                            />
                        </Tooltip>
                        <Tooltip trigger={"hover"} title="[FILTERED] sum of (EstChildSales * BBShare)">
                            <Statistic
                                title="F. child sales (30D)"
                                value={localData
                                    .filter(
                                        (row) =>
                                            (localFiltered.length === 0 || localFiltered.includes(row.key as number)) &&
                                            row.EstChildSales! >= 0
                                    )
                                    .reduce((acc, curr) => (acc += curr.EstChildSales * curr.bbMultiplier), 0)}
                                precision={2}
                                prefix={"$"}
                            />
                        </Tooltip>
                        <Tooltip trigger={"hover"} title="1 - (filtered # of items/total number of items)">
                            <Statistic
                                title="Progress"
                                value={
                                    100 *
                                    (1 -
                                        localData.filter((row) => localFiltered.length === 0 || localFiltered.includes(row.key as number))
                                            .length /
                                            tableData.length)
                                }
                                precision={0}
                                suffix={"%"}
                            />
                        </Tooltip>
                        {tableData && tableData[0].Supplier_MAP && (
                            <Tooltip trigger={"hover"} title="How many products are breaking MAP">
                                <Statistic
                                    title="MAP Broken"
                                    value={`${localData.filter((prod) => prod.Issues?.includes("MAP Violation")).length} / ${
                                        localData.filter((prod) => prod.Supplier_MAP).length
                                    }`}
                                />
                            </Tooltip>
                        )}
                        {selectedColumnPreset.includes("REPLEN_PRESET") && (
                            <Tooltip trigger={"hover"} title="the dominant SS's tag">
                                <Statistic
                                    title="Dominant Tag"
                                    value={`${dominantTag}: ${
                                        localData.filter(
                                            (row) =>
                                                (localFiltered.length === 0 || localFiltered.includes(row.key as number)) &&
                                                row.Tags.length > 0 &&
                                                row.Tags.includes(dominantTag)
                                        ).length
                                    } / ${
                                        localData.filter((row) => localFiltered.length === 0 || localFiltered.includes(row.key as number))
                                            .length
                                    }`}
                                />
                            </Tooltip>
                        )}
                        {selectedColumnPreset.includes("REPLEN_PRESET") && (
                            <Tooltip trigger={"hover"} title="Average suggested DaysToBuy (coverage)">
                                <Statistic
                                    title="Filter coverage (days):"
                                    value={localData
                                        .filter(
                                            (row) =>
                                                (localFiltered.length === 0 || localFiltered.includes(row.key as number)) &&
                                                row.replenEstSales! >= 0 &&
                                                row.BuyQTY > 0
                                        )
                                        .reduce((acc, curr, _, {length}) => (acc += curr.BuyQTY / (curr.replenEstSales / 30) / length), 0)}
                                    precision={0}
                                />
                            </Tooltip>
                        )}
                        <Button type="primary" onClick={() => setFilteredInfo({})}>
                            Reset filters
                        </Button>
                        <Button type="primary" onClick={() => setSortedInfo({})}>
                            Reset sorters
                        </Button>
                    </Space>
                )}
                dataSource={localData}
                pagination={{
                    showSizeChanger: true,
                }}
                columns={columns.map((column) => {
                    const newColumn = {
                        ...column,
                        filteredValue: filteredInfo[column.dataIndex! as string] || null,
                        sortOrder: sortedInfo[column.dataIndex! as string]?.order || null,
                    };

                    if (
                        (newColumn.dataIndex as string)?.includes(SUPPLIER_PREFIX) ||
                        (newColumn.dataIndex as string)?.includes("parentASIN") ||
                        (newColumn.dataIndex as string)?.includes("ASIN")
                    ) {
                        newColumn.onCell = (record) => {
                            return {
                                onClick: (event) => extraFilterClickHandlers(filteredInfo, setFilteredInfo, newColumn, record)(event),
                            };
                        };
                    }
                    return newColumn;
                })}
                showSorterTooltip={false}
                onChange={(pagination, filters, sorter, extra) => {
                    setFilteredInfo(filters);
                    setSortedInfo(
                        [sorter].flat().reduce(
                            (
                                acc: {
                                    [key: string]: SorterResult<WholesaleDataType>;
                                },
                                curr: SorterResult<WholesaleDataType>
                               
                            ) => (((acc[curr.columnKey as string] = curr), acc)), // prettier-ignore
                            {}
                        )
                    );
                    console.log("Rendering SeparatedTable with filtered", extra.currentDataSource);
                    // setLocalFiltered(extra.currentDataSource.map((row) => row.key as number))
                }}
            ></Table>
        </>
    );
};
export default SeparatedTable;
