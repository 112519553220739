import {EbayOfferDetailsWithKeys, InventoryItem} from "ebay-api/lib/types";
import {EBayAspect, EBayCategorySuggestion, ItemConditionPolicy} from "../types/WarehouseTypes";
import {makeCall} from "./common";

export const CONDITION_MAPPING: {[key: string]: string} = {
    "1000": "NEW",
    "2750": "LIKE_NEW",
    "1500": "NEW_OTHER",
    "1750": "NEW_WITH_DEFECTS",
    "2000": "CERTIFIED_REFURBISHED",
    "2010": "EXCELLENT_REFURBISHED",
    "2020": "VERY_GOOD_REFURBISHED",
    "2030": "GOOD_REFURBISHED",
    "2500": "SELLER_REFURBISHED",
    "3000": "USED_EXCELLENT",
    "4000": "USED_VERY_GOOD",
    "5000": "USED_GOOD",
    "6000": "USED_ACCEPTABLE",
    "7000": "FOR_PARTS_OR_NOT_WORKING",
};

export async function getCategorySuggestions(query: string): Promise<{
    categorySuggestions: EBayCategorySuggestion[];
    categoryTreeId: string;
    categoryTreeVersion: string;
}> {
    const response = await makeCall("eBayApi/commerce/taxonomy/getCategorySuggestions", {categoryTreeId: "0", q: query, devMode: false});
    return response;
}

export async function getItemConditionPolicies(categoryId: string): Promise<ItemConditionPolicy[]> {
    const response = await makeCall("eBayApi/sell/metadata/getItemConditionPolicies", {
        marketplaceId: "EBAY_US",
        filter: `categoryIds:{${categoryId}}`,
        devMode: false,
    });
    return response.result.itemConditionPolicies;
}

export async function getItemAspectsForCategory(categoryTreeId: string, categoryId: string): Promise<EBayAspect[]> {
    const response = await makeCall("eBayApi/commerce/taxonomy/getItemAspectsForCategory", {categoryTreeId, categoryId, devMode: false});
    return response.result.aspects || [];
}

export async function createOrReplaceInventoryItem(sku: string, data: InventoryItem, devMode?: boolean): Promise<any> {
    const response = await makeCall("eBayApi/sell/inventory/createOrReplaceInventoryItem", {sku, devMode}, "POST", data);
    return response.result;
}

export async function createOffer(data: EbayOfferDetailsWithKeys, devMode?: boolean): Promise<any> {
    const response = await makeCall("eBayApi/sell/inventory/createOffer", {devMode}, "POST", data);
    return response.result;
}

export async function updateOffer(offerId: string, data: EbayOfferDetailsWithKeys, devMode?: boolean): Promise<any> {
    const response = await makeCall(`eBayApi/sell/inventory/updateOffer`, {offerId, devMode}, "POST", data);
    return response.result;
}

export async function getOffers(sku: string, devMode?: boolean): Promise<any[]> {
    const response = await makeCall("eBayApi/sell/inventory/getOffers", {sku, devMode});
    return response.offers;
}

export async function publishOffer(offerId: string, devMode?: boolean): Promise<any> {
    const response = await makeCall("eBayApi/sell/inventory/publishOffer", {offerId, devMode}, "POST");
    return response.result;
}
