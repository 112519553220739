import React, {useEffect, useState} from "react";
import {Space, Row, Col, Spin, Card, Descriptions, Empty, List, Button, Table, Input, Avatar, notification, Divider} from "antd";
import {ColumnsType} from "antd/lib/table";
import {getShipments} from "../../../../services/WarehouseService";
import {InboundShipmentItemV2, InboundShipmentV2} from "../../../../types/WarehouseTypes";
import {getSearchType} from "../../common/helpers";
import PrintButton from "../../common/PrintButton";
import ItemsToPrintDialog from "./ItemsToPrintDialog";
import PrintHandler from "../../common/PrintHandler";
import {useStore} from "../../../../store/useStore";

const ShipmentList: React.FC<{shipments: InboundShipmentV2[]; onSelect: (shipmentId: string) => void}> = ({shipments, onSelect}) => {
    if (!shipments.length) {
        return <Empty style={{width: "100%"}} />;
    }

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <List
                style={{height: "80vh", overflow: "auto"}}
                header="Shipments"
                dataSource={shipments}
                renderItem={(shipment) => (
                    <List.Item>
                        <Card
                            title={shipment.name}
                            style={{width: "100%", margin: 4}}
                            extra={<Button onClick={() => onSelect(shipment.shipmentId)}>Items</Button>}
                        >
                            <Descriptions
                                column={1}
                                bordered
                                items={[
                                    {key: "2", label: "Shipment ID", children: shipment.shipmentConfirmationId},
                                    {key: "3", label: "Destination Center ID", children: shipment.destination.warehouseId},
                                    {key: "4", label: "SKUs", children: shipment.expectedItems.length},
                                    {
                                        key: "5",
                                        label: "Items",
                                        children: shipment.expectedItems.map((item) => item.quantity).reduce((a, b) => a + b, 0),
                                    },
                                ]}
                            />
                        </Card>
                    </List.Item>
                )}
            />
        </Space>
    );
};

const LabelingDashboard: React.FC = () => {
    const [shipments, setShipments] = useState<InboundShipmentV2[]>([]);
    const [shipmentsLoading, setShipmentsLoading] = useState<boolean>(false);
    const [foundItems, setFoundItems] = useState<InboundShipmentItemV2[]>([]);
    const [code, setCode] = useState<string>("");
    const {printerLoading, handlePrintGlobal} = useStore((state) => ({
        printerLoading: state.printerLoading,
        handlePrintGlobal: state.handlePrint,
    }));
    const [showItemsToPrint, setShowItemsToPrint] = useState<boolean>(false);
    const [selectedShipmentItems, setSelectedShipmentItems] = useState<InboundShipmentItemV2[]>([]);

    useEffect(() => {
        setShipmentsLoading(true);
        getShipments().then((data) => {
            setShipments(data.shipments);
            setShipmentsLoading(false);
        });
    }, []);

    const columns: ColumnsType<InboundShipmentItemV2> = [
        {
            title: "Destination Center",
            dataIndex: "destinationWarehouseId",
            key: "destinationWarehouseId",
        },
        {
            title: "Shipment ID",
            dataIndex: "shipmentConfirmationId",
            key: "shipmentConfirmationId",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Printed Quantity",
            render: (item: InboundShipmentItemV2) => item.printedQuantity || 0,
            key: "printedQuantity",
        },
        {
            title: "Action",
            key: "action",
            render: (item: InboundShipmentItemV2) => (
                <PrintButton
                    onPrintLabel={(quantity) => handlePrintLabel(item, quantity)}
                    printedQuantity={item.printedQuantity}
                    maxQuantity={item.quantity}
                />
            ),
        },
    ];

    const findItems = (shipments: InboundShipmentV2[], code: string, key: "msku" | "asin" | "upc") => {
        const items: InboundShipmentItemV2[] = [];
        shipments.forEach((shipment) => {
            const foundItems = shipment.expectedItems.filter((item) => item[key] === code);
            items.push(...foundItems.map((item) => ({...item, destinationWarehouseId: shipment.destination.warehouseId || ""})));
        });

        return items;
    };

    const handlePrintLabel = async (item: InboundShipmentItemV2, quantity: number) => {
        try {
            await handlePrintGlobal(
                {
                    fnsku: item.fnsku,
                    name: item.name || "N/A",
                    upc: item.upc || "N/A",
                    centerId: item.destinationWarehouseId || "N/A",
                },
                quantity
            );

            // Update the printed quantity in the shipments state
            const newShipments = shipments.map((shipment) => {
                if (shipment.shipmentId === item.shipmentId) {
                    const newItems = shipment.expectedItems.map((expectedItem) => {
                        if (expectedItem.msku === item.msku) {
                            return {...expectedItem, printedQuantity: (expectedItem.printedQuantity || 0) + quantity};
                        }
                        return expectedItem;
                    });
                    return {...shipment, ExpectedItems: newItems};
                }
                return shipment;
            });
            setShipments(newShipments);
            setFoundItems(findItems(newShipments, item.msku, "msku"));
        } catch (error: any) {
            notification.error({message: "Error", description: error.message});
        }
    };

    const handleScan = (code: string) => {
        const searchType = getSearchType(code);
        let key: keyof InboundShipmentItemV2 = "upc";
        if (searchType === "ASIN") {
            key = "asin";
        } else if (searchType === "FNSKU") {
            key = "msku";
        }

        const items = findItems(shipments, code, key);
        if (items.length === 0) {
            notification.error({message: "Error", description: "Item not found"});
        } else {
            setFoundItems(items);
        }
    };

    const foundItem = foundItems.length > 0 ? foundItems[0] : null;

    return (
        <Spin spinning={shipmentsLoading || printerLoading} style={{width: "100%"}}>
            <Row style={{width: "100%"}}>
                <Col span={8} style={{paddingRight: 8}}>
                    <ShipmentList
                        shipments={shipments}
                        onSelect={(shipmentId) => {
                            const selectedShipment = shipments.find((shipment) => shipment.shipmentId === shipmentId);
                            setSelectedShipmentItems(
                                (selectedShipment?.expectedItems || []).map((item) => ({
                                    ...item,
                                    DestinationFulfillmentCenterId: selectedShipment?.destination.warehouseId || "",
                                }))
                            );
                            setShowItemsToPrint(true);
                        }}
                    />
                </Col>
                <Col span={16}>
                    <Row>
                        <Col span={20}>
                            <Input
                                size="large"
                                placeholder="Scan items"
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                                onPressEnter={() => {
                                    handleScan(code);
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <Space style={{width: "100%", justifyContent: "center"}}>
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                        handleScan(code);
                                    }}
                                >
                                    Scan
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Divider />
                    <PrintHandler />
                    <Divider />

                    <Row>
                        <Col span={4}>
                            <Avatar src={foundItem?.imgURL} size={120} shape="square" />
                        </Col>
                        <Col span={20}>
                            <Descriptions
                                column={1}
                                size="small"
                                bordered
                                items={[
                                    {key: "1", label: "Name", children: foundItem?.name},
                                    {key: "2", label: "ASIN", children: foundItem?.asin},
                                    {key: "3", label: "SKU", children: foundItem?.msku},
                                    {key: "4", label: "UPC", children: foundItem?.upc},
                                ]}
                            />
                        </Col>
                    </Row>
                    <Divider />

                    <Table
                        style={{height: "50vh", overflow: "auto"}}
                        columns={columns}
                        dataSource={foundItems}
                        rowKey="shipmentId"
                        pagination={false}
                    />
                </Col>
            </Row>
            <ItemsToPrintDialog
                items={selectedShipmentItems}
                open={showItemsToPrint}
                onCancel={() => {
                    setShowItemsToPrint(false);
                }}
                onPrint={(item, quantity) => {
                    handlePrintLabel(item, quantity);
                }}
            />
        </Spin>
    );
};

export default LabelingDashboard;
