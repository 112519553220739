import React, {useMemo} from "react";
import {Typography, Space, Empty, Row, Col, Button, Spin, Tooltip, Modal} from "antd";
import {PlacementOption} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import {DownloadOutlined, ReloadOutlined} from "@ant-design/icons";
import ShipmentView from "./ShipmentView";

const {Text} = Typography;

interface PlacementOptionViewProps {
    placementOption?: PlacementOption;
    index: number;
}

const PlacementOptionView: React.FC<PlacementOptionViewProps> = ({placementOption, index}) => {
    const {data, actions} = useStore(useShallow((state) => ({data: state.packFirstBoxingData, actions: state.packFirstBoxingActions})));
    const [modal, contextHolder] = Modal.useModal();

    const shipments = useMemo(() => {
        return Object.values(data.shipments.byId).filter((s) => placementOption?.shipmentIds?.includes(s.shipmentId));
    }, [data.shipments.byId, placementOption?.shipmentIds]);

    const packingOption = data.packingOptions.byId[data.selectedPackingOptionId || ""];
    const totalFees = useMemo(() => {
        // If any of the shipments doesn't have a transportation option, return undefined
        if (shipments.length === 0 || shipments.some((s) => !s.selectedTransportationOptionId)) {
            return undefined;
        }
        const placementFees = placementOption?.fees.reduce((prev, curr) => prev + curr.value?.amount || 0, 0);
        let shippingFees = 0;
        shipments.forEach((s) => {
            const transportationOption = s.transportationOptions?.find(
                (t) => t.transportationOptionId === s.selectedTransportationOptionId
            );
            shippingFees += transportationOption?.quote?.cost?.amount || 0;
        });
        return (placementFees || 0) + (shippingFees || 0);
    }, [shipments, placementOption]);

    if (!placementOption || !packingOption) {
        return <Empty description="Select a placement option to view shipments" />;
    }

    const loadingPlacementOption = data.loadingPlacementOptions.find((l) => l.id === placementOption.placementOptionId);

    return (
        <Spin spinning={!!loadingPlacementOption} tip={loadingPlacementOption?.message}>
            <Space direction="vertical" style={{width: "100%"}}>
                <Row>
                    <Col span={8}>
                        <Space style={{width: "100%", justifyContent: "space-between"}}>
                            <Text>Option {index + 1}</Text>
                            <Space>
                                <Text>Total Fees: </Text>
                                <Text type="danger">${totalFees?.toFixed(2) || "N/A"}</Text>
                            </Space>
                        </Space>
                    </Col>
                    <Col span={16}>
                        <Space style={{width: "100%", justifyContent: "flex-end"}}>
                            <Button
                                disabled={packingOption.confirmTransportationOptionsStatus?.operationStatus !== "SUCCESS"}
                                onClick={() => {
                                    modal.confirm({
                                        title: "Finish Placement Option",
                                        content: "Are you sure you want to finish this placement option?",
                                        onOk: () =>
                                            actions.finishPackingOption(packingOption.packingOptionId, placementOption.placementOptionId),
                                        okText: "Yes",
                                        cancelText: "No",
                                    });
                                }}
                            >
                                Finish
                            </Button>
                            <Tooltip title="Confirm shipments">
                                <Button
                                    disabled={
                                        packingOption.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS" ||
                                        shipments.some((s) => !s.selectedTransportationOptionId) ||
                                        shipments.length === 0
                                    }
                                    onClick={() => {
                                        modal.confirm({
                                            title: "Confirm Shipments",
                                            content: "Are you sure you want to confirm these shipments?",
                                            onOk: () => actions.confirmShipments(placementOption.placementOptionId),
                                            okText: "Yes",
                                            cancelText: "No",
                                        });
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Tooltip>
                            <Tooltip title="Generate transportation options">
                                <Button
                                    disabled={
                                        packingOption.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS" ||
                                        shipments.length === 0
                                    }
                                    onClick={() => {
                                        actions.generateTransportationOptions(
                                            packingOption.inboundPlanId,
                                            placementOption.placementOptionId,
                                            placementOption.shipmentIds
                                        );
                                    }}
                                >
                                    Generate
                                </Button>
                            </Tooltip>
                            <Button
                                disabled={packingOption.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS"}
                                icon={<DownloadOutlined />}
                                onClick={() => {
                                    actions.fetchShipments(
                                        packingOption.inboundPlanId,
                                        placementOption.placementOptionId,
                                        placementOption.shipmentIds
                                    );
                                }}
                            >
                                Get shipments
                            </Button>
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={() =>
                                    actions.reloadShipments(
                                        packingOption.inboundPlanId,
                                        placementOption.placementOptionId,
                                        placementOption.shipmentIds
                                    )
                                }
                            />
                        </Space>
                    </Col>
                </Row>
                {shipments.length === 0 ? <Empty description="Fetch shipments first" /> : null}
                <Space direction="horizontal" wrap style={{height: "25vw", overflow: "auto", width: "100%", alignItems: "flex-start"}}>
                    {shipments.map((s, i) => (
                        <ShipmentView key={s.shipmentId} shipment={s} index={i} />
                    ))}
                </Space>
            </Space>
            {contextHolder}
        </Spin>
    );
};

export default PlacementOptionView;
