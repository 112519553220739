import {Button, Divider, InputRef, message, notification, Space, Switch} from "antd";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {InquiryItem, InquiryItemRenderType, OMSItem} from "../../../types/OmegaTypes";
import {makeColumnsEditable, makeInquiriesColumns} from "../../utilities/OMSColumns";
import {EditableColumnType} from "../table/EditableCell";
import * as _ from "lodash";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {
    deleteInquiries,
    getExperimentalInventory,
    getInquiries,
    getInvoices,
    getOMS,
    getOMSItemsByASIN,
    updateInquiries,
} from "../../../services/WholesaleService";
import {DeleteFilled} from "@ant-design/icons";
import EditableTable, {EditableTableHandle} from "../table/EditableTable";
import {getOrderSummariesForIDs} from "../../../services/OmegaService";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import {excelDownloadHelper} from "../../utilities/helpers/Downloads";
import {autoWidth} from "../../wholesale/ExcelDownload";

const defaultItem: InquiryItem = {
    Supplier_POs: [],
    UnitsSold: 0,
    UnitsInStock: 0,
    ASIN: "",
    Title: undefined,
    UPC: undefined,
    DropboxLink: undefined,
    Submitted: false,
    DateResponded: new Date(),
    ResponseTime: new Date(),
    Status: undefined,
    Notes: undefined,
    Timestamp: new Date(),
    Completed: false,
};

export const Inquiries: React.FC = () => {
    const {currentUser} = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<InquiryItemRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const tableRef = useRef<EditableTableHandle<InquiryItemRenderType>>(null);

    const {
        data: shipmentLogData,
        isLoading: shipmentLogDataLoading,
        isRefetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ["inquiries"],
        queryFn: async () => {
            const token = await currentUser!.getIdToken();
            const data = await getInquiries(token);
            return data;
        },
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Error",
                // @ts-ignore
                description: error.message,
            });
        }
    });

    const syncTableData = useCallback(() => {
        if (shipmentLogData) {
            setTableData(
                shipmentLogData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
            setLastUpdateTimestamp(fetchTimestamp);
        }
    }, [shipmentLogData, fetchTimestamp]);

    const omsMutation = useMutation({
        mutationFn: async (logItems: InquiryItem[]) => {
            const token = await currentUser!.getIdToken();

            if (!token) {
                throw new Error("Auth token not found");
            }

            if (!shipmentLogData) {
                throw new Error("Inquiries not fetched correctly");
            }

            const originalIds = shipmentLogData.map((item) => item._id);
            const localIds = logItems.map((item) => item._id);
            const idsToDelete = _.difference(originalIds, localIds);
            const returnedData = await updateInquiries(token, logItems);
            await deleteInquiries(token, idsToDelete.filter((id) => id !== undefined) as string[]);

            return returnedData;
        },
        onSuccess: () => {
            setFetchTimestamp(new Date());
            notification.success({
                message: "Push successful!",
            });

            refetch({throwOnError: true});
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const updateData = (newData: InquiryItemRenderType[]) => {
        setTableData(newData);
        setLastUpdateTimestamp(new Date());
    };

    const handleSave = useCallback(
        (row: InquiryItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            updateData(newData);
        },
        [tableData]
    );

    useEffect(() => {
        syncTableData();
    }, [shipmentLogData, syncTableData]);

    const columns = useMemo<EditableColumnType<InquiryItemRenderType>[]>(() => {
        const ledgerColumns = makeInquiriesColumns(searchInputRef, editable ? handleSave : undefined);
        if (editable) {
            ledgerColumns.push({
                title: "Action",
                dataIndex: "",
                key: "x",
                render: (_, record) => [
                    <Button
                        danger
                        block
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...tableData];
                            const newData = dataCopy.filter((item) => (record._id ? item._id !== record._id : item.key !== record.key));

                            setLastUpdateTimestamp(new Date());
                            setTableData(
                                newData.map((item, idx) => ({
                                    ...item,
                                    key: idx,
                                }))
                            );
                        }}
                    >
                        Delete
                    </Button>,
                    <Button
                        style={{marginTop: "5px"}}
                        type="primary"
                        block
                        onClick={async () => {
                            const itemClicked = tableData.find((item) => (record._id ? item._id === record._id : item.key === record.key))!;

                            console.log("itemClicked");

                            // we have to grab Master data, then necessary Invoices, also neccesary Order data
                            const token = await currentUser!.getIdToken();

                            const [omsData, orderData, inventoryData] = await Promise.all([
                                getOMSItemsByASIN(token, [itemClicked.ASIN], false),
                                getOrderSummariesForIDs(token, "asin", [itemClicked.ASIN], true, true),
                                getExperimentalInventory(token, [itemClicked.ASIN]),
                            ]).then((resList) => resList.map((item) => item[0]));

                            console.log("omsData", omsData);
                            console.log("orderData", orderData);
                            console.log("inventoryData", inventoryData);

                            const invoiceData = await getInvoices(token);

                            itemClicked.Supplier_POs = omsData.map((item: OMSItem) => ({
                                ...item,
                                Invoices: invoiceData.filter((invoice) => invoice.Supplier_PO === item.Supplier_PO),
                            }));
                            itemClicked.Title = omsData[0].AMZ_Title;
                            itemClicked.UPC = omsData[0].UPC;
                            itemClicked.SKU = omsData[0].Supplier_SKU;
                            itemClicked.UnitsSold = orderData?.d365!.units ?? 0;
                            itemClicked.UnitsInStock = parseInt(
                                inventoryData["afn-fulfillable-quantity"] ?? inventoryData["mfn-fulfillable-quantity"] ?? "0"
                            );

                            console.log("itemClicked", itemClicked);

                            // update table with new item
                            setLastUpdateTimestamp(new Date());
                            const newData = tableData.map((item) => (item.key === itemClicked.key ? itemClicked : item));
                            setTableData(
                                newData.map((item, idx) => ({
                                    ...item,
                                    key: idx,
                                }))
                            );
                        }}
                    >
                        Grab
                    </Button>,
                    <Button
                        style={{marginTop: "5px"}}
                        type="primary"
                        block
                        onClick={async () => {
                            const itemClicked = tableData.find((item) => (record._id ? item._id === record._id : item.key === record.key))!;
                            const token = await currentUser!.getIdToken();

                            // find POs without invoices
                            if (!itemClicked.Supplier_POs) {
                                message.error("No Supplier POs found for this item - Grab the data first!");
                                return;
                            }

                            const posWithoutInvoices = itemClicked.Supplier_POs.filter(
                                (po) => po.Invoices === undefined || po.Invoices.length === 0
                            );

                            if (posWithoutInvoices.length === 0) {
                                message.success("All Supplier POs have invoices!");
                                return;
                            }

                            const resultFrame = new dataForge.DataFrame(posWithoutInvoices)
                                .subset([
                                    "Invoice",
                                    "Supplier_Name",
                                    "Ship_Requested",
                                    "Supplier_PO",
                                    "Supplier_SKU",
                                    "Supplier_Title",
                                    "UPC",
                                    "Quantity",
                                    "Cost",
                                ])
                                .transformSeries({
                                    Ship_Requested: (val) => (val ? dayjs(val).format("MM/DD/YYYY") : ""),
                                });

                            console.log("posWithoutInvoices", resultFrame.toArray());
                            const resultWorkbook = new ExcelJS.Workbook();
                            const summarySheet = resultWorkbook.addWorksheet("Summary");

                            summarySheet.columns = [
                                {header: "Supplier PO", key: "Supplier_PO"},
                                {header: "Supplier Name", key: "Supplier_Name"},
                                {header: "Ship Requested", key: "Ship_Requested"},
                                {header: "Invoice", key: "Invoice"},
                            ];

                            ["A1", "B1", "C1", "D1"].forEach((key) => {
                                summarySheet.getCell(key).fill = {
                                    type: "pattern",
                                    pattern: "solid",

                                    fgColor: {argb: "cccccc"},
                                };

                                summarySheet.getCell(key).font = {bold: true};
                                summarySheet.getCell(key).alignment = {horizontal: "center"};
                            });

                            summarySheet.addRows(resultFrame.toArray());
                            autoWidth(summarySheet, 10, 50);

                            const sheets = posWithoutInvoices.map((po) => resultWorkbook.addWorksheet(po.Supplier_PO));

                            for (const sheet of sheets) {
                                console.log("sheet", sheet.name);

                                // download PO data
                                const poData = await getOMS(token, sheet.name);
                                console.log("poData", poData);

                                // create worksheet
                                sheet.columns = [
                                    {header: "Supplier Name", key: "Supplier_Name"},
                                    {header: "Ship Requested", key: "Ship_Requested"},
                                    {header: "Supplier PO", key: "Supplier_PO"},
                                    {header: "Supplier SKU", key: "Supplier_SKU"},
                                    {header: "Supplier Title", key: "Supplier_Title"},
                                    {header: "UPC", key: "UPC"},
                                    {header: "Quantity", key: "Quantity"},
                                    {header: "Cost", key: "Cost"},
                                ];

                                ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1"].forEach((key) => {
                                    sheet.getCell(key).fill = {
                                        type: "pattern",
                                        pattern: "solid",

                                        fgColor: {argb: "cccccc"},
                                    };

                                    sheet.getCell(key).font = {bold: true};
                                    sheet.getCell(key).alignment = {horizontal: "center"};
                                });

                                sheet.getColumn(8).numFmt = "$#,##0.00";

                                sheet.addRows(
                                    poData.map((row) => ({
                                        ...row,
                                        Ship_Requested: row.Ship_Requested ? dayjs(row.Ship_Requested).format("MM/DD/YYYY") : "",
                                    }))
                                );

                                autoWidth(sheet, 10, 50);
                            }

                            excelDownloadHelper(resultWorkbook, "Invoice Data for " + itemClicked.ASIN);
                        }}
                    >
                        Excel
                    </Button>,
                ],
                fixed: "right",
                width: "60px",
            });
            return makeColumnsEditable(ledgerColumns, handleSave);
        } else {
            return ledgerColumns;
        }
    }, [handleSave, editable, tableData, currentUser]);

    const addNewRow = () => {
        const newItem: InquiryItemRenderType = {
            ...defaultItem,
            key: 0,
            Timestamp: new Date(),
        };
        const newData = [newItem, ...tableData];
        setTableData(newData.map((item, idx) => ({...item, key: idx})));
    };

    const onDiscardChanges = () => {
        if (shipmentLogData) {
            setTableData(
                shipmentLogData.map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
            );
        }
        setLastUpdateTimestamp(fetchTimestamp);
    };

    const onSaveChanges = () => {
        omsMutation.mutate(tableData);
    };

    const tableTitle = () => (
        <Space direction="horizontal" style={{width: "100%", justifyContent: "end"}} split={<Divider type="vertical" />}>
            <UnsavedChangesHandler
                isSaved={lastUpdateTimestamp <= fetchTimestamp}
                disableWhenSaved
                isLoading={omsMutation.isPending || isRefetching}
                onDiscardChanges={onDiscardChanges}
                onSaveChanges={onSaveChanges}
            />
            <Space>
                Editable: <Switch checked={editable} onChange={setEditable} />
            </Space>
            <Button
                onClick={() => {
                    addNewRow();
                }}
                disabled={!editable}
            >
                Add row
            </Button>
        </Space>
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <EditableTable<InquiryItemRenderType>
                ref={tableRef}
                title={tableTitle}
                tableData={tableData}
                columns={columns}
                loading={shipmentLogDataLoading}
                onRow={(row) => {
                    let style = {};
                    if (row.Status === "Draft") {
                        style = {
                            backgroundColor: "#f0a20244",
                        };
                    }
                    return {
                        style,
                    };
                }}
            />
        </Space>
    );
};
