import {Column, Line, Pie} from "@ant-design/charts";
import {UploadOutlined} from "@ant-design/icons";
import {Button, Card, Col, Divider, Modal, Row, Select, Space, Table, Tag, Tooltip, Typography, Upload} from "antd";
import {FilterValue} from "antd/es/table/interface";
import {RcFile} from "antd/es/upload";
import {ColumnType} from "antd/lib/table";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, {useEffect, useMemo, useState} from "react";
import {useSupplierContext} from "../../contexts/SupplierContext";
import {getGranulatedOrderSummariesForIDs} from "../../services/OmegaService";
import {getExperimentalInventory} from "../../services/WholesaleService";
import {getIdToken} from "../../services/common";
import {BrandDashboardItem} from "../../types/Brand";
import {InventoryItem} from "../../types/WholesaleItem";
import SingleBrowser from "../omega/SingleBrowser";
import {GetExpandedFilter} from "../utilities/ExpandedFilterDropdown";
import {filterTableData} from "../wholesale/SeparatedTable";
import {extraFilterClickHandlers} from "../wholesale/table/WholesaleUtilities";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Etc/GMT");

const BrandSellthrough: React.FC = () => {
    const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
    const {selectedSupplierItems} = useSupplierContext();
    const [modal, contextHolder] = Modal.useModal();
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [selectedWeek, setSelectedWeek] = useState<string | null>(null);
    const [totalDataMap, setTotalDataMap] = useState<{
        [key: string]: {
            orderData: {
                [key: string]: any;
            };
            projectionData: {
                [key: string]: any;
            };
            projectedUnits: number;
            projectedUnitsTotal: number;
            orderedUnits: number;
            orderedUnitsTotal: number;
            tableData: any[];
        };
    }>({});
    const [lineChartData, setLineChartData] = useState<any[]>([]);
    const [pieChartData, setPieChartData] = useState<any[]>([]);
    const [weeklyChartData, setWeeklyChartData] = useState<any[]>([]);
    const [fileMap, setFileMap] = useState<{sellthroughImport: RcFile | undefined}>({sellthroughImport: undefined});
    const [isLoading, setIsLoading] = useState(false);

    const beforeUpload = (file: RcFile) => {
        setFileMap((prev) => ({...prev, sellthroughImport: file}));
        return false;
    };

    const onRemove = () => {
        setFileMap({sellthroughImport: undefined});
    };

    const handleUpload = async () => {
        setIsLoading(true);

        const reader = new FileReader();

        reader.onload = async (e) => {
            const csv = e.target?.result;
            const csvString = csv as string;

            const df = dataForge.fromCSV(csvString);

            console.log(df.toArray());

            const weeks = df
                .getColumnNames()
                .slice(1)
                .sort()
                .map((date) => dayjs.utc(date).format("YYYY-MM-DD"));

            setDateRange(weeks);

            const asins = df.getSeries("ASIN").distinct().toArray();

            const inventoryData = await getIdToken().then((token) => getExperimentalInventory(token, asins));

            const orderData = await getGranulatedOrderSummariesForIDs(
                "asin",
                asins,
                dayjs.utc(weeks[0]).toISOString(),
                dayjs
                    .utc(weeks[weeks.length - 1])
                    .endOf("week")
                    .toISOString(),
                7,
                true
            );

            let totalDataMap: {
                [key: string]: {
                    orderData: {
                        [key: string]: any;
                    };
                    projectionData: {
                        [key: string]: any;
                    };
                    projectedUnits: number;
                    projectedUnitsTotal: number;
                    orderedUnits: number;
                    orderedUnitsTotal: number;
                    tableData: any[];
                };
            } = {};

            Object.entries(orderData).forEach(([asin, asinSummary], idx, arr) => {
                for (const asinEntry of asinSummary) {
                    const startDate = dayjs.utc(asinEntry.start).format("YYYY-MM-DD");
                    if (!totalDataMap[startDate]) {
                        totalDataMap[startDate] = {
                            orderData: {
                                [asin]: asinEntry.data,
                            },
                            projectionData: {},
                            projectedUnits: 0,
                            projectedUnitsTotal: 0,
                            orderedUnits: 0,
                            orderedUnitsTotal: 0,
                            tableData: [],
                        };
                    } else {
                        totalDataMap[startDate].orderData[asin] = asinEntry.data;
                    }
                }
            });

            df.toArray().forEach((row) => {
                Object.entries(row).forEach(([key, value]) => {
                    if (key !== "ASIN") {
                        const startDate = dayjs.utc(key).format("YYYY-MM-DD");
                        if (!totalDataMap[startDate].projectionData[row.ASIN]) {
                            totalDataMap[startDate].projectionData[row.ASIN] = {
                                units: parseInt(value as string),
                            };
                        } else {
                            totalDataMap[startDate].projectionData[row.ASIN] = {
                                units: parseInt(value as string),
                            };
                        }
                    }
                });
            });

            // sum up preivous weeks to get total projected and ordered units for each ASIN
            Object.entries(totalDataMap).forEach(([date, data], idx, arr) => {
                const previousData = arr.slice(0, idx + 1).map(([_, data]) => data);
                Object.entries(data.projectionData).forEach(([asin, asinData]) => {
                    const projectedUnitsTotal = previousData.reduce((acc: number, curr) => {
                        return acc + (curr.projectionData[asin]?.units ?? 0);
                    }, 0);

                    const orderedUnitsTotal = previousData.reduce((acc: number, curr) => {
                        return acc + (curr.orderData[asin]?.units ?? 0);
                    }, 0);

                    const orderedValueTotal = previousData.reduce((acc: number, curr) => {
                        return acc + (curr.orderData[asin]?.value ?? 0);
                    }, 0);

                    totalDataMap[date].projectionData[asin] = {
                        ...totalDataMap[date].projectionData[asin],
                        projectedUnitsTotal,
                    };

                    totalDataMap[date].orderData[asin] = {
                        ...totalDataMap[date].orderData[asin],
                        orderedUnitsTotal,
                        orderedValueTotal,
                    };
                });
            });

            // sum up previous weeks to get total projected and ordered units
            Object.entries(totalDataMap).forEach(([date, data]) => {
                const projectedUnits = Object.values(data.projectionData).reduce((acc: number, curr: any) => (acc += curr.units), 0);
                const projectedUnitsTotal = Object.values(data.projectionData).reduce(
                    (acc: number, curr: any) => (acc += curr.projectedUnitsTotal),
                    0
                );

                const orderedUnits = Object.values(data.orderData).reduce((acc: number, curr: any) => (acc += curr.units), 0);
                const orderedUnitsTotal = Object.values(data.orderData).reduce(
                    (acc: number, curr: any) => (acc += curr.orderedUnitsTotal),
                    0
                );

                totalDataMap[date] = {
                    ...totalDataMap[date],
                    projectedUnits,
                    projectedUnitsTotal,
                    orderedUnits,
                    orderedUnitsTotal,
                };
            });

            const supplierItemsMap: {[key: string]: BrandDashboardItem} = {};
            selectedSupplierItems.forEach((item) => {
                supplierItemsMap[item.ASIN] = item;
            });

            const inventoryMap: {[key: string]: InventoryItem[]} = {};
            inventoryData.forEach((item) => {
                if (inventoryMap[item.asin]) {
                    inventoryMap[item.asin].push(item);
                } else {
                    inventoryMap[item.asin] = [item];
                }
            });

            console.log("inventoryMap", inventoryMap);

            // find the week for current day
            const currentWeek =
                weeks.find((week, idx) => {
                    if (dayjs(week).add(6, "days").isSameOrAfter(dayjs().format("YYYY-MM-DD"))) {
                        return true;
                    }
                    return false;
                }) || weeks[weeks.length - 1];

            setSelectedWeek(currentWeek);

            for (const week of weeks) {
                Object.entries(totalDataMap[week].orderData).map(([asin, entryData]) => {
                    const actual = entryData;
                    const projected = totalDataMap[week].projectionData[asin].units;
                    const totalActual = entryData.orderedUnitsTotal;
                    const totalProjected = totalDataMap[week].projectionData[asin].projectedUnitsTotal;

                    const tableDataEntry = {
                        asin,
                        title: supplierItemsMap[asin]?.Title ?? "Unknown",
                        cost: supplierItemsMap[asin]?.Cost ?? -1,
                        stock:
                            inventoryMap[asin]?.length > 0
                                ? inventoryMap[asin].reduce(
                                      (acc, curr) =>
                                          (acc +=
                                              parseInt(curr["afn-fulfillable-quantity"] || "0") +
                                              parseInt(curr["mfn-fulfillable-quantity"] || "0") +
                                              parseInt(curr["afn-reserved-future-supply"] || "0") +
                                              parseInt(curr["afn-inbound-receiving-quantity"] || "0") +
                                              parseInt(curr["afn-inbound-shipped-quantity"] || "0") +
                                              parseInt(curr["afn-inbound-working-quantity"] || "0")),
                                      0
                                  )
                                : 0,
                        projected: projected,
                        actual: actual.units,
                        difference: actual.units - projected,
                        percDiff: ((actual.units - projected) / projected) * 100,
                        revenue: actual.value,
                        totalProjected,
                        totalActual,
                        totalDifference: totalActual - totalProjected,
                        totalPercDiff: ((totalActual - totalProjected) / totalProjected) * 100,
                        totalRevenue: entryData.orderedValueTotal,
                    };

                    totalDataMap[week].tableData = [...(totalDataMap[week].tableData ?? []), tableDataEntry];
                    return tableDataEntry;
                });
            }

            setTotalDataMap(totalDataMap);
            setIsLoading(false);
        };

        reader.readAsText(fileMap.sellthroughImport as Blob);
    };

    console.log("totalData", totalDataMap);

    const tableColumns: ColumnType<any>[] = useMemo(() => {
        return [
            {
                title: "ASIN",
                dataIndex: "asin",
                key: "asin",
                filteredValue: filteredInfo?.asin || null,
                ...GetExpandedFilter([], "asin"),
                render: (value: string) => {
                    return (
                        <>
                            <Typography.Link
                                style={{textAlign: "center"}}
                                href={`https://www.amazon.com/dp/${value}/?th=1&psc=1`}
                                target="_blank"
                            >
                                {value}
                            </Typography.Link>
                            {/* <a href={`https://www.amazon.com/dp/${value}?th=1&psc=1`} target="_blank" rel="noreferrer">
                                <AmazonCircleFilled style={{color: "#FF9900", marginLeft: "5px"}} />
                            </a> */}
                            <Divider type="vertical" />
                            <Typography.Link
                                italic={true}
                                onClick={() =>
                                    modal.info({
                                        width: "95%",
                                        bodyStyle: {height: "90vh", overflow: "auto", scale: 0.8},
                                        title: `Single Browser for ${value}`,
                                        content: <SingleBrowser asin={value}></SingleBrowser>,
                                    })
                                }
                            >
                                <Tooltip placement="topLeft" title="Check product's Omega page">
                                    Omega
                                </Tooltip>
                            </Typography.Link>
                        </>
                    );
                },
                onCell: (record) => {
                    return {
                        onClick: (event) => extraFilterClickHandlers(filteredInfo, setFilteredInfo, {dataIndex: "asin"}, record)(event),
                    };
                },
            },
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
                width: "20%",
            },
            {
                title: "Cost",
                dataIndex: "cost",
                key: "cost",
            },
            {
                title: "Stock",
                dataIndex: "stock",
                key: "stock",
            },
            {
                title: <Divider type="vertical"></Divider>,
                align: "center",
                render: () => (
                    <div>
                        <Divider type="vertical"></Divider>
                    </div>
                ),
            },
            {
                title: "Selected week sales",
                children: [
                    {
                        title: "Projected",
                        dataIndex: "projected",
                        key: "projected",
                    },
                    {
                        title: "Actual",
                        dataIndex: "actual",
                        key: "actual",
                    },
                    {
                        title: "Diff",
                        dataIndex: "difference",
                        key: "difference",
                    },
                    {
                        title: "Diff %",
                        dataIndex: "percDiff",
                        key: "percDiff",
                        render: (value: number) => {
                            // return a colored Tag based on the value
                            return (
                                <Tag color={value > 0 ? "success" : "error"} style={{fontSize: "12px"}}>
                                    {value.toFixed(2)}% {value >= 0 ? "over goal" : "under goal"}
                                </Tag>
                            );
                        },
                    },
                    {
                        title: "Revenue",
                        dataIndex: "revenue",
                        key: "revenue",
                        render: (value: number) => {
                            return `$${value.toLocaleString()}`;
                        },
                    },
                    {
                        title: <Divider type="vertical"></Divider>,
                        align: "center",
                        render: () => (
                            <div>
                                <Divider type="vertical"></Divider>
                            </div>
                        ),
                    },
                ],
            },
            {
                title: "Sales so far",
                children: [
                    {
                        title: "Projected",
                        dataIndex: "totalProjected",
                        key: "totalProjected",
                    },
                    {
                        title: "Actual",
                        dataIndex: "totalActual",
                        key: "totalActual",
                    },
                    {
                        title: "Diff",
                        dataIndex: "totalDifference",
                        key: "totalDifference",
                    },
                    {
                        title: "Diff %",
                        dataIndex: "totalPercDiff",
                        key: "totalPercDiff",
                        render: (value: number) => {
                            // return a colored Tag based on the value
                            return (
                                <Tag color={value > 0 ? "success" : "error"} style={{fontSize: "12px"}}>
                                    {value.toFixed(2)}% {value >= 0 ? "over goal" : "under goal"}
                                </Tag>
                            );
                        },
                    },
                    {
                        title: "Revenue",
                        dataIndex: "totalRevenue",
                        key: "totalRevenue",
                        render: (value: number) => {
                            return `$${value.toLocaleString()}`;
                        },
                    },
                ],
            },
        ];
    }, [modal, filteredInfo]);

    useEffect(() => {
        if (!selectedWeek) return;

        let goalMap = {
            "> 50%": 0,
            "20% to 50%": 0,
            "0% to 20%": 0,
            "-20% to 0%": 0,
            "-50% to -20%": 0,
            "< -50%": 0,
        };

        filterTableData(filteredInfo, tableColumns, totalDataMap[selectedWeek].tableData).forEach((row) => {
            const diff = row.totalPercDiff;
            if (diff > 0.5) {
                goalMap["> 50%"] += row.cost * row.stock;
            } else if (diff > 0.2) {
                goalMap["20% to 50%"] += row.cost * row.stock;
            } else if (diff > 0) {
                goalMap["0% to 20%"] += row.cost * row.stock;
            } else if (diff > -0.2) {
                goalMap["-20% to 0%"] += row.cost * row.stock;
            } else if (diff > -0.5) {
                goalMap["-50% to -20%"] += row.cost * row.stock;
            } else {
                goalMap["< -50%"] += row.cost * row.stock;
            }
        });

        setPieChartData(
            Object.entries(goalMap).map(([bracket, inventoryValue]) => {
                return {
                    bracket,
                    inventoryValue,
                };
            })
        );

        let lineChartData: any[] = [];
        Object.entries(totalDataMap).forEach(([date, data]) => {
            const applicableEntries = filterTableData(filteredInfo, tableColumns, data.tableData);
            let totalProjected = 0;
            let totalActual = 0;

            applicableEntries.forEach((entry) => {
                totalProjected += entry.totalProjected;
                totalActual += entry.totalActual;
            });

            lineChartData.push({
                week: date,
                type: "Projected",
                units: totalProjected,
            });

            lineChartData.push({
                week: date,
                type: "Actual",
                units: totalActual,
            });
        });

        setLineChartData(lineChartData);

        let weeklyChartData: any[] = [];
        Object.entries(totalDataMap).forEach(([date, data]) => {
            const applicableEntries = filterTableData(filteredInfo, tableColumns, data.tableData);
            let totalProjected = 0;
            let totalActual = 0;

            applicableEntries.forEach((entry) => {
                totalProjected += entry.projected;
                totalActual += entry.actual;
            });

            weeklyChartData.push({
                week: date,
                type: "Projected",
                units: totalProjected,
            });

            weeklyChartData.push({
                week: date,
                type: "Actual",
                units: totalActual,
            });

            weeklyChartData.push({
                week: date,
                type: "Balance",
                units: totalActual - totalProjected,
            });
        });

        setWeeklyChartData(weeklyChartData);
    }, [totalDataMap, selectedWeek, filteredInfo, tableColumns]);

    const lineConfig = {
        data: lineChartData,
        xField: "week",
        seriesField: "type",
        colorField: "type",
        yField: "units",
        // children: [
        //     {
        //         type: "line",
        //         yField: "projectedUnitsTotal",
        //         style: {
        //             stroke: "#5B8FF9",
        //             lineWidth: 2,
        //         },
        //         axis: {
        //             y: {
        //                 title: "Projected Units",
        //                 style: {titleFill: "#5B8FF9"},
        //             },
        //         },
        //     },
        //     {
        //         type: "line",
        //         yField: "orderedUnitsTotal",
        //         style: {
        //             stroke: "#5AD8A6",
        //             lineWidth: 2,
        //         },
        //         axis: {
        //             y: {
        //                 position: "right",
        //                 title: "Ordered Units",
        //                 style: {titleFill: "#5AD8A6"},
        //             },
        //         },
        //     },
        // ],
        theme: "classicDark",
    };

    const pieConfig = {
        height: 400,
        data: pieChartData,
        angleField: "inventoryValue",
        colorField: "bracket",
        label: {
            render: (_: any, datum: any) => {
                if (datum.inventoryValue > 0) {
                    return (
                        <div style={{fontWeight: "bold", fontSize: "14px", fill: "white", fillOpacity: 1, textAlign: "center"}}>
                            ${datum.inventoryValue.toLocaleString()}
                        </div>
                    );
                } else {
                    return <></>;
                }
            },
        },
        legend: {
            color: {
                title: false,
                position: "right",
                rowPadding: 5,
            },
        },
        theme: "classicDark",
    };

    const weeklyBarConfig = {
        data: weeklyChartData,
        seriesField: "type",
        colorField: "type",
        xField: "week",
        yField: "units",
        legend: true,
        label: {
            fill: "white",
            fillOpacity: 1,
            textBaseline: "bottom",
            position: "top",
            textAlign: "center",
        },
        axis: {
            x: {
                title: "Date",
            },
            y: {
                title: "Units sold",
            },
        },
        theme: "classicDark",
    };

    console.log("filteredInfo", filteredInfo);
    console.log("filteredAsins", selectedWeek && filterTableData(filteredInfo, tableColumns, totalDataMap[selectedWeek]?.tableData ?? []));

    return (
        <Row>
            <Col span={6}>
                <Card style={{minHeight: "100%"}} title={"Upload your projections"}>
                    <Space direction="horizontal">
                        <Upload
                            beforeUpload={(file: RcFile) => beforeUpload(file)}
                            onRemove={onRemove}
                            accept={".csv"}
                            name="sellthroughData"
                            listType="text"
                        >
                            <Button block icon={<UploadOutlined />}>
                                Upload Sell-through
                            </Button>
                        </Upload>
                        <Button
                            block
                            type="primary"
                            onClick={() => handleUpload()}
                            disabled={!fileMap.sellthroughImport || selectedSupplierItems.length === 0}
                            loading={isLoading}
                        >
                            {isLoading ? "Uploading" : "Start Upload"}
                        </Button>
                    </Space>
                </Card>
            </Col>
            <Col span={6}>
                <Card style={{minHeight: "100%"}} title={"Select your week"}>
                    <Select
                        optionFilterProp="children"
                        filterOption={(input, option) => option?.value?.toString().toLowerCase().includes(input.toLowerCase()) || false}
                        placeholder="Please select Week"
                        allowClear
                        onChange={(week: string) => setSelectedWeek(week)}
                        value={selectedWeek}
                        options={dateRange.map((date) => ({
                            value: date,
                            label: `${dayjs(date).format("MMM DD, YYYY")} to ${dayjs(date).add(6, "days").format("MMM DD, YYYY")}`,
                        }))}
                        style={{width: "100%"}}
                    ></Select>
                </Card>
            </Col>
            <Col span={18}>
                <Card style={{minHeight: "100%"}} title={"Sell-through data"}>
                    {contextHolder}
                    <Table
                        dataSource={selectedWeek ? totalDataMap[selectedWeek]?.tableData ?? [] : []}
                        style={{
                            width: "100%",
                        }}
                        onChange={(pagination, filters, sorter, extra) => {
                            setFilteredInfo(filters);
                        }}
                        columns={tableColumns as ColumnType<any>[]}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card style={{maxHeight: "100%"}} title={"Inv Value over Goals"}>
                    <Pie {...pieConfig} style={{maxHeight: "300px"}} />
                </Card>
            </Col>
            <Col span={12}>
                <Card style={{minHeight: "100%"}} title={"Units sold throughout time"}>
                    <Line {...lineConfig} />
                </Card>
            </Col>
            <Col span={12}>
                <Card style={{minHeight: "100%"}} title={"Units sold per week"}>
                    <Column {...weeklyBarConfig} />
                </Card>
            </Col>
        </Row>
    );
};

export default BrandSellthrough;
