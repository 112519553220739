import React, {useEffect, useState} from "react";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import {Button, Space, Row, Col, Spin, Empty, List, Card, Descriptions, Input, notification} from "antd";
import {InboundPackingOption} from "../../../types/WarehouseTypes";
import PackingOptionView from "./PackingOptionView";
import {ArrowRightOutlined} from "@ant-design/icons";
import PlacementOptionsList from "./PlacementOptionsList";

const PackingOptionsList: React.FC<{options: InboundPackingOption[]; onSelect: (packingOptionId: string) => void}> = ({
    options,
    onSelect,
}) => {
    const selectedId = useStore((state) => state.packFirstBoxingData.selectedPackingOptionId);
    if (!options.length) {
        return <Empty style={{width: "100%"}} />;
    }

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <List
                style={{height: "80vh", overflow: "auto"}}
                header="Packing Options"
                dataSource={options}
                renderItem={(option) => (
                    <List.Item>
                        <Card
                            title={option.name}
                            style={{
                                width: "100%",
                                margin: 4,
                                cursor: "pointer",
                                borderColor: selectedId === option.packingOptionId ? "#3166D4" : undefined,
                            }}
                            extra={<Button onClick={() => window.open(`https://sellercentral.amazon.com/fba/sendtoamazon?wf=${option.inboundPlanId}`, '_blank')}>SC</Button>}
                            onClick={() => onSelect(option.packingOptionId)}
                        >
                            <Descriptions
                                column={1}
                                bordered
                                size="small"
                                items={[
                                    {key: "1", label: "Status", children: option.processingStatus},
                                    {key: "2", label: "Plan ID", children: option.inboundPlanId},
                                    {key: "3", label: "Packing Option ID", children: option.packingOptionId},
                                ]}
                            />
                        </Card>
                    </List.Item>
                )}
            />
        </Space>
    );
};

const BoxingDashboard: React.FC = () => {
    const {actions, data} = useStore(
        useShallow((state) => ({
            actions: state.packFirstBoxingActions,
            data: state.packFirstBoxingData,
        }))
    );
    const [code, setCode] = useState("");

    useEffect(() => {
        actions.getData();
    }, [actions]);

    const handleScan = (code: string) => {
        try {
            code = code.toUpperCase();
            actions.scanItem(code);
            setCode("");
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
            setCode("");
        }
    };

    const options = Object.values(data.packingOptions.byId);

    const selectedPackingOption = data.packingOptions.byId[data.selectedPackingOptionId || ""];
    const isPlacementMode = selectedPackingOption?.viewMode === "PLACEMENT";
    const hasPlacementOptions = (selectedPackingOption?.placementOptions?.length || 0) > 0;
    const lastUpdateTimestamp = selectedPackingOption?.lastUpdate || 0;
    const packingInformationTimestamp = selectedPackingOption?.packingInformationTimestamp || 0;
    return (
        <Spin spinning={data.isDataLoading} style={{width: "100%"}}>
            <Row style={{width: "100%", scrollbarWidth: "thin", scrollbarColor: "#808080 #00000000"}}>
                <Col xs={10} lg={8} xxl={6} style={{paddingRight: 8}}>
                    <PackingOptionsList options={options} onSelect={actions.setSelectedPackingOptionId} />
                </Col>
                <Col xs={14} lg={16} xxl={18} style={{paddingLeft: 8}}>
                    {isPlacementMode ? (
                        <PlacementOptionsList 
                            placementOptions={selectedPackingOption?.placementOptions || []}
                        />
                    ) : (
                        <>
                            <Row>
                                <Col span={18}>
                                    <Input
                                        size="large"
                                        placeholder="Scan items"
                                        value={code}
                                        onChange={(event) => setCode(event.target.value)}
                                        onPressEnter={() => handleScan(code)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Space style={{width: "100%", justifyContent: "space-evenly"}}>
                                        <Button size="large" type="primary" onClick={() => handleScan(code)}>
                                            Scan
                                        </Button>
                                        <Button
                                            icon={<ArrowRightOutlined />}
                                            iconPosition="end"
                                            onClick={() => actions.setPackingOptionViewMode(data.selectedPackingOptionId || "", "PLACEMENT")}
                                            disabled={!hasPlacementOptions || lastUpdateTimestamp > packingInformationTimestamp}
                                        >
                                            Placements
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                            <PackingOptionView option={selectedPackingOption} />
                        </>
                    )}
                </Col>
            </Row>
        </Spin>
    );
};

export default BoxingDashboard;
