import React from "react";
import {Button, Col, Divider, List, message, notification, Popover, Row, Space, Tag, Tooltip, Typography} from "antd";
import Link from "antd/es/typography/Link";
import {WholesaleItem} from "../../../types/WholesaleItem";
import {GetExpandedFilter} from "../../utilities/ExpandedFilterDropdown";
import {InfoCircleFilled, WarningFilled} from "@ant-design/icons";
import {ColumnsType} from "antd/lib/table";
import {
    getBooleanFilter,
    getNumberFilterSorter,
    getNumberRenderer,
    getPriceRenderer,
    getSellPriceRenderer,
    getShareRenderer,
} from "../../utilities/TableFilterSorters";
import ReturnsPopover from "./ReturnsPopover";
import ProfitPopover from "./ProfitPopover";
import ReplenProfitPopover from "./ReplenProfitPopover";
import EstSalesPopover from "./EstSalesPopover";
import ReplenSalesPopover from "./ReplenSalesPopover";
import InventoryPopover from "./InventoryPopover";
import {BRAND_OPPORTUNITIES, OMSItem, SEASONAL_TAGS} from "../../../types/OmegaTypes";
import {WS_POSSIBLE_ISSUES, POSSIBLE_STAGES_WS} from "../ItemCalcs";
import dayjs from "dayjs";
import {saveProduct} from "../../../services/WholesaleService";
import {confirmSave, getIssueColor} from "./WholesaleUtilities";
import {ModalStaticFunctions} from "antd/es/modal/confirm";
import NotesRenderer, {ExpandedNote} from "../../utilities/NotesRenderer";
import SingleBrowser from "../../omega/SingleBrowser";
import {GetCategoryEstSalesData} from "../../brand/SalesRankConstants";
import StrictNotesRenderer from "../../utilities/StrictNotesRenderer";
import {GlobalNoteItem} from "../../../types/Brand";
import {postGlobalNote} from "../../../services/BrandService";
import GMROIPopover from "./GMROIPopover";
import ParentDistributionPopover from "./ParentDistributionPopover";

export interface WholesaleDataType extends WholesaleItem {
    key: React.Key;
    azImg: number;
    Profit: number;
    cost: number;
    AskPrice: number;
    bbOwner: number;
    bbOOS: number;
    azOOS: number;
    bbStock: number;
    azStock: number;
    sellPrice: number;
    keepaRating: number;
    sizeMultiplier: number;
    colorMultiplier: number;
    reviewMultiplier: number;
    bbMultiplier: number;
    SuggestedQTY: number;
    variationReviews: number;
    variationReviewsTotal: number;
    variationActive: number;
    variationChecked: boolean;
    adjustedWeight: number;
    editedShippingCost: number;
    recentlyEdited: boolean;
    editSiblings: boolean;
    wsTTS: number;
    wsVelTTS: number;
    wsGMROITTS: number;
    wsGMROI: number;
    comments: (string | ExpandedNote)[] | string;
    returnRate: number;
    returnUnsellableRate: number;
    shippingFeeBase: number;
    shippingFeeMulti: number;
    prepFee: number;
    prepDelay: number;
    desiredRoi: number;
    askPriceMultiplier: number;
    targetDIS: number;
    ccAccepted: boolean;
    canEditCancel: boolean;
    netTerm: number;
    replenBBMultiplier: number;
    replenProfit: number;
    replenROI: number;
    replenEstSales: number;
    replenAllSales: number;
    replenTargetDTB: number;
    replenDaysToBuy: number;
    replenToBuy: number;
    replenTTS: number;
    replenIncoming: number;
    anticipatedVelocity: number;
    SizeTier: string;
    SmallLightFee: number;
    shipDimWeight: number;
    refFeeSPAPI: number;
    refFeeSPAPIBase: number;
    productGroup: string;
    treeCategory?: any;
}

export interface WholesaleDataUpdate {
    type: "partial" | "full" | "classic";
    data: WholesaleDataType | WholesaleItem[] | WholesaleDataType[];
}

export const WHOLESALE_CELL_COLORS = {
    BLUE: "#1677ffb0",
    YELLOW: "#fadb14b0",
    GREEN: "#52c41ab0",
    RED: "#f5222db0",
};

const GetProfitWarnings = (record: WholesaleDataType) => {
    let warningString = ``;

    if (record.Fees <= 0) {
        warningString += "Keepa has no fee data - consult RevSeller!\n";
    }

    if (record.meanFee > 0 && (record.Fees > 1.1 * record.meanFee || record.Fees < 0.9 * record.meanFee)) {
        warningString += `Product fees deviate from parent's mean fees!\n`;
    }

    if (warningString.length > 0) {
        return (
            <Tooltip placement="top" title={warningString}>
                {" "}
                <WarningFilled style={{color: "red"}} />
            </Tooltip>
        );
    } else {
        return null;
    }
};

export const GetWholesaleColumns = (
    updateTableData: React.Dispatch<WholesaleItem[] | WholesaleDataType | WholesaleDataType[] | WholesaleDataUpdate>,
    modal: Omit<ModalStaticFunctions, "warn">
): ColumnsType<WholesaleDataType> => {
    return [
        {
            title: "ASIN",
            dataIndex: "ASIN",
            key: "ASIN",
            width: "120px",
            fixed: "left",
            ...GetExpandedFilter([], "ASIN"),
            render: (text: string, record: WholesaleItem) => (
                <Space
                    style={{width: "100%", justifyContent: "center", textAlign: "center"}}
                    size="small"
                    align="center"
                    direction="vertical"
                >
                    <Link style={{textAlign: "center"}} href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                        {text}
                    </Link>
                    <div style={{width: "100%", justifyContent: "center", textAlign: "center", marginTop: "5px"}}>
                        <Link italic={true} href={`https://sellercentral.amazon.com/product-search/search?q=${text}`} target="_blank">
                            <Tooltip placement="topLeft" title="Check product's (possible) restrictions">
                                SC
                            </Tooltip>
                        </Link>
                        <Divider type="vertical" />
                        <Link
                            disabled={!(record.ssSkus > 0)}
                            italic={true}
                            onClick={() =>
                                modal.info({
                                    width: "95%",
                                    bodyStyle: {height: "90vh", overflow: "auto", scale: 0.8},
                                    title: `Single Browser for ${text}`,
                                    content: <SingleBrowser asin={text}></SingleBrowser>,
                                })
                            }
                        >
                            <Tooltip placement="topLeft" title="Check product's Omega page">
                                Omega
                            </Tooltip>
                        </Link>
                    </div>
                </Space>
            ),
        },
        {
            title: "Amazon Image",
            key: "azImg",
            dataIndex: "azImg",
            width: "125px",
            render: (text: string, record: WholesaleDataType) => {
                return (
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.amazon.com/dp/${record.ASIN}/?th=1&psc=1`}>
                        <img src={text} height={125} width={125} alt="Amazon product" />
                    </a>
                );
            },
        },
        {
            title: "UPC",
            dataIndex: "UPC",
            key: "UPC",
            width: "110px",
            ...GetExpandedFilter([], "UPC"),
            render: (text: string, record: WholesaleItem) => text,
        },
        {
            title: "Parent",
            dataIndex: "parentASIN",
            key: "parentASIN",
            width: "95px",
            sorter: {
                compare: (a, b) => (a.parentASIN > b.parentASIN ? 1 : a.parentASIN === b.parentASIN ? 0 : -1),
                multiple: 10,
            },
            ...GetExpandedFilter([], "parentASIN"),
            render: (val, record) => {
                if (record.parentASIN) {
                    return (
                        <Popover
                            destroyTooltipOnHide={true}
                            id="buyqty-popover"
                            autoAdjustOverflow={false}
                            content={<ParentDistributionPopover record={record} />}
                            trigger="hover"
                            title={null}
                        >
                            <Space direction="horizontal" align="center">
                                <Typography>{val}</Typography>
                                <InfoCircleFilled />
                            </Space>
                        </Popover>
                    );
                } else {
                    return val;
                }
            },
        },
        {
            title: "Reviews",
            key: "reviewCount",
            dataIndex: "reviewCount",
            width: "90px",
            ...getNumberFilterSorter("reviewCount"),
        },
        {
            title: "Title",
            dataIndex: "name",
            key: "name",
            width: "175px",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1,
            },
            ...GetExpandedFilter([], "name"),
        },
        {
            title: "SellPrice",
            dataIndex: "sellPrice",
            key: "sellPrice",
            width: "95px",
            ...getNumberFilterSorter("sellPrice"),
            ...getSellPriceRenderer(),
        },
        {
            title: "ParentSellPrice",
            dataIndex: "ParentSellPrice",
            key: "ParentSellPrice",
            width: "95px",
            ...getNumberFilterSorter("ParentSellPrice"),
            ...getPriceRenderer(),
        },
        {
            title: "BB_30D",
            dataIndex: "buyBox30Avg",
            key: "buyBox30Avg",
            width: "95px",
            ...getNumberFilterSorter("buyBox30Avg"),
            ...getPriceRenderer(),
        },
        {
            title: "BB_90D",
            dataIndex: "buyBox90Avg",
            key: "buyBox90Avg",
            width: "95px",
            ...getNumberFilterSorter("buyBox90Avg"),
            ...getPriceRenderer(),
        },
        {
            title: "BB_180D",
            dataIndex: "buyBox180Avg",
            key: "buyBox180Avg",
            width: "95px",
            ...getNumberFilterSorter("buyBox180Avg"),
            ...getPriceRenderer(),
        },
        {
            title: "Cost",
            key: "cost",
            dataIndex: "cost",
            width: "85px",
            ...getNumberFilterSorter("cost"),
        },
        {
            title: "Returns",
            key: "totalReturnCost",
            dataIndex: "totalReturnCost",
            width: "95px",
            ...getNumberFilterSorter("totalReturnCost"),
            render: (val, record) => (
                <Popover
                    id="returns-popover"
                    autoAdjustOverflow={false}
                    content={<ReturnsPopover record={record} handleSave={updateTableData}></ReturnsPopover>}
                    trigger="hover"
                >
                    <Typography>{`$${val.toFixed(2)}`}</Typography>
                </Popover>
            ),
        },
        {
            title: "ReturnConf",
            key: "returnConfidenceLevel",
            dataIndex: "returnConfidenceLevel",
            width: "95px",
            ...GetExpandedFilter(["ASIN", "Parent", "Category", "Basic"], "returnConfidenceLevel"),
            render: (tags) => (
                <Space direction="vertical" align="center" style={{width: "100%"}}>
                    <Tag style={{marginRight: 0}}>{tags}</Tag>
                </Space>
            ),
        },
        {
            title: "ReturnRate",
            key: "returnRate",
            dataIndex: "returnRate",
            width: "95px",
            ...getNumberFilterSorter("returnRate"),
            render: (val) => <Typography>{val >= 0 ? `${(val * 100).toFixed(2)}%` : val ? val : "?"}</Typography>,
        },
        {
            title: "UnsellableRate",
            key: "returnUnsellableRate",
            dataIndex: "returnUnsellableRate",
            width: "110px",
            ...getNumberFilterSorter("returnUnsellableRate"),
            render: (val) => <Typography>{val >= 0 ? `${(val * 100).toFixed(2)}%` : val ? val : "?"}</Typography>,
        },
        {
            title: "Profit",
            key: "Profit",
            dataIndex: "Profit",
            width: "85px",
            ...getNumberFilterSorter("Profit"),
            render: (val, record) => (
                <Popover
                    id="profit-popover"
                    autoAdjustOverflow={false}
                    content={<ProfitPopover record={record} handleSave={updateTableData}></ProfitPopover>}
                    trigger="click"
                >
                    <Typography>
                        {`$${val.toFixed(2)}`}
                        {GetProfitWarnings(record)}
                        {/* {record.SmallLightFee > 0 && <Tooltip placement="top" title={'Item might be elligible for Small&Light fees at under $12 SellPrice!'}> <AmazonOutlined /> </Tooltip>} */}
                    </Typography>
                </Popover>
            ),
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if ((record.productGroup === "Shoes" && record.Profit > 5) || (record.productGroup !== "Shoes" && record.Profit > 2.5)) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                } else {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                if (record.currentBuyBoxPrice <= 0) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.BLUE;
                }

                return {style};
            },
        },
        {
            title: "RepProfit",
            key: "replenProfit",
            dataIndex: "replenProfit",
            width: "105px",
            ...getNumberFilterSorter("replenProfit"),
            // ...getPriceRenderer(),
            render: (val, record) => (
                <Popover
                    id="profit-popover"
                    autoAdjustOverflow={false}
                    content={<ReplenProfitPopover record={record} handleSave={updateTableData}></ReplenProfitPopover>}
                    trigger="click"
                >
                    <Typography>{`$${val.toFixed(2)}`}</Typography>
                </Popover>
            ),
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (
                    (record.productGroup === "Shoes" && record.replenProfit > 5) ||
                    (record.productGroup !== "Shoes" && record.replenProfit > 2.5)
                ) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                } else {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                if (record.currentBuyBoxPrice <= 0) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.BLUE;
                }

                return {style};
            },
        },
        {
            title: "ROI",
            key: "ROI2",
            dataIndex: "ROI2",
            width: "70px",
            ...getNumberFilterSorter("ROI2"),
            render: (val, record) => (
                <Popover
                    id="profit-popover"
                    autoAdjustOverflow={false}
                    content={<ProfitPopover record={record} handleSave={updateTableData}></ProfitPopover>}
                    trigger="click"
                >
                    <Typography>
                        {`${val.toFixed(2)}%`}
                        {record.Fees <= 0 ? (
                            <Tooltip placement="top" title={"Keepa has no fee data - consult RevSeller!"}>
                                {" "}
                                <WarningFilled style={{color: "red"}} />
                            </Tooltip>
                        ) : record.meanFee > 0 && (record.Fees > 1.1 * record.meanFee || record.Fees < 0.9 * record.meanFee) ? (
                            <Tooltip placement="top" title={`Product fees deviate from parent's mean fees`}>
                                {" "}
                                <WarningFilled style={{color: "red"}} />
                            </Tooltip>
                        ) : null}
                    </Typography>
                </Popover>
            ),
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (record.ROI2 < 15) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                } else if (record.ROI2 < 25) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                } else {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                }

                if (record.currentBuyBoxPrice <= 0) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.BLUE;
                }

                return {style};
            },
        },
        {
            title: "RepROI",
            key: "replenROI",
            dataIndex: "replenROI",
            width: "105px",
            ...getNumberFilterSorter("replenROI"),
            // render: (val, record) => <Typography>{`${val.toFixed(2)}%`}</Typography>,
            // ...getNumberRenderer(),
            render: (val, record) => (
                <Popover
                    id="profit-popover"
                    autoAdjustOverflow={false}
                    content={<ReplenProfitPopover record={record} handleSave={updateTableData}></ReplenProfitPopover>}
                    trigger="click"
                >
                    <Typography>{`${val.toFixed(2)}%`}</Typography>
                </Popover>
            ),
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (record.replenROI < 15) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                } else if (record.replenROI < 25) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                } else {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                }

                if (record.currentBuyBoxPrice <= 0) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.BLUE;
                }

                return {style};
            },
        },
        {
            title: "BoughtPastMonth",
            key: "Bought in past month",
            dataIndex: "Bought in past month",
            width: "140px",
            ...getNumberFilterSorter("Bought in past month"),
            ...getNumberRenderer(0),
        },
        {
            title: "EstSales",
            key: "EstSales",
            dataIndex: "EstSales",
            width: "95px",
            ...getNumberFilterSorter("EstSales"),
            render: (val, record) => {
                const salesRanks = [record.currentSalesRank, record.salesRank30Avg, record.salesRank90Avg];
                const minRank = Math.min(...salesRanks);
                const maxRank = Math.max(...salesRanks);

                return (
                    <Popover
                        id="wholesale-popover"
                        autoAdjustOverflow={false}
                        key={record.ASIN}
                        destroyTooltipOnHide={true}
                        content={<EstSalesPopover {...{record, handleSave: updateTableData}}></EstSalesPopover>}
                        trigger="click"
                        title={
                            maxRank / minRank >= 3 ? (
                                <>
                                    <WarningFilled style={{color: "red"}} /> High variance detected <WarningFilled style={{color: "red"}} />
                                </>
                            ) : null
                        }
                    >
                        <Typography>
                            {`${val.toFixed(2)}`}{" "}
                            {maxRank / minRank >= 3 || record.currentSalesRank === -1 ? (
                                <Tooltip placement="topLeft" title="Check product's SalesRank!">
                                    <WarningFilled style={{color: "darkred"}} />
                                </Tooltip>
                            ) : null}
                        </Typography>
                    </Popover>
                );
            },
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (
                    record.reviewMultiplier < 1 ||
                    record.variationReviewsTotal > 50 ||
                    record.parentASIN === record.ASIN ||
                    record.parentASIN.length === 0
                ) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                } else if (record.reviewMultiplier > 1 || (record.variationReviewsTotal >= 0 && record.variationReviewsTotal < 50)) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                } else if (record.variationReviewsKeepa === record.reviewCount && record.parentASIN !== record.ASIN) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                if (record.activeOffers === 0) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.BLUE;
                }

                if (record.Issues.includes("Check ParentRevs")) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                return {style};
            },
        },
        {
            title: "PastSoldMax",
            key: "KeepaMaxSold",
            dataIndex: "KeepaMaxSold",
            width: "120px",
            ...getNumberFilterSorter("KeepaMaxSold"),
            render: (val, record) => (
                <Typography style={{textAlign: "center"}}>
                    {val ? (
                        <>
                            {val}
                            <br />({record.KeepaMaxSoldDate})
                        </>
                    ) : (
                        -1
                    )}
                </Typography>
            ),
        },
        {
            title: "EstChildSales",
            key: "EstChildSales",
            dataIndex: "EstChildSales",
            width: "120px",
            ...getNumberFilterSorter("EstChildSales"),
            ...getPriceRenderer(),
        },
        {
            title: "EstParentSales",
            key: "EstParentSales",
            dataIndex: "EstParentSales",
            width: "120px",
            ...getNumberFilterSorter("EstParentSales"),
            ...getPriceRenderer(),
        },
        {
            title: "EstSalesTotal",
            key: "EstSalesTotal",
            dataIndex: "EstSalesTotal",
            width: "110px",
            ...getNumberFilterSorter("EstSalesTotal"),
            render: (val, record) => {
                const salesRanks = [record.currentSalesRank, record.salesRank30Avg, record.salesRank90Avg];
                const minRank = Math.min(...salesRanks);
                const maxRank = Math.max(...salesRanks);

                return (
                    <Popover
                        id="wholesale-popover"
                        autoAdjustOverflow={false}
                        key={record.ASIN}
                        destroyTooltipOnHide={true}
                        content={<EstSalesPopover {...{record, handleSave: updateTableData}}></EstSalesPopover>}
                        trigger="click"
                        title={
                            maxRank / minRank >= 3 ? (
                                <>
                                    <WarningFilled style={{color: "red"}} /> High variance detected <WarningFilled style={{color: "red"}} />
                                </>
                            ) : null
                        }
                    >
                        <Typography>
                            {`${val.toFixed(2)}`}{" "}
                            {maxRank / minRank >= 3 || record.currentSalesRank === -1 ? (
                                <Tooltip placement="topLeft" title="Check product's SalesRank!">
                                    <WarningFilled style={{color: "darkred"}} />
                                </Tooltip>
                            ) : null}
                        </Typography>
                    </Popover>
                );
            },
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (
                    (record.reviewMultiplier < 1 && record.variationChecked) ||
                    record.variationReviewsTotal > 50 ||
                    record.parentASIN === record.ASIN ||
                    record.parentASIN.length === 0
                ) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                } else if (record.reviewMultiplier > 1 || (record.variationReviewsTotal >= 0 && record.variationReviewsTotal < 50)) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                }

                if (record.activeOffers === 0) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.BLUE;
                }

                if (record.Issues.includes("Check ParentRevs")) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                return {style};
            },
        },
        {
            title: "RepEstSales",
            key: "replenEstSales",
            dataIndex: "replenEstSales",
            width: "120px",
            ...getNumberFilterSorter("replenEstSales"),
            render: (val, record) => (
                <Popover
                    id="replen-popover"
                    autoAdjustOverflow={false}
                    content={<ReplenSalesPopover record={record} handleSave={updateTableData}></ReplenSalesPopover>}
                    trigger="click"
                >
                    <Typography>{`${val}`}</Typography>
                </Popover>
            ),
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (record.DIS > 15) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                } else if (record.DIS > 7) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                } else {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                return {style};
            },
        },
        {
            title: "CalcRank",
            key: "calcRank",
            dataIndex: "calcRank",
            width: "85px",
            ...getNumberFilterSorter("calcRank"),
            ...getNumberRenderer(),
        },
        {
            title: "HighRank",
            key: "salesRankHighest",
            dataIndex: "salesRankHighest",
            width: "85px",
            ...getNumberFilterSorter("salesRankHighest"),
            ...getNumberRenderer(),
        },
        {
            title: "LowRank",
            key: "salesRankLowest",
            dataIndex: "salesRankLowest",
            width: "85px",
            ...getNumberFilterSorter("salesRankLowest"),
            ...getNumberRenderer(),
        },
        {
            title: "SRDrops",
            key: "salesRankDrops",
            dataIndex: "salesRankDrops",
            width: "85px",
            ...getNumberFilterSorter("salesRankDrops"),
            ...getNumberRenderer(2),
        },
        {
            title: "Offers",
            key: "offerCount",
            dataIndex: "offerCount",
            width: "85px",
            ...getNumberFilterSorter("offerCount"),
            ...getNumberRenderer(),
        },
        {
            title: "Category",
            key: "rootCategory",
            dataIndex: "rootCategory",
            width: "120px",
            ...GetExpandedFilter(Object.keys(GetCategoryEstSalesData().mainSalesRankCorrelation), "rootCategory"),
        },
        {
            title: "SR Ref",
            key: "salesRankReference",
            dataIndex: "salesRankReference",
            width: "120px",
            ...GetExpandedFilter(Object.keys(GetCategoryEstSalesData().mainSalesRankCorrelation), "salesRankReference"),
        },
        {
            title: "BBOwner",
            key: "bbOwner",
            dataIndex: "bbOwner",
            width: "90px",
            ...GetExpandedFilter(["Amazon", "FBA", "FBM", "None", "Unknown"], "bbOwner"),
        },
        {
            title: "BBSeller",
            key: "bbOwnerId",
            dataIndex: "bbOwnerId",
            width: "90px",
            ...GetExpandedFilter([], "bbOwnerId"),
        },
        {
            title: "BB OOS",
            key: "bbOOS",
            dataIndex: "bbOOS",
            width: "80px",
            ...getNumberFilterSorter("bbOOS"),
            ...getShareRenderer(),
        },
        {
            title: "AZ OOS",
            key: "azOOS",
            dataIndex: "azOOS",
            width: "80px",
            ...getNumberFilterSorter("azOOS"),
            ...getShareRenderer(),
        },
        {
            title: "BBStock",
            key: "bbStock",
            dataIndex: "bbStock",
            width: "85px",
            ...getNumberFilterSorter("bbStock"),
            ...getNumberRenderer(),
        },
        {
            title: "AZStock",
            key: "azStock",
            dataIndex: "azStock",
            width: "85px",
            ...getNumberFilterSorter("azStock"),
            ...getNumberRenderer(),
        },
        {
            title: "AZTTS",
            key: "azTTS",
            dataIndex: "azTTS",
            width: "85px",
            ...getNumberFilterSorter("azTTS"),
            ...getNumberRenderer(),
        },
        {
            title: "ToBuy",
            key: "SuggestedQTY",
            dataIndex: "SuggestedQTY",
            width: "80px",
            ...getNumberFilterSorter("SuggestedQTY"),
            ...getNumberRenderer(),
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        if (Array.from((event.target as HTMLElement).classList.values()).includes("ant-table-cell")) {
                            updateTableData({...record, BuyQTY: Math.min(record.SuggestedQTY, record.onHand)});
                            message.success("Updated BuyQTY to ToBuy!", 1);
                        }
                    },
                };
            },
        },
        {
            title: "RepToBuy",
            key: "replenToBuy",
            dataIndex: "replenToBuy",
            width: "110px",
            ...getNumberFilterSorter("replenToBuy"),
            ...getNumberRenderer(),
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        if (Array.from((event.target as HTMLElement).classList.values()).includes("ant-table-cell")) {
                            updateTableData({...record, BuyQTY: Math.min(record.replenToBuy, record.onHand)});
                            message.success("Updated BuyQTY to RepToBuy!", 1);
                        }
                    },
                };
            },
        },
        {
            title: "wsTTS",
            key: "wsTTS",
            dataIndex: "wsTTS",
            width: "80px",
            ...getNumberFilterSorter("wsTTS"),
        },
        {
            title: "wsVelTTS",
            key: "wsVelTTS",
            dataIndex: "wsVelTTS",
            width: "100px",
            ...getNumberFilterSorter("wsVelTTS"),
        },
        {
            title: "wsMinTTS",
            key: "wsMinTTS",
            dataIndex: "wsMinTTS",
            width: "100px",
            ...getNumberFilterSorter("wsMinTTS"),
        },
        {
            title: "wsGMROI",
            key: "wsGMROI",
            dataIndex: "wsGMROI",
            width: "95px",
            ...getNumberFilterSorter("wsGMROI"),
            render: (value, record, index) => {
                return (
                    <Popover
                        id="gmroi-popover"
                        autoAdjustOverflow={false}
                        title={
                            <Typography style={{width: "100%", textAlign: "center", fontSize: "14px"}}>
                                GMROI: Gross Margin Return on Investment, or how much yearly profit you make for every dollar invested in
                                inventory.
                            </Typography>
                        }
                        content={<GMROIPopover record={record}></GMROIPopover>}
                        trigger="click"
                    >
                        <Typography>
                            {value}{" "}
                            {record.wsTTS + record.prepDelay <= record.netTerm ? (
                                <WarningFilled style={{fontSize: "10px", color: "red"}} />
                            ) : (
                                <InfoCircleFilled style={{fontSize: "10px", color: "white"}} />
                            )}
                        </Typography>
                    </Popover>
                );
            },
        },
        {
            title: "VarCheck",
            key: "variationChecked",
            dataIndex: "variationChecked",
            width: "110px",
            render: (val) => (val ? "True" : "False"),
            ...getBooleanFilter("variationChecked"),
        },
        {
            title: "RestrCheck",
            key: "isRestrictedChecked",
            dataIndex: "isRestrictedChecked",
            width: "90px",
            render: (val) => (val ? "True" : "False"),
            ...getBooleanFilter("isRestrictedChecked"),
        },
        {
            title: "HazmCheck",
            key: "isHazmatChecked",
            dataIndex: "isHazmatChecked",
            width: "90px",
            render: (val, record) => (record.isHazmatChecked ? (record.isHazmat ? record.isHazmatReason : "Not Hazmat") : "False"),
            ...getBooleanFilter("isHazmatChecked"),
        },
        {
            title: "StockCheck",
            key: "stockChecked",
            dataIndex: "stockChecked",
            width: "110px",
            render: (val) => (val ? "True" : "False"),
            ...getBooleanFilter("stockChecked"),
        },
        {
            title: "VarRevs",
            key: "variationReviews",
            dataIndex: "variationReviews",
            width: "90px",
            ...getNumberFilterSorter("variationReviews"),
        },
        {
            title: "ReviewMult",
            key: "reviewMultiplier",
            dataIndex: "reviewMultiplier",
            width: "90px",
            ...getNumberFilterSorter("reviewMultiplier"),
        },
        {
            title: "VarRevsKeepa",
            key: "variationReviewsKeepa",
            dataIndex: "variationReviewsKeepa",
            width: "90px",
            ...getNumberFilterSorter("variationReviewsKeepa"),
        },
        {
            title: "BuyQTY",
            key: "BuyQTY",
            dataIndex: "BuyQTY",
            width: "90px",
            ...getNumberFilterSorter("BuyQTY"),
            // render: (val, record) => {
            //     if (val >= 5 || val * record.sellPrice >= 200) {
            //         return <Popover
            //             id="buyqty-popover"
            //             content={"This item requires further review"}
            //             trigger="hover"
            //             title={null}
            //         >
            //             <Space direction="horizontal" align="center">
            //                 <Typography>
            //                     {val}
            //                 </Typography>
            //                 <InfoCircleFilled style={{color: WHOLESALE_CELL_COLORS.RED}} />
            //             </Space>
            //         </Popover>
            //     } else {
            //         return val
            //     }
            // },
        },
        {
            title: "StorageFee",
            key: "storageFee",
            dataIndex: "storageFee",
            width: "110px",
            ...getNumberFilterSorter("storageFee"),
            ...getPriceRenderer(),
        },
        {
            title: "ProfitStFee",
            key: "ProfitStFee",
            dataIndex: "ProfitStFee",
            width: "110px",
            ...getNumberFilterSorter("ProfitStFee"),
            ...getNumberRenderer(2),
        },
        {
            title: () => (
                <Tooltip placement="top" title={"Maximum Viable Cost"}>
                    MVC
                </Tooltip>
            ),
            dataIndex: "MaximumViableCost",
            key: "MaximumViableCost",
            width: "75px",
            ...getNumberFilterSorter("MaximumViableCost"),
            ...getPriceRenderer(),
        },
        {
            title: "DiscNeeded",
            dataIndex: "DiscountNeeded",
            key: "DiscountNeeded",
            width: "105px",
            ...getNumberFilterSorter("DiscountNeeded"),
            ...getShareRenderer(),
        },
        {
            title: () => (
                <Tooltip placement="top" title={"Minimum Viable Sell Price"}>
                    MVSP
                </Tooltip>
            ),
            dataIndex: "MinimumViableSellPrice",
            key: "MinimumViableSellPrice",
            width: "75px",
            ...getNumberFilterSorter("MinimumViableSellPrice"),
            ...getPriceRenderer(),
        },
        {
            title: "Breakeven",
            dataIndex: "BreakevenSellPrice",
            key: "BreakevenSellPrice",
            width: "105px",
            ...getNumberFilterSorter("BreakevenSellPrice"),
            ...getPriceRenderer(),
        },
        {
            title: "OH",
            dataIndex: "onHand",
            key: "onHand",
            width: "65px",
            ...getNumberFilterSorter("onHand"),
            ...getNumberRenderer(),
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        if (Array.from((event.target as HTMLElement).classList.values()).includes("ant-table-cell")) {
                            updateTableData({...record, BuyQTY: record.onHand});
                            message.success("Updated BuyQTY to OH!", 1);
                        }
                    },
                };
            },
            // shouldCellUpdate: (record, prevRecord) => false,
        },
        {
            title: "Inv",
            dataIndex: "InvQuantity",
            key: "InvQuantity",
            width: "65px",
            ...getNumberFilterSorter("InvQuantity"),
            render: (val, record) => (
                <Popover
                    id="inventory-popover"
                    autoAdjustOverflow={false}
                    content={<InventoryPopover record={record}></InventoryPopover>}
                    trigger="hover"
                >
                    <Typography>{`${val}`}</Typography>
                </Popover>
            ),
        },
        {
            title: "TotalInv",
            dataIndex: "InventoryTotal",
            key: "InventoryTotal",
            width: "85px",
            ...getNumberFilterSorter("InventoryTotal"),
            render: (val, record) => (
                <Popover
                    id="inventory-popover"
                    autoAdjustOverflow={false}
                    content={<InventoryPopover record={record}></InventoryPopover>}
                    trigger="hover"
                >
                    <Typography>{`${val}`}</Typography>
                </Popover>
            ),
        },
        {
            title: "ShipLater",
            dataIndex: "ShipLater",
            key: "ShipLater",
            width: "110px",
            ...getNumberFilterSorter("ShipLater"),
            ...getNumberRenderer(),
        },
        {
            title: "Discrepancy",
            dataIndex: "Discrepancy",
            key: "Discrepancy",
            width: "110px",
            ...getNumberFilterSorter("Discrepancy"),
            ...getNumberRenderer(),
        },
        {
            title: "Inc30D",
            dataIndex: "IncomingQuantity30D",
            key: "IncomingQuantity30D",
            width: "90px",
            ...getNumberFilterSorter("IncomingQuantity30D"),
            ...getNumberRenderer(),
        },
        {
            title: "IncAll",
            dataIndex: "IncomingQuantity",
            key: "IncomingQuantity",
            width: "110px",
            ...getNumberFilterSorter("IncomingQuantity"),
            ...getNumberRenderer(),
        },
        {
            title: "FBA Avail",
            dataIndex: "InvAvailableQuantity",
            key: "InvAvailableQuantity",
            width: "85px",
            ...getNumberFilterSorter("InvAvailableQuantity"),
            ...getNumberRenderer(),
        },
        {
            title: "OMS",
            dataIndex: "OMSItems",
            key: "OMSItems",
            width: "375px",
            // render: (val) => val ? 'True' : 'False',
            ...GetExpandedFilter([], "OMSItems", (record) =>
                record.OMSItems.map((item: OMSItem) => `${item.Supplier_SO},${item.Supplier_PO}`).join(",")
            ),
            render: (value: OMSItem[]) => (
                <List
                    // header={<div style={{ textAlign: 'center' }}>{`Exists in ${record.POs?.length || 0} POs`}</div>}
                    bordered
                    size="small"
                    dataSource={[...value].sort((a, b) => dayjs(a.ShipDate).toDate().getTime() - dayjs(b.ShipDate).toDate().getTime())}
                    style={{overflow: "auto", maxHeight: "220px"}}
                    renderItem={(item: OMSItem) => {
                        const maxCost = value
                            .map((item) => item.Cost)
                            .reduce((acc: number, curr) => (curr !== null && curr > acc ? curr : acc), 0);
                        const minCost = value
                            .map((item) => item.Cost)
                            .reduce((acc: number, curr) => (curr !== null && curr < acc ? curr : acc), Number.POSITIVE_INFINITY);
                        const tintFactor = maxCost !== minCost ? (item.Cost! - maxCost!) / (minCost! - maxCost!) : 1;

                        return (
                            <List.Item style={{justifyContent: "center", padding: "8px 0px"}}>
                                <Row style={{width: "100%", textAlign: "center"}}>
                                    <Col span={4}>
                                        <Tag
                                            color={`rgb(${199 * (1 - tintFactor)}, ${15 * (1 - tintFactor)}, ${15 * (1 - tintFactor)}, 1)`}
                                            style={{fontSize: "12px", marginRight: "0", padding: "0 4px"}}
                                        >
                                            ${item.Cost!.toFixed(2)}
                                        </Tag>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text style={{fontSize: "12px"}}>{item.Supplier_SO || item.Supplier_PO}</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text style={{fontSize: "12px"}}>{item.ShipDate}</Typography.Text>
                                    </Col>
                                    <Col span={3}>
                                        <Typography.Text style={{fontSize: "12px"}}>{item.Quantity}</Typography.Text>
                                    </Col>
                                    <Col span={2}>
                                        <Typography.Text style={{fontSize: "12px"}}>{item.ShipDateType}</Typography.Text>
                                    </Col>
                                    <Col span={3}>
                                        <Typography.Text style={{fontSize: "12px"}}>{item.IsPaid ? "Paid" : ""}</Typography.Text>
                                    </Col>
                                </Row>
                            </List.Item>
                        );
                    }}
                />
            ),
        },
        {
            title: "Orders7D",
            key: "Orders7D",
            dataIndex: "Orders7D",
            width: "110px",
            ...getNumberFilterSorter("Orders7D"),
            ...getNumberRenderer(),
        },
        {
            title: "Orders30D",
            key: "Orders30D",
            dataIndex: "Orders30D",
            width: "110px",
            ...getNumberFilterSorter("Orders30D"),
            render: (val, record) => (
                <Popover
                    id="orders30d-popover"
                    content={"Click here to open SellerCentral with last 90 days of orders"}
                    trigger="hover"
                    title={null}
                >
                    <Space direction="horizontal" align="center">
                        <Typography>{val}</Typography>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://sellercentral.amazon.com/orders-v3/search?page=1&q=${record.ASIN}&qt=asin&date-range=last-90`}
                        >
                            <InfoCircleFilled style={{color: WHOLESALE_CELL_COLORS.GREEN}} />
                        </a>
                    </Space>
                </Popover>
            ),
        },
        {
            title: "OrdersAll",
            key: "OrdersAll",
            dataIndex: "OrdersAll",
            width: "110px",
            ...getNumberFilterSorter("OrdersAll"),
            ...getNumberRenderer(),
        },
        {
            title: "OrdersOur7D",
            key: "OrdersOur7D",
            dataIndex: "OrdersOur7D",
            width: "120px",
            ...getNumberFilterSorter("OrdersOur7D"),
            ...getNumberRenderer(),
        },
        {
            title: "OrdersOur14D",
            key: "OrdersOur14D",
            dataIndex: "OrdersOur14D",
            width: "120px",
            ...getNumberFilterSorter("OrdersOur14D"),
            ...getNumberRenderer(),
        },
        {
            title: "OrdersOur30D",
            key: "OrdersOur30D",
            dataIndex: "OrdersOur30D",
            width: "120px",
            ...getNumberFilterSorter("OrdersOur30D"),
            ...getNumberRenderer(),
        },
        {
            title: "OrdersOur60D",
            key: "OrdersOur60D",
            dataIndex: "OrdersOur60D",
            width: "120px",
            ...getNumberFilterSorter("OrdersOur60D"),
            ...getNumberRenderer(),
        },
        {
            title: "OrdersOur90D",
            key: "OrdersOur90D",
            dataIndex: "OrdersOur90D",
            width: "120px",
            ...getNumberFilterSorter("OrdersOur90D"),
            ...getNumberRenderer(),
        },
        {
            title: "OrdersOur180D",
            key: "OrdersOur180D",
            dataIndex: "OrdersOur180D",
            width: "125px",
            ...getNumberFilterSorter("OrdersOur180D"),
            ...getNumberRenderer(),
        },
        {
            title: "OrdersOur365D",
            key: "OrdersOur365D",
            dataIndex: "OrdersOur365D",
            width: "125px",
            ...getNumberFilterSorter("OrdersOur365D"),
            ...getNumberRenderer(),
        },
        {
            title: "Velocity",
            key: "Velocity",
            dataIndex: "Velocity",
            width: "110px",
            ...getNumberFilterSorter("Velocity"),
            ...getNumberRenderer(),
        },
        {
            title: "BBShare",
            key: "BBShare",
            dataIndex: "BBShare",
            width: "110px",
            ...getNumberFilterSorter("BBShare"),
            render: (val) => <Typography>{val >= 0 ? `${val}%` : val ? val : "?"}</Typography>,
        },
        {
            title: () => (
                <Tooltip placement="top" title={"Days in Stock (SS) - Green for 15+, Yellow for 7+, Red otherwise"}>
                    DIS
                </Tooltip>
            ),
            key: "DIS",
            dataIndex: "DIS",
            width: "75px",
            ...getNumberFilterSorter("DIS"),
            ...getNumberRenderer(),
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (record.DIS > 15) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                } else if (record.DIS > 7) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                } else {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                return {style};
            },
        },
        {
            title: () => (
                <Tooltip placement="top" title={"Average Sell Price (SS)"}>
                    AvgSell
                </Tooltip>
            ),
            dataIndex: "AvgSellPrice",
            key: "AvgSellPrice",
            width: "100px",
            ...getNumberFilterSorter("AvgSellPrice"),
            ...getPriceRenderer(),
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (record.DIS > 15) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                } else if (record.DIS > 7) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                } else {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.RED;
                }

                return {style};
            },
        },
        {
            title: "SS_Min",
            dataIndex: "SellerSnapMinPrice",
            key: "SellerSnapMinPrice",
            width: "90px",
            ...getNumberFilterSorter("SellerSnapMinPrice"),
            ...getPriceRenderer(),
        },

        {
            title: "SS_Max",
            dataIndex: "SellerSnapMaxPrice",
            key: "SellerSnapMaxPrice",
            width: "90px",
            ...getNumberFilterSorter("SellerSnapMaxPrice"),
            ...getPriceRenderer(),
        },
        {
            title: "AskPrice",
            key: "AskPrice",
            dataIndex: "AskPrice",
            width: "95px",
            ...getNumberFilterSorter("AskPrice"),
            ...getPriceRenderer(),
        },
        {
            title: "MinPrice",
            key: "MinPrice",
            dataIndex: "MinPrice",
            width: "95px",
            ...getNumberFilterSorter("MinPrice"),
            ...getPriceRenderer(),
        },
        {
            title: "MaxPrice",
            key: "MaxPrice",
            dataIndex: "MaxPrice",
            width: "95px",
            ...getNumberFilterSorter("MaxPrice"),
            ...getPriceRenderer(),
        },
        {
            title: "Seasonal Tags",
            dataIndex: "Seasonal Tags",
            key: "Seasonal Tags",
            width: "110px",
            ...GetExpandedFilter([], "Seasonal Tags"),
            render: (tags) => (
                <Space direction="vertical" align="center" style={{width: "100%"}}>
                    {tags.length > 0
                        ? tags.split(";").map((tag: string, idx: number) => (
                              <Tag style={{marginRight: 0}} key={idx}>
                                  {tag.toUpperCase()}
                              </Tag>
                          ))
                        : "?"}
                </Space>
            ),
        },
        {
            title: "OrderAmt",
            key: "OrderAmount",
            dataIndex: "OrderAmount",
            width: "95px",
            ...getNumberFilterSorter("OrderAmount", 6),
            ...getPriceRenderer(),
        },
        {
            title: "PercOrderAmt",
            key: "FracOrderAmount",
            dataIndex: "FracOrderAmount",
            width: "115px",
            ...getNumberFilterSorter("FracOrderAmount"),
            ...getShareRenderer(2),
        },
        {
            title: "ParentOrderAmt",
            key: "ParentOrderAmount",
            dataIndex: "ParentOrderAmount",
            width: "135px",
            ...getNumberFilterSorter("ParentOrderAmount"),
            ...getPriceRenderer(),
        },
        {
            title: "ParentPercOrderAmt",
            key: "ParentFracOrdAmt",
            dataIndex: "ParentFracOrdAmt",
            width: "135px",
            ...getNumberFilterSorter("ParentFracOrdAmt", 9),
            ...getShareRenderer(2),
        },
        {
            title: "ParentStage01Spend",
            key: "parentSpendStage01",
            dataIndex: "parentSpendStage01",
            width: "135px",
            ...getNumberFilterSorter("parentSpendStage01"),
            ...getPriceRenderer(),
        },
        {
            title: "ParentStage01Perc",
            key: "parentOrderStage01Perc",
            dataIndex: "parentOrderStage01Perc",
            width: "135px",
            ...getNumberFilterSorter("parentOrderStage01Perc", 9),
            ...getShareRenderer(2),
        },
        {
            title: "Rating",
            key: "keepaRating",
            dataIndex: "keepaRating",
            width: "100px",
            ...getNumberFilterSorter("keepaRating"),
            ...getNumberRenderer(),
        },
        {
            title: "TrackedFor",
            key: "trackedFor",
            dataIndex: "trackedFor",
            width: "130px",
            ...getNumberFilterSorter("trackedFor"),
            ...getNumberRenderer(),
        },
        {
            title: "AdiStockAvail",
            key: "adidasStockAvail",
            dataIndex: "adidasStockAvail",
            width: "120px",
            ...getNumberFilterSorter("adidasStockAvail"),
            ...getShareRenderer(2),
        },
        {
            title: "Tags",
            dataIndex: "Tags",
            key: "Tags",
            width: "85px",
            ...GetExpandedFilter(
                SEASONAL_TAGS.map((el) => el as string),
                "Tags"
            ),
            render: (tags) => (
                <Space direction="vertical" align="center" style={{width: "100%"}}>
                    {tags.map((tag: string, idx: number) => (
                        <Tag style={{marginRight: 0}} key={idx}>
                            {tag.toUpperCase()}
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: "PartNumber",
            key: "Product Codes: PartNumber",
            dataIndex: "Product Codes: PartNumber",
            width: "100px",
            ...GetExpandedFilter([], "Product Codes: PartNumber"),
        },
        {
            title: "Model",
            key: "Model",
            dataIndex: "Model",
            width: "100px",
            ...GetExpandedFilter([], "Model"),
        },
        {
            title: "Color",
            key: "keepaColor",
            dataIndex: "keepaColor",
            width: "100px",
            ...GetExpandedFilter([], "keepaColor"),
            sorter: {
                compare: (a, b) => a.keepaColor!.localeCompare(b.keepaColor!),
                multiple: 3,
            },
        },
        {
            title: "Size",
            key: "keepaSize",
            dataIndex: "keepaSize",
            width: "100px",
            ...GetExpandedFilter([], "keepaSize"),
            sorter: {
                compare: (a, b) => {
                    if (!Number.isNaN(parseFloat(a.keepaSize)) && !Number.isNaN(parseFloat(b.keepaSize))) {
                        return parseFloat(a.keepaSize) - parseFloat(b.keepaSize);
                    } else {
                        return a.keepaSize!.localeCompare(b.keepaSize!);
                    }
                },
                multiple: 2,
            },
        },
        {
            title: "PackSize",
            dataIndex: "packSize",
            key: "packSize",
            width: "95px",
            ...getNumberFilterSorter("packSize"),
            ...getNumberRenderer(),
        },
        {
            title: "IsBundle",
            key: "isBundle",
            dataIndex: "isBundle",
            width: "85px",
            render: (val) => (val ? "True" : "False"),
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        if (Array.from((event.target as HTMLElement).classList.values()).includes("ant-table-cell")) {
                            updateTableData({...record, isBundle: !record.isBundle});
                            message.success(`Updated record's isBundle!`, 1);
                        }
                    },
                };
            },
            ...getBooleanFilter("variationChecked"),
        },
        {
            title: "Issues",
            key: "Issues",
            dataIndex: "Issues",
            width: "85px",
            ...GetExpandedFilter(WS_POSSIBLE_ISSUES, "Issues"),
            render: (issues) => (
                <Space direction="vertical" align="center" style={{width: "100%"}}>
                    {issues.map((issue: string, idx: number) => (
                        <Tag style={{marginRight: 0}} color={getIssueColor(issue)} key={idx}>
                            {issue.toUpperCase()}
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: "Stages",
            key: "Stages",
            dataIndex: "Stages",
            width: "85px",
            ...GetExpandedFilter(POSSIBLE_STAGES_WS, "Stages"),
            render: (stages) => (
                <Space direction="vertical" align="center" style={{width: "100%"}}>
                    {stages.map((issue: string, idx: number) => (
                        <Tag style={{marginRight: 0}} color={getIssueColor(issue)} key={idx}>
                            {issue.toUpperCase()}
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: "Keepa",
            key: "keepaGraph",
            dataIndex: "keepaGraph",
            width: "325px",
            render: (text: string, record: WholesaleDataType) => {
                return (
                    <a target="_blank" rel="noopener noreferrer" href={`https://keepa.com/#!product/1-${record.ASIN}`}>
                        <img
                            src={`https://graph.keepa.com/pricehistory.png?domain=com&bb=1&salesrank=1&asin=${record.ASIN}&range=365`}
                            height={125}
                            width={325}
                            alt="Keepa graph"
                        />
                    </a>
                );
            },
            onCell: (record) => {
                const style = {backgroundColor: "transparent"};

                if (dayjs().diff(dayjs(record["Tracking since"]), "day") < 60) {
                    style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                }

                return {style};
            },
        },
        {
            title: "Comments",
            key: "comments",
            dataIndex: "comments",
            width: "225px",
            ...GetExpandedFilter([], "comments", (record) =>
                record.comments
                    .filter((row: any) => typeof row === "object")
                    .map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`)
                    .join(",")
            ),
            render: (value, record) => (
                <NotesRenderer
                    editable={true}
                    notes={value}
                    onFinish={(notes) => {
                        const newRecord = {
                            ...record,
                            comments: notes,
                        };

                        updateTableData(newRecord);
                    }}
                />
            ),
        },
        {
            title: "Opportunities",
            key: "opportunities",
            dataIndex: "opportunities",
            width: "100px",
            ...GetExpandedFilter(BRAND_OPPORTUNITIES, "opportunities"),
            render: (opportunities) => (
                <Space direction="vertical" align="center" style={{width: "100%"}}>
                    {opportunities?.length > 0
                        ? opportunities.split(";").map((tag: string, idx: number) => (
                              <Tag style={{marginRight: 0}} key={idx}>
                                  {tag.toUpperCase()}
                              </Tag>
                          ))
                        : "?"}
                </Space>
            ),
        },
        {
            title: "Notes",
            key: "Notes",
            dataIndex: "Notes",
            width: "225px",
            ...GetExpandedFilter([], "Notes", (record) =>
                record.Notes.map((row: ExpandedNote) => `${row.noteAuthor}-${row.noteText}`).join(",")
            ),
            render: (value, record) => (
                <StrictNotesRenderer
                    editable={true}
                    notes={value}
                    onFinish={(newNotes) => {
                        const newGlobalNotes: GlobalNoteItem[] = newNotes.map((note) => ({
                            ASIN: record.ASIN,
                            ParentASIN: record.parentASIN,
                            isParentNote: false,
                            ...note,
                        }));

                        const globalNotesToSave = newGlobalNotes.filter((globalNote) => globalNote.edited);

                        // store the notes in the database
                        if (globalNotesToSave.length > 0) {
                            postGlobalNote(
                                "",
                                globalNotesToSave.map((globalNote) => ({...globalNote, edited: false}))
                            )
                                .then((res) => {
                                    if (res.error === null) {
                                        message.success("Notes saved!");

                                        if (res.writeResult.upserted?.length > 0) {
                                            for (const upsertInfo of res.writeResult.upserted) {
                                                globalNotesToSave[upsertInfo.index]._id = upsertInfo._id;
                                            }
                                        }

                                        const newRecord = {
                                            ...record,
                                            Notes: newGlobalNotes.map((globalNote) => ({...globalNote, edited: false})),
                                        };

                                        updateTableData(newRecord);
                                    } else {
                                        notification.error({
                                            message: `Failed to save notes for ASIN ${record.ASIN}`,
                                            description: res.error,
                                        });
                                    }
                                })
                                .catch((ex) => {
                                    notification.error({
                                        message: `Failed to save notes for ASIN ${record.ASIN}`,
                                        description: ex.toString(),
                                    });
                                });
                        }
                    }}
                />
            ),
        },
        {
            title: "Save",
            dataIndex: "saved",
            key: "saved",
            fixed: "right",
            width: "70px",
            filters: [
                {
                    text: "True",
                    value: true,
                },
                {
                    text: "False",
                    value: false,
                },
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.saved === value || record.recentlyEdited,
            sorter: {
                compare: (a, b) => +a.saved! - +b.saved!,
                multiple: 99,
            },
            render: (text: string, record: WholesaleDataType) => (
                <Space direction="vertical" size="middle" align="center" style={{width: "100%"}}>
                    {record.saved ? (
                        <>
                            <Tag style={{marginRight: 0, background: WHOLESALE_CELL_COLORS.GREEN}}>Saved</Tag>
                            <Button
                                onClick={() => {
                                    saveProduct({...record, saved: false})
                                        .then((res) => res.json())
                                        .then((res) => {
                                            if (res.error === null && res.mongoOk && res.updatedCount === 1) {
                                                message.success("Unsaved!");
                                                updateTableData({...record, saved: false});
                                            } else {
                                                notification.error({
                                                    message: `Failed to unsave ASIN ${record.ASIN}`,
                                                    description: res.error,
                                                });
                                            }
                                        })
                                        .catch((ex) => {
                                            notification.error({
                                                message: `Failed to unsave ASIN ${record.ASIN}`,
                                                description: ex.toString(),
                                            });
                                        });
                                }}
                            >
                                Unsave
                            </Button>
                        </>
                    ) : null}
                    <Button
                        onClick={() => {
                            const prodToSave = {...record, saved: true};
                            confirmSave(modal, prodToSave, () =>
                                saveProduct(prodToSave)
                                    .then((res) => res.json())
                                    .then((res) => {
                                        if (res.error === null && res.mongoOk) {
                                            message.success("Saved!");
                                            updateTableData(prodToSave);
                                        } else {
                                            notification.error({message: `Failed to save ASIN ${record.ASIN}`, description: res.error});
                                        }
                                    })
                                    .catch((ex) => {
                                        notification.error({message: `Failed to save ASIN ${prodToSave.ASIN}`, description: ex.toString()});
                                    })
                            );
                        }}
                    >
                        {record.saved ? "Update" : "Save"}
                    </Button>
                </Space>
            ),
        },
    ];
};
