import React from "react";
import {Button, Space} from "antd";
import {Dimensions, InboundPallet} from "../../../../types/WarehouseTypes";
import {PlusOutlined} from "@ant-design/icons";
import {useStore} from "../../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import BoxView from "../../common/BoxView";

interface PalletViewProps {
    pallet: InboundPallet;
}

const PalletView: React.FC<PalletViewProps> = ({pallet}) => {
    const {boxesStore, boxingActions} = useStore(
        useShallow((state) => ({
            boxesStore: state.boxes,
            boxingActions: {
                addBox: state.addBox,
                removeBox: state.removeBox,
                setActiveBox: state.setActiveBox,
                duplicateBox: state.duplicateBox,
                addBoxDimensions: state.addBoxDimensions,
                changeItemQuantity: state.changeItemQuantity,
                removeItem: state.removeItem,
            },
        }))
    );

    const handleBoxSelect = (boxId: string) => {
        boxingActions.setActiveBox(pallet.id, boxId);
    };

    const handleBoxRemove = (boxId: string) => {
        boxingActions.removeBox(boxId);
    };

    const handleBoxDuplicate = (boxId: string) => {
        boxingActions.duplicateBox(boxId);
    };

    const handleDimensionsUpdate = (boxId: string, dimensions: Dimensions) => {
        boxingActions.addBoxDimensions(boxId, dimensions);
    };

    const handleItemQuantityChange = (sku: string, boxId: string, quantity: number) => {
        boxingActions.changeItemQuantity(sku, boxId, quantity);
    };

    const handleItemRemove = (sku: string, boxId: string) => {
        boxingActions.removeItem(sku, boxId);
    };

    return (
        <Space direction="vertical" style={{height: "35vh", overflow: "auto", width: "100%"}}>
            <Space direction="horizontal" wrap>
                {pallet.boxesIds.map((boxId) => (
                    <BoxView
                        key={boxId}
                        box={boxesStore.byId[boxId]}
                        isActive={pallet.activeBox === boxId}
                        onSelect={handleBoxSelect}
                        onRemove={handleBoxRemove}
                        onDuplicate={handleBoxDuplicate}
                        onDimensionsUpdate={handleDimensionsUpdate}
                        onItemQuantityChange={handleItemQuantityChange}
                        onItemRemove={handleItemRemove}
                    />
                ))}
                <Button
                    style={{width: 50, height: 50}}
                    icon={<PlusOutlined style={{fontSize: 40}} />}
                    onClick={() => boxingActions.addBox(pallet.id)}
                />
            </Space>
        </Space>
    );
};

export default PalletView;
