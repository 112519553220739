import {create} from "zustand";
import {AppState} from "../types/storeTypes";
import {createPackLaterBoxingDataSlice} from "./warehouse/pack-later/boxingDataSlice";
import {createBoxingActionsSlice} from "./warehouse/pack-later/boxingActionsSlice";
import {createUserDataSlice} from "./userDataSlice";
import {immer} from "zustand/middleware/immer";
import {createPrintSlice} from "./warehouse/printSlice";
import {createReturnsSlice} from "./warehouse/returnsSlice";
import {createPackLaterReceivingSlice} from "./warehouse/pack-later/receivingSlice";
import {createPackFirstReceivingSlice} from "./warehouse/pack-first/receivingSlice";
import {createPackFirstBoxingDataSlice} from "./warehouse/pack-first/boxingDataSlice";

export const useStore = create<AppState>()(
    immer((...a) => ({
        ...createPackLaterBoxingDataSlice(...a),
        ...createBoxingActionsSlice(...a),
        ...createUserDataSlice(...a),
        ...createPrintSlice(...a),
        ...createReturnsSlice(...a),
        ...createPackLaterReceivingSlice(...a),
        ...createPackFirstReceivingSlice(...a),
        ...createPackFirstBoxingDataSlice(...a),
    }))
);
