import React from "react";
import {Input, Button, Table, Spin, Space, notification, Tooltip} from "antd";
import {useStore} from "../../../store/useStore";
import {CheckCircleFilled, EditOutlined, ReloadOutlined} from "@ant-design/icons";
import ExtractedItemsDialog from "./ExtractedItemsDialog";
import {ReturnProduct} from "../../../types/WarehouseTypes";
import {ColumnsType} from "antd/lib/table";
import {useShallow} from "zustand/react/shallow";
import UploadPhotosDialog from "./UploadPhotosDialog";
import {uploadPhotos} from "../../../services/WarehouseService";
import EBayFormDialog from "./EBayFormDialog";

const HandleReturns: React.FC = () => {
    const {
        returnsData,
        setCode,
        getExpectedProducts,
        resetReturnsData,
        resetEBayData,
        scanReturnedProduct,
        setExtractedItems,
        saveReturnedProduct,
        processWithoutLPN,
        setIsScanning,
        setSelectedItem,
        setEBayItem,
    } = useStore(
        useShallow((state) => ({
            returnsData: state.returnsData,
            setCode: state.setCode,
            getExpectedProducts: state.getExpectedProducts,
            resetReturnsData: state.resetReturnsData,
            resetEBayData: state.resetEBayData,
            scanReturnedProduct: state.scanReturnedProduct,
            setExtractedItems: state.setExtractedItems,
            saveReturnedProduct: state.saveReturnedProduct,
            processWithoutLPN: state.processWithoutLPN,
            setIsScanning: state.setIsScanning,
            setSelectedItem: state.setSelectedItem,
            setEBayItem: state.setEBayItem,
        }))
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    };

    const handleSubmit = () => {
        // If expected products is empty, we are scanning a tracking number
        if (returnsData.code && returnsData.expectedProducts.length === 0) {
            getExpectedProducts(returnsData.code);
        } else if (returnsData.code) {
            scanReturnedProduct(returnsData.code);
        }
    };

    const handleExtractedItem = (item: ReturnProduct) => {
        if (item.action?.includes("fraud")) {
            setExtractedItems([]);
            setSelectedItem(item);
        } else if (item.action === "sell-on-ebay") {
            resetEBayData();
            setExtractedItems([]);
            setEBayItem({...item, eBayQuantity: item.quantity ? parseInt(item.quantity) : 1});
        } else {
            setExtractedItems([]);
            saveReturnedProduct(item);
        }
        setCode(undefined);
    };

    const handleReset = () => {
        resetReturnsData();
    };
    const columns: ColumnsType<ReturnProduct> = [
        {
            title: "Name",
            dataIndex: "product-name",
            key: "product-name",
        },
        {
            title: "ASIN",
            width: 150,
            dataIndex: "asin",
            key: "asin",
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku",
        },
        {
            title: "Quantity",
            width: 70,
            dataIndex: "shipped-quantity",
            key: "shipped-quantity",
        },
        {
            title: "Status",
            width: 70,
            render: (product, _, index) => {
                return (
                    <>
                        {product.finished ? (
                            <CheckCircleFilled
                                style={{fontSize: 24, color: "green"}}
                                onClick={() => processWithoutLPN(product.sku, index)}
                            />
                        ) : (
                            <Tooltip title="Process manually" placement="left">
                                <Button shape="circle" icon={<EditOutlined />} onClick={() => processWithoutLPN(product.sku, index)} />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <Space style={{width: "100%", justifyContent: "center", marginBottom: 8}}>
                <Input
                    placeholder={returnsData.expectedProducts.length > 0 ? "Scanning..." : "Enter tracking number"}
                    value={returnsData.code}
                    onChange={handleInputChange}
                    onPressEnter={handleSubmit}
                    style={{width: 300}}
                />
                <Button onClick={handleSubmit} type="primary">
                    Scan
                </Button>
                <Button onClick={handleReset} icon={<ReloadOutlined />} />
            </Space>

            {returnsData.isLoading ? (
                <Space style={{width: "100%", justifyContent: "center", marginTop: 8}}>
                    <Spin size="large" />
                </Space>
            ) : (
                <Spin spinning={returnsData.isScanning}>
                    <Table
                        dataSource={returnsData.expectedProducts}
                        columns={columns}
                        rowKey="asin"
                        pagination={false}
                        // style={{maxHeight: "80vh", overflow: "auto"}}
                        scroll={{y: "60vh"}}
                    />
                </Spin>
            )}
            <ExtractedItemsDialog
                items={returnsData.extractedItems}
                open={returnsData.extractedItems.length > 0}
                onClose={() => {
                    setExtractedItems([]);
                    setCode(undefined);
                }}
                onOk={handleExtractedItem}
            />
            <UploadPhotosDialog
                open={!!returnsData.selectedItem}
                onClose={() => setSelectedItem(undefined)}
                onFinish={async (files) => {
                    if (!returnsData.selectedItem) {
                        notification.error({
                            message: "Something went wrong",
                        });
                        return;
                    }

                    try {
                        setIsScanning(true);
                        const uploadedUrls = await uploadPhotos(files);
                        saveReturnedProduct({...returnsData.selectedItem, photos: uploadedUrls});
                        setSelectedItem(undefined);
                    } catch (error: any) {
                        notification.error({
                            message: "Error uploading images",
                            description: error.message,
                        });
                    } finally {
                        setIsScanning(false);
                    }
                }}
            />
            <EBayFormDialog open={!!returnsData.eBayItem} onClose={() => setEBayItem(undefined)} />
        </div>
    );
};

export default HandleReturns;
