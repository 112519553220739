import React from "react";
import {Popover, Typography, Space} from "antd";
import {InboundOperationStatus} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {InfoCircleFilled} from "@ant-design/icons";

interface OperationStatusInfoProps {
    operationStatus?: InboundOperationStatus;
}

const OperationStatusInfo: React.FC<OperationStatusInfoProps> = ({operationStatus}) => {
    if (!operationStatus) {
        return (
            <Space style={{width: 150}}>
                <Typography.Text type="secondary">---</Typography.Text>
            </Space>
        );
    }

    const {operationStatus: status, operationProblems} = operationStatus;

    let content = <Typography.Text>{status}</Typography.Text>;

    if (operationProblems && operationProblems.length > 0) {
        content = (
            <Popover
                content={
                    <Space direction="vertical" style={{width: "60vw"}}>
                        {operationProblems.map((problem, index) => {
                            let type: "warning" | "danger" | undefined;
                            if (problem.severity === "ERROR") {
                                type = "danger";
                            } else if (problem.severity === "WARNING") {
                                type = "warning";
                            }
                            return (
                                <Typography.Text key={index} type={type}>
                                    <b>{problem.code}:</b> {problem.message}
                                </Typography.Text>
                            );
                        })}
                    </Space>
                }
                title="Operation Problems"
            >
                <Typography.Text>{status}</Typography.Text>
                <InfoCircleFilled style={{marginLeft: 8}} />
            </Popover>
        );
    }

    return <Space style={{width: 150}}>{content}</Space>;
};

export default OperationStatusInfo;
