import React from "react";
import {Card, Space, Typography, Empty, Descriptions, Divider, Button} from "antd";
import {PlacementOption} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import dayjs from "dayjs";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import PlacementOptionView from "./PlacementOptionView";
import {sumIncentives} from "./helpers";

const {Text} = Typography;

interface PlacementOptionsViewProps {
    placementOptions: PlacementOption[];
}

const PlacementOptionsList: React.FC<PlacementOptionsViewProps> = ({placementOptions}) => {
    const {actions, data} = useStore(useShallow((state) => ({actions: state.packFirstBoxingActions, data: state.packFirstBoxingData})));
    if (!placementOptions.length) {
        return <Empty description="No placement options available" />;
    }

    const selectedPlacementOptionIndex = placementOptions.findIndex(
        (option) => option.placementOptionId === data.selectedPlacementOptionId
    );

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Space direction="horizontal" style={{width: "100%", justifyContent: "center", position: "relative"}}>
                <Button
                    icon={<ArrowLeftOutlined />}
                    style={{position: "absolute", left: 0, top: 0}}
                    onClick={() => {
                        if (data.selectedPackingOptionId) {
                            actions.setPackingOptionViewMode(data.selectedPackingOptionId, "BOXING");
                        }
                    }}
                >
                    Back
                </Button>
                <Typography.Title style={{margin: 0}} level={5}>
                    Placement Options
                </Typography.Title>
            </Space>
            <Space direction="horizontal" style={{height: 200, overflowX: "auto", width: "100%"}}>
                {placementOptions.map((option, index) => (
                    <Card
                        key={option.placementOptionId}
                        style={{
                            cursor: "pointer",
                            borderColor: data.selectedPlacementOptionId === option.placementOptionId ? "#1677ff" : undefined,
                            width: 200,
                            margin: 8,
                        }}
                        styles={{body: {padding: 8}, title: {fontSize: 12}, header: {margin: 0}}}
                        onClick={() => actions.setSelectedPlacementOptionId(option.placementOptionId)}
                        title={`Option ${index + 1}`}
                    >
                        <Descriptions
                            column={1}
                            size="small"
                            // bordered
                            items={[
                                {
                                    key: "shipments",
                                    label: "Shipments",
                                    children: <Text>{option.shipmentIds.length}</Text>,
                                },
                                {
                                    key: "expires",
                                    label: "Expires",
                                    children: option.expiration
                                        ? dayjs(option.expiration).format("YYYY-MM-DD HH:mm:ss")
                                        : "No expiration date",
                                },
                                {
                                    key: "fees",
                                    label: "Fees",
                                    children: <Text type="danger">${sumIncentives(option.fees).toFixed(2)}</Text>,
                                },
                                {
                                    key: "discounts",
                                    label: "Discounts",
                                    children: <Text type="success">${sumIncentives(option.discounts).toFixed(2)}</Text>,
                                },
                            ]}
                        />
                    </Card>
                ))}
            </Space>
            <Divider style={{margin: 4}} />
            <PlacementOptionView placementOption={placementOptions[selectedPlacementOptionIndex]} index={selectedPlacementOptionIndex} />
        </Space>
    );
};

export default PlacementOptionsList;
