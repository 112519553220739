import {CopyOutlined, DownOutlined} from "@ant-design/icons";
import {Button, Dropdown, Input, InputNumber, Modal, notification, Select} from "antd";
// import {ItemType, MenuItemType} from "antd/es/menu/hooks/useItems";
import React, {useState} from "react";
import {OMSItemRenderType, SEASONAL_TAGS, VENDOR_TYPES} from "../../../types/OmegaTypes";
import {useSuppliers} from "../oms/dataHandlers";
import {DateTimePicker} from "./DateTimePicker";
import {ItemType, MenuItemType} from "antd/lib/menu/interface";

export type BulkActionType = Omit<MenuItemType, "key">;

interface BulkActionsButtonProps {
    actions?: BulkActionType[];
}

const defaultItemStyle: React.CSSProperties = {
    padding: 8,
};

/**
 * Renders the given list of actions in a dropdown.
 * @param param0
 * @returns
 */
export const BulkActionsButton: React.FC<BulkActionsButtonProps> = ({actions}) => {
    // Add keys and default style to items
    const newActions = actions?.map<ItemType>((action, index) => ({
        key: index,
        ...action,
        style: {...defaultItemStyle, ...action},
    }));

    return (
        <Dropdown
            menu={{items: newActions}}
            dropdownRender={(menu) => (
                <div>
                    {React.cloneElement(menu as React.ReactElement, {
                        style: {backgroundColor: "#323232"},
                    })}
                </div>
            )}
            placement="bottomRight"
        >
            <Button icon={<DownOutlined />}>Actions</Button>
        </Dropdown>
    );
};

/**
 * Creates an action to copy ASIN values to the clipboard.
 *
 * @param data Data to extract ASINs from.
 * @param keysToFilter Includes only items with keys from this list.
 * @returns
 */
export const makeCopyAsinsAction = <T extends {key: React.Key; ASIN?: string}>(
    data: T[],
    keysToFilter: React.Key[],
    separator: string = ","
): BulkActionType => {
    const copyAsins = () => {
        const selectedKeysSet = new Set(keysToFilter);
        let itemsToConsider = data;
        itemsToConsider = itemsToConsider.filter((item) => selectedKeysSet.has(item.key) && item.ASIN);
        const asins = itemsToConsider.map((item) => item.ASIN);

        navigator.clipboard.writeText(asins.join(separator));
        notification.success({
            message: `${asins.length} ASIN(s) copied!`,
        });
    };

    const action: BulkActionType = {
        label: "Copy ASINs",
        onClick: copyAsins,
        icon: <CopyOutlined />,
        disabled: data.length === 0,
    };

    return action;
};

/**
 * Creates an action to copy given column values to the clipboard.
 *
 * @param dataIndex Data index.
 * @param data Data to extract column values from.
 * @param keysToFilter Includes only items with keys from this list.
 * @returns
 */
export const makeCopyDataIndexAction = <T extends {key: React.Key; [dataIndex: string]: any}>(
    dataIndex: string,
    data: T[],
    keysToFilter: React.Key[],
    separator: string = ","
): BulkActionType => {
    const copyAsins = () => {
        const selectedKeysSet = new Set(keysToFilter);
        let itemsToConsider = data;
        itemsToConsider = itemsToConsider.filter((item) => selectedKeysSet.has(item.key) && item[dataIndex]);
        const asins = itemsToConsider.map((item) => item[dataIndex]);

        navigator.clipboard.writeText(asins.join(separator));
        notification.success({
            message: `${asins.length} ASIN(s) copied!`,
        });
    };

    const action: BulkActionType = {
        label: `Copy ${dataIndex}`,
        onClick: copyAsins,
        icon: <CopyOutlined />,
        disabled: data.length === 0,
    };

    return action;
};

/**
 * Specifies required parameters for bulk actions that are specifically
 * designed for certain columns. For example for bulk actions that modify only
 * the Supplier_Name column.
 */
interface BulkActionPropsSpecific<DataType extends {key: React.Key} = any> {
    onUpdate: (data: DataType[]) => void;
    onRowUpdated?: (row: DataType) => DataType;
}

/**
 * Specifies required parameters for bulk actions that are
 * designed to accept different columns of the same type. For example you might want
 * to create a bulk action to handle all numeric columns.
 */
interface BulkActionPropsGeneral<DataType extends {key: React.Key} = any> extends BulkActionPropsSpecific<DataType> {
    columnName: keyof DataType;
    title: string;
}

/**
 * Return type of the modal hook.
 */
export type BulkActionsModalHookType<T = BulkActionPropsGeneral> = [{show: (p: T) => void}, React.ReactElement];

/**
 * Specifies all parameters that are required to render a bulk action.
 *
 * @template InnerValueType Value stored inside the bulk action. Its type depends on the
 * children rendered by the render() function. For example if it's a numeric input, the InnerValueType
 * should be a number.
 * @template ReturnValueType Value returned by the bulk action that will eventually be
 * stored in the cell. For example some columns may store values as string with names separated
 * by commas. In this case the InnerValueType will be a list of strings, but the bulk action should
 * return the expected string of values.
 * @template DataType Type of data elements.
 */
interface BulkActionsRenderOptions<InnerValueType, ReturnValueType, DataType = OMSItemRenderType> {
    render: (value: InnerValueType, setValue: (value: InnerValueType) => void) => React.ReactElement;
    parseValue?: (value: InnerValueType) => ReturnValueType;
    defaultValue: InnerValueType;
    data: DataType[];
    selectedRowKeys: React.Key[];
}

/**
 * Hook wrapper responsible for handling the state and updating values in columns.
 * Should be used to create hooks that handle specific types of data.
 * @param param0
 * @returns
 */
export const useBulkActionsModal = <InnerValueType, ReturnValueType, DataType extends {key: React.Key} = OMSItemRenderType>({
    parseValue,
    render,
    defaultValue,
    data,
    selectedRowKeys,
}: BulkActionsRenderOptions<InnerValueType, ReturnValueType, DataType>) => {
    const [value, setValue] = useState<InnerValueType>(defaultValue);
    const [opened, setOpened] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [onOk, setOnOk] = useState<(value: InnerValueType) => void>(() => {});

    const localModal = {
        show: ({title, onUpdate, onRowUpdated, columnName}: BulkActionPropsGeneral<DataType>) => {
            setOpened(true);
            setModalTitle(`Change ${title} of ${selectedRowKeys.length} item(s) to`);
            const update = (value: InnerValueType) => {
                const keysSet = new Set(selectedRowKeys);
                const newData = data.map((item) => {
                    let itemCopy = {...item};
                    if (keysSet.has(item.key)) {
                        // @ts-ignore
                        itemCopy[columnName] = parseValue ? parseValue(value) : value;
                        if (onRowUpdated) {
                            itemCopy = onRowUpdated(itemCopy);
                        }
                    }
                    return itemCopy;
                });
                onUpdate(newData);
            };

            setOnOk(() => {
                return (value: InnerValueType) => {
                    update(value);
                    setOpened(false);
                };
            });
        },
    };

    const context: React.ReactElement = (
        <Modal title={modalTitle} centered open={opened} width={300} onCancel={() => setOpened(false)} onOk={() => onOk(value)}>
            {render(value, setValue)}
        </Modal>
    );

    const returnValue: BulkActionsModalHookType<BulkActionPropsGeneral<DataType>> = [localModal, context];
    return returnValue;
};

/**
 * Uses useBulkActionsModal hook wrapper to create a hook modal for handling
 * the supplier column.
 * @param data Original data.
 * @param selectedRowKeys Keys of rows to update.
 * @returns Tuple with modal handler with a function to show it and modal context that
 * should be rendered in a component.
 */
export const useSuppliersColumnModal = (data: OMSItemRenderType[], selectedRowKeys: React.Key[] = []) => {
    const {data: suppliers} = useSuppliers();
    const [modal, modalContext] = useBulkActionsModal<string | undefined, string | undefined>({
        defaultValue: undefined,
        render: (value, setValue) => (
            <Select
                showSearch
                value={value}
                options={(suppliers || [])
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((supplier) => ({
                        label: supplier.name,
                        value: supplier.name,
                    }))}
                autoFocus
                style={{width: 150}}
                onChange={(value) => setValue(value)}
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                allowClear
            />
        ),
        data,
        selectedRowKeys,
    });

    const newModal = {
        show: (props: BulkActionPropsSpecific) => {
            modal.show({
                ...props,
                columnName: "Supplier_Name",
                title: "Supplier Name",
            });
        },
    };

    return [newModal, modalContext] as BulkActionsModalHookType<BulkActionPropsSpecific>;
};

/**
 * Uses useBulkActionsModal hook wrapper to create a hook modal for handling
 * columns that store multiple values as a string separated by commas.
 * @param data Original data.
 * @param selectedRowKeys Keys of rows to update.
 * @returns Tuple with modal handler with a function to show it and modal context that
 * should be rendered in a component.
 */
export const useSelectMultipleColumnModal = (
    data: any[],
    selectedRowKeys: React.Key[] = [],
    availableValues: readonly string[] = SEASONAL_TAGS,
    parseValue: (value: string[]) => any = (value) => value.join(";")
) => {
    const [modal, modalContext] = useBulkActionsModal<string[], string | undefined>({
        defaultValue: [],
        render: (value, setValue) => (
            <Select
                mode="tags"
                value={value}
                options={availableValues.map((tag) => ({
                    label: tag,
                    value: tag,
                }))}
                autoFocus
                style={{width: 150}}
                onChange={(value) => setValue(value)}
                allowClear
            />
        ),
        parseValue: parseValue,
        data,
        selectedRowKeys,
    });

    return [modal, modalContext] as BulkActionsModalHookType;
};

/**
 * Uses useBulkActionsModal hook wrapper to create a hook modal for handling
 * columns that store only certain values.
 * @param data Original data.
 * @param selectedRowKeys Keys of rows to update.
 * @returns Tuple with modal handler with a function to show it and modal context that
 * should be rendered in a component.
 */
export const useSelectColumnModal = (
    data: OMSItemRenderType[],
    selectedRowKeys: React.Key[] = [],
    availableValues: readonly string[] = VENDOR_TYPES
) => {
    const [modal, modalContext] = useBulkActionsModal<string | undefined, string | undefined>({
        defaultValue: undefined,
        render: (value, setValue) => (
            <Select
                value={value}
                options={availableValues.map((tag) => ({
                    label: tag,
                    value: tag,
                }))}
                autoFocus
                style={{width: 150}}
                onChange={(value) => setValue(value)}
                allowClear
            />
        ),
        data,
        selectedRowKeys,
    });

    return [modal, modalContext] as BulkActionsModalHookType;
};

/**
 * Uses useBulkActionsModal hook wrapper to create a hook modal for handling
 * columns that store dates.
 * @param data Original data.
 * @param selectedRowKeys Keys of rows to update.
 * @returns Tuple with modal handler with a function to show it and modal context that
 * should be rendered in a component.
 */
export const useDateColumnModal = <DataType extends {key: React.Key} = OMSItemRenderType>(
    data: DataType[],
    selectedRowKeys: React.Key[] = []
) => {
    const [modal, modalContext] = useBulkActionsModal<string | undefined, string | undefined, DataType>({
        defaultValue: new Date().toISOString(),
        render: (value, setValue) => <DateTimePicker value={value} onChange={(date) => setValue(date)} />,
        data,
        selectedRowKeys,
    });

    return [modal, modalContext] as BulkActionsModalHookType<BulkActionPropsGeneral<DataType>>;
};

/**
 * Uses useBulkActionsModal hook wrapper to create a hook modal for handling
 * columns that store numbers.
 * @param data Original data.
 * @param selectedRowKeys Keys of rows to update.
 * @returns Tuple with modal handler with a function to show it and modal context that
 * should be rendered in a component.
 */
export const useNumberColumnModal = <DataType extends {key: React.Key} = OMSItemRenderType>(
    data: DataType[],
    selectedRowKeys: React.Key[] = []
) => {
    const [modal, modalContext] = useBulkActionsModal<number, number, DataType>({
        defaultValue: 0,
        render: (value, setValue) => (
            <InputNumber
                min={0}
                value={value}
                onChange={(value) => {
                    if (value !== undefined && value !== null) setValue(value);
                }}
            />
        ),
        data,
        selectedRowKeys,
    });

    return [modal, modalContext] as BulkActionsModalHookType<BulkActionPropsGeneral<DataType>>;
};

/**
 * Uses useBulkActionsModal hook wrapper to create a hook modal for handling
 * columns that store only certain values.
 * @param data Original data.
 * @param selectedRowKeys Keys of rows to update.
 * @returns Tuple with modal handler with a function to show it and modal context that
 * should be rendered in a component.
 */
export const useBoolColumnModal = <DataType extends {key: React.Key} = OMSItemRenderType>(
    data: DataType[],
    selectedRowKeys: React.Key[] = []
) => {
    const [modal, modalContext] = useBulkActionsModal<string | undefined, boolean | undefined, DataType>({
        defaultValue: undefined,
        render: (value, setValue) => (
            <Select
                value={value}
                options={[
                    {
                        label: "YES",
                        value: "YES",
                    },
                    {
                        label: "NO",
                        value: "NO",
                    },
                ]}
                autoFocus
                style={{width: 150}}
                onChange={(value) => setValue(value)}
                allowClear
            />
        ),
        parseValue: (value) => {
            if (!value) return undefined;
            if (value === "YES") return true;
            if (value === "NO") return false;
        },
        data,
        selectedRowKeys,
    });

    return [modal, modalContext] as BulkActionsModalHookType<BulkActionPropsGeneral<DataType>>;
};

/**
 * Uses useBulkActionsModal hook wrapper to create a hook modal for handling
 * columns that store text.
 * @param data Original data.
 * @param selectedRowKeys Keys of rows to update.
 * @returns Tuple with modal handler with a function to show it and modal context that
 * should be rendered in a component.
 */
export const useTextColumnModal = <DataType extends {key: React.Key} = OMSItemRenderType>(
    data: DataType[],
    selectedRowKeys: React.Key[] = []
) => {
    const [modal, modalContext] = useBulkActionsModal<string, string, DataType>({
        defaultValue: "",
        render: (value, setValue) => (
            <Input.TextArea
                value={value}
                onChange={(event) => {
                    if (event.target.value) setValue(event.target.value);
                }}
            />
        ),
        data,
        selectedRowKeys,
    });

    return [modal, modalContext] as BulkActionsModalHookType<BulkActionPropsGeneral<DataType>>;
};
