import {Col, Row, Statistic, Tooltip} from "antd";
import React from "react";
import {InventoryHealthItem} from "../../types/OmegaTypes";

export const GetStorageCost = (invHealthItem: InventoryHealthItem) => {
    const VOL_SHORTERM_FEE = 2.4;
    const VOL_OVERSIZE_SHORTERM_FEE = 1.4;
    const VOL_LONGTERM_FEE = 1.5;
    const VOL_MEDIUMLONGTERM_FEE = 4;
    const VOL_VERYLONGTERM_FEE = 6.9;
    const UNIT_VERYLONGTERM_FEE = 0.15;

    const volume = parseFloat(invHealthItem["item-volume"]);
    const isOversize =
        invHealthItem["storage-type"]?.toLowerCase().includes("oversize") ||
        invHealthItem["storage-type"]?.toLowerCase().includes("extra-large");
    const shortTermStorageUnits =
        parseInt(invHealthItem["inv-age-0-to-30-days"]) +
        parseInt(invHealthItem["inv-age-31-to-60-days"]) +
        parseInt(invHealthItem["inv-age-61-to-90-days"]) +
        parseInt(invHealthItem["inv-age-91-to-180-days"]);

    const longTermStorageUnits = parseInt(invHealthItem["inv-age-181-to-270-days"]);
    const mediumLongTermStorageUnits = parseInt(invHealthItem["inv-age-271-to-365-days"]);
    const veryLongTermStorageUnits = parseInt(invHealthItem["inv-age-365-plus-days"]);

    const veryLongTermFee = Math.max(
        veryLongTermStorageUnits * volume * VOL_VERYLONGTERM_FEE,
        veryLongTermStorageUnits * UNIT_VERYLONGTERM_FEE
    );

    return (
        (shortTermStorageUnits + longTermStorageUnits + veryLongTermStorageUnits) *
            volume *
            (isOversize ? VOL_OVERSIZE_SHORTERM_FEE : VOL_SHORTERM_FEE) +
        longTermStorageUnits * volume * VOL_LONGTERM_FEE +
        mediumLongTermStorageUnits * volume * VOL_MEDIUMLONGTERM_FEE +
        veryLongTermFee
    );
};

const InvHealthPopover: React.FC<{invHealthItem: InventoryHealthItem}> = ({invHealthItem}) => {
    return (
        <div style={{width: "500px", height: "150px"}}>
            <Row justify="space-between" style={{width: "100%", height: "50%", textAlign: "center"}}>
                <Col span={4} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="0-30 D" value={invHealthItem["inv-age-0-to-30-days"]} precision={0} />
                </Col>
                <Col span={4} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="31-60 D" value={invHealthItem["inv-age-31-to-60-days"]} precision={0} />
                </Col>
                <Col span={4} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="61-90 D" value={invHealthItem["inv-age-61-to-90-days"]} precision={0} />
                </Col>
                <Col span={6} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="91-180 D" value={invHealthItem["inv-age-91-to-180-days"]} precision={0} />
                </Col>
                <Col span={6} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="181-270 D" value={invHealthItem["inv-age-181-to-270-days"]} precision={0} />
                </Col>
            </Row>
            <Row justify="space-between" style={{width: "100%", height: "50%", textAlign: "center"}}>
                <Col span={4} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="271-365 D" value={invHealthItem["inv-age-271-to-365-days"]} precision={0} />
                </Col>
                <Col span={4} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="365+ D" value={invHealthItem["inv-age-365-plus-days"]} precision={0} />
                </Col>
                <Col span={4} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic
                        title={
                            <Tooltip title="The next monthly charge for storage of our inventory units (assumes no inventory charge). Includes long term storage fees.">
                                Storage (?)
                            </Tooltip>
                        }
                        value={GetStorageCost(invHealthItem)}
                        precision={2}
                        prefix={"$"}
                    />
                </Col>
                <Col span={6} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic
                        title={<Tooltip title="Storage costs averaged per unit">Storage / Unit</Tooltip>}
                        value={GetStorageCost(invHealthItem) / parseInt(invHealthItem.available)}
                        precision={2}
                        prefix={"$"}
                    />
                </Col>
                <Col span={6} style={{height: "100%", display: "flex", justifyContent: "space-around", flexDirection: "column"}}>
                    <Statistic title="Snap Date" value={invHealthItem["snapshot-date"]} />
                </Col>
            </Row>
        </div>
    );
};

export default InvHealthPopover;
