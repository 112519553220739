import React, {useMemo} from "react";
import {
    Button,
    Card,
    Descriptions,
    Modal,
    Radio,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
    InputNumber,
    List,
    Row,
    Col,
    Select,
    Skeleton,
    Avatar,
    Collapse,
} from "antd";
import {InboundShipmentV2, SHIPMENT_TYPES, FREIGHT_CLASSES, FreightClass, InboundShipmentItemV2} from "../../../types/WarehouseTypes";
import {PrinterOutlined, DeleteOutlined, TruckOutlined} from "@ant-design/icons";
import {DescriptionsItemType} from "antd/lib/descriptions";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import {Box, Item, SpdTrackingItem} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {ColumnsType} from "antd/lib/table";
import {DateTimePicker} from "../../omega/table/DateTimePicker";
import {getFreightClass} from "../common/freightClass";

interface TrackingDetailsViewProps {
    shipment: InboundShipmentV2;
    open: boolean;
    onClose: () => void;
}

const TrackingDetailsView: React.FC<TrackingDetailsViewProps> = ({shipment, open, onClose}) => {
    const trackingDetails = shipment.trackingDetails;
    const spdColumns: ColumnsType<SpdTrackingItem> = [
        {
            title: "Box ID",
            dataIndex: "boxId",
            key: "boxId",
        },
        {
            title: "Tracking ID",
            dataIndex: "trackingId",
            key: "trackingId",
        },
        {
            title: "Tracking Number Validation Status",
            dataIndex: "trackingNumberValidationStatus",
            key: "trackingNumberValidationStatus",
        },
    ];

    return (
        <Modal title="Tracking Details" open={open} onCancel={onClose} footer={null} width={"50vw"}>
            {!!trackingDetails?.spdTrackingDetail?.spdTrackingItems?.length && (
                <Table
                    dataSource={trackingDetails.spdTrackingDetail.spdTrackingItems}
                    columns={spdColumns}
                    rowKey="trackingId"
                    pagination={false}
                    size="small"
                />
            )}
            {trackingDetails?.ltlTrackingDetail?.billOfLadingNumber && (
                <Descriptions
                    bordered
                    size="small"
                    column={2}
                    items={[
                        {
                            key: "billOfLadingNumber",
                            label: "Bill of Lading Number",
                            children: trackingDetails.ltlTrackingDetail.billOfLadingNumber,
                        },
                        {
                            key: "freightBillNumber",
                            label: "Freight Bill Number",
                            children: trackingDetails.ltlTrackingDetail.freightBillNumber?.join(", ") || "N/A",
                        },
                    ]}
                />
            )}
        </Modal>
    );
};

interface BoxesViewProps {
    boxes: Box[];
    expectedItems: InboundShipmentItemV2[];
    open: boolean;
    title: string;
    onClose: () => void;
}

const BoxesView: React.FC<BoxesViewProps> = ({boxes, open, onClose, title, expectedItems}) => {
    const itemColumns: ColumnsType<Item> = [
        {
            title: "Image",
            dataIndex: "imgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />),
        },
        {
            title: "MSKU",
            dataIndex: "msku",
            key: "msku",
        },
        {
            title: "FNSKU",
            dataIndex: "fnsku",
            key: "fnsku",
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Expiration",
            dataIndex: "expiration",
            key: "expiration",
            render: (text?: string) => text || "N/A",
        },
    ];

    return (
        <Modal title={title} open={open} onCancel={onClose} width={800} footer={null}>
            <Space direction="vertical" style={{width: "100%", maxHeight: "70vh", overflow: "auto"}}>
                {boxes.map((box, index) => (
                    <Card key={box.packageId || index}>
                        <Space direction="vertical" style={{width: "100%"}}>
                            <Descriptions
                                size="small"
                                items={[
                                    {
                                        key: "template",
                                        label: "Template",
                                        children: box.templateName || "N/A",
                                    },
                                    {
                                        key: "weight",
                                        label: "Weight",
                                        children: box.weight ? `${box.weight.value} ${box.weight.unit}` : "N/A",
                                    },
                                ]}
                            />
                            <Collapse>
                                <Collapse.Panel header={`${box.items?.length || 0} items`} key="1">
                                    <Table
                                        dataSource={box.items?.map((item) => ({
                                            ...item,
                                            ...expectedItems.find((i) => i.fnsku === item.fnsku),
                                        }))}
                                        columns={itemColumns}
                                        pagination={false}
                                        showHeader={false}
                                    />
                                </Collapse.Panel>
                            </Collapse>
                        </Space>
                    </Card>
                ))}
            </Space>
        </Modal>
    );
};

interface PalletsDialogProps {
    open: boolean;
    onClose: () => void;
    shipment: InboundShipmentV2;
}

const PalletsDialog: React.FC<PalletsDialogProps> = ({open, onClose, shipment}) => {
    const {actions} = useStore(
        useShallow((state) => ({
            actions: state.packFirstBoxingActions,
        }))
    );

    return (
        <Modal
            title="Manage Pallets"
            open={open}
            onCancel={onClose}
            footer={[
                <Button key="add" type="primary" onClick={() => actions.addPallet(shipment.shipmentId)}>
                    Add Pallet
                </Button>,
                <Button key="close" onClick={onClose}>
                    Finish
                </Button>,
            ]}
            width={800}
            style={{height: "50vh", overflow: "auto"}}
        >
            <List
                dataSource={shipment.pallets || []}
                renderItem={(pallet, index) => (
                    <List.Item
                        key={pallet.id}
                        actions={[
                            <Button
                                type="text"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => actions.removePallet(shipment.shipmentId, pallet.id)}
                            />,
                        ]}
                    >
                        <List.Item.Meta
                            description={
                                <Row>
                                    <Col span={4}>
                                        <Typography.Text>Pallet #{index + 1}</Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space>
                                            <InputNumber
                                                placeholder="L"
                                                name="length"
                                                min={0}
                                                addonBefore="L"
                                                value={pallet.dimensions.length}
                                                onChange={(value) => {
                                                    actions.updatePalletDimensions(shipment.shipmentId, pallet.id, {
                                                        ...pallet.dimensions,
                                                        length: value || 0,
                                                    });
                                                }}
                                            />
                                            <InputNumber
                                                placeholder="W"
                                                name="width"
                                                min={0}
                                                addonBefore="W"
                                                value={pallet.dimensions.width}
                                                onChange={(value) => {
                                                    actions.updatePalletDimensions(shipment.shipmentId, pallet.id, {
                                                        ...pallet.dimensions,
                                                        width: value || 0,
                                                    });
                                                }}
                                            />
                                            <InputNumber
                                                placeholder="H"
                                                name="height"
                                                min={0}
                                                addonBefore="H"
                                                value={pallet.dimensions.height}
                                                onChange={(value) => {
                                                    actions.updatePalletDimensions(shipment.shipmentId, pallet.id, {
                                                        ...pallet.dimensions,
                                                        height: value || 0,
                                                    });
                                                }}
                                            />
                                            <InputNumber
                                                placeholder="Weight"
                                                name="weight"
                                                min={0}
                                                addonBefore="Weight"
                                                value={pallet.dimensions.weight}
                                                onChange={(value) => {
                                                    actions.updatePalletDimensions(shipment.shipmentId, pallet.id, {
                                                        ...pallet.dimensions,
                                                        weight: value || 0,
                                                    });
                                                }}
                                            />
                                        </Space>
                                    </Col>
                                </Row>
                            }
                        />
                    </List.Item>
                )}
                bordered
                size="small"
            />
        </Modal>
    );
};

interface ShipmentViewProps {
    shipment: InboundShipmentV2;
    index: number;
}

const ShipmentView: React.FC<ShipmentViewProps> = ({shipment, index}) => {
    const {data, actions} = useStore(useShallow((state) => ({data: state.packFirstBoxingData, actions: state.packFirstBoxingActions})));
    const [isBoxesModalOpen, setIsBoxesModalOpen] = React.useState(false);
    const [isPalletsModalOpen, setIsPalletsModalOpen] = React.useState(false);
    const [isTrackingModalOpen, setIsTrackingModalOpen] = React.useState(false);

    const address = shipment.destination.address;
    const transportOptions = useMemo(() => {
        const amazonOptions =
            shipment.transportationOptions?.filter((option) => option.shippingSolution === "AMAZON_PARTNERED_CARRIER") || [];
        if (shipment.shipmentType === "LTL") {
            return amazonOptions.filter((option) => option.shippingMode === "FREIGHT_LTL");
        } else {
            return amazonOptions.filter((option) => option.shippingMode === "GROUND_SMALL_PARCEL");
        }
    }, [shipment.transportationOptions, shipment.shipmentType]);

    const packingOption = Object.values(data.packingOptions.byId).find((option) => option.inboundPlanId === shipment.inboundPlanId);
    const isEditable = packingOption?.confirmTransportationOptionsStatus?.operationStatus !== "SUCCESS";

    const descriptionItems: DescriptionsItemType[] = [
        {
            key: "destination",
            label: "Destination",
            span: 2,
            children: (
                <Tooltip
                    title={`${address?.addressLine1}, ${address?.city}, ${address?.stateOrProvinceCode} ${address?.postalCode} ${address?.countryCode}`}
                >
                    <Space direction="horizontal" style={{width: "100%"}} styles={{item: {width: "100%", justifyContent: "space-between"}}}>
                        <Typography.Paragraph style={{width: 200, margin: 0}} ellipsis>
                            {shipment.destination.warehouseId} - {address?.city}
                        </Typography.Paragraph>
                        <Typography.Text type="secondary">
                            {shipment.distance ? `~${Math.round(shipment.distance)} miles` : "N/A"}
                        </Typography.Text>
                    </Space>
                </Tooltip>
            ),
        },
        {
            key: "boxes",
            label: "Boxes",
            children: (
                <Space style={{width: 100}}>
                    <Typography.Text>{shipment?.boxes?.length || "N/A"}</Typography.Text>
                    <Button type="link" onClick={() => setIsBoxesModalOpen(true)}>
                        Show
                    </Button>
                </Space>
            ),
        },
        {
            key: "pallets",
            label: "Pallets",
            children: (
                <Spin spinning={shipment.shipmentType !== "LTL" || !isEditable} indicator={<></>}>
                    <Typography.Text>{shipment?.pallets?.length || "---"}</Typography.Text>
                    <Button type="link" onClick={() => setIsPalletsModalOpen(true)}>
                        Manage
                    </Button>
                </Spin>
            ),
        },
        {
            key: "freight_class",
            label: "Freight Class",
            children: (
                <Spin spinning={shipment.shipmentType !== "LTL" || !isEditable} indicator={<></>}>
                    <Select
                        style={{width: 100}}
                        options={FREIGHT_CLASSES.map((cls) => ({label: cls, value: cls}))}
                        value={shipment.freightClass}
                        onChange={(value) => {
                            actions.setFreightClass(shipment.shipmentId, value as FreightClass);
                        }}
                    />
                </Spin>
            ),
        },
        {
            key: "declared_value",
            label: "Declared Value",
            children: <Typography.Text>${shipment.declaredValue?.toFixed(2) || "N/A"}</Typography.Text>,
        },
        {
            key: "ready_to_ship_date",
            label: "Ship Date",
            children: (
                <Spin spinning={!isEditable} indicator={<></>}>
                    <DateTimePicker
                        value={shipment.readyToShipDate}
                        onChange={(date) => actions.setReadyToShipDate(shipment.shipmentId, date)}
                    />
                </Spin>
            ),
        },
        {
            key: "shipment_type",
            label: "Type",
            children: (
                <Spin spinning={!isEditable} indicator={<></>}>
                    <Radio.Group
                        size="small"
                        block
                        options={[...SHIPMENT_TYPES]}
                        optionType="button"
                        buttonStyle="solid"
                        value={shipment.shipmentType}
                        onChange={(value) => actions.setShipmentType(shipment.shipmentId, value.target.value)}
                    />
                </Spin>
            ),
        },
        {
            key: "transport_options",
            label: "Transport Options",
            span: 2,
            children: (
                <Spin spinning={transportOptions.length === 0 || !isEditable} indicator={<></>}>
                    <Select
                        style={{width: "100%"}}
                        options={transportOptions.map((option) => ({
                            label: `${option.carrier.name} - $${option.quote?.cost?.amount || "N/A"}`,
                            value: option.transportationOptionId,
                        }))}
                        value={shipment.selectedTransportationOptionId}
                        onChange={(value) => {
                            actions.setSelectedTransportationOptionId(shipment.shipmentId, value);
                        }}
                    />
                </Spin>
            ),
        },
    ];

    return (
        <>
            <Card
                title={`Shipment ${index + 1}${shipment.shipmentConfirmationId ? ` - ${shipment.shipmentConfirmationId}` : ""}`}
                style={{marginBottom: 16, width: 470}}
                extra={
                    <Space>
                        <Button
                            icon={<TruckOutlined />}
                            onClick={() => setIsTrackingModalOpen(true)}
                            disabled={!shipment.trackingDetails}
                        />
                        <Button icon={<PrinterOutlined />} onClick={() => actions.getLabels(shipment.shipmentId)} />
                    </Space>
                }
                styles={{body: {padding: 0}, header: {borderBottom: "none"}}}
            >
                <Descriptions bordered size="small" column={2} items={descriptionItems} labelStyle={{margin: 0}} />
            </Card>
            <BoxesView
                boxes={shipment.boxes || []}
                expectedItems={shipment.expectedItems || []}
                open={isBoxesModalOpen}
                onClose={() => setIsBoxesModalOpen(false)}
                title={`Boxes for Shipment ${index + 1}`}
            />
            <PalletsDialog
                open={isPalletsModalOpen}
                onClose={() => {
                    if (shipment.pallets && shipment.expectedItems) {
                        const freightClass = getFreightClass(
                            shipment.pallets?.map((p) => p.dimensions) || [],
                            shipment.expectedItems || []
                        );
                        actions.setFreightClass(shipment.shipmentId, freightClass);
                    }
                    setIsPalletsModalOpen(false);
                }}
                shipment={shipment}
            />
            <TrackingDetailsView shipment={shipment} open={isTrackingModalOpen} onClose={() => setIsTrackingModalOpen(false)} />
        </>
    );
};

export default ShipmentView;
