import { Dimensions, FreightClass, InboundShipmentItemV2 } from "../../../types/WarehouseTypes";

export const GENERAL_FREIGHT_CLASSES: {[key in FreightClass]?: number} = {
    "FC_400": 0,
    "FC_300": 1,
    "FC_250": 2,
    "FC_175": 4,
    "FC_125": 6,
    "FC_100": 8,
    "FC_92_5": 10,
    "FC_85": 12,
    "FC_70": 15,
    "FC_65": 22.5,
    "FC_60": 30,
};

export const FOOTWEAR_FREIGHT_CLASSES: {[key in FreightClass]?: number} = {
    "FC_200": 0,
    "FC_125": 6,
};

export function getFreightClass(pallets_dimensions: Dimensions[], products: InboundShipmentItemV2[]): FreightClass {
    // Calculate the number of items in each productGroup
    const productGroups: {[key: string]: number} = {};
    products.forEach((product) => {
        if (product.productGroup) {
            if (productGroups[product.productGroup]) {
                productGroups[product.productGroup] += product.quantityInBox || 0;
            } else {
                productGroups[product.productGroup] = product.quantityInBox || 0;
            }
        }
    });

    // Check what percentage of the total number of items Shoe products take
    const shoePercentage = productGroups["Shoes"] / Object.values(productGroups).reduce((a, b) => a + b, 0);

    let classes_object: {[key in FreightClass]?: number} | undefined = undefined;
    if (shoePercentage > 0.6) {
        classes_object = FOOTWEAR_FREIGHT_CLASSES;
    } else {
        classes_object = GENERAL_FREIGHT_CLASSES;
    }

    let total_volume = 0;
    let total_weight = 0;
    pallets_dimensions.forEach((dim) => {
        total_volume += dim.length * dim.width * dim.height;
        total_weight += dim.weight;
    });
    total_volume /= 1728; // Convert volume from cubic inches to cubic feet

    const density = total_weight / total_volume;

    const classItems = Object.entries(classes_object);
    classItems.sort((a, b) => a[1]! - b[1]!);

    const classes = classItems.map((item) => item[0] as FreightClass);
    const brackets = classItems.map((item) => item[1]).concat([Infinity]);

    for (let i = 0; i < classes.length; i++) {
        if (density < brackets[i + 1]) {
            return classes[i];
        }
    }

    return "FC_60";
}
