import {notification} from "antd";
import {countItems, countItemsByKey, randID, verifyShipment} from "../../../components/warehouse/pack-later/boxing/helpers";
import {getShipments, removeShipmentsData, saveShipmentsData} from "../../../services/WarehouseService";
import {Dimensions, InboundBoxV2, InboundPallet, InboundShipmentItemV2, InboundShipmentV2} from "../../../types/WarehouseTypes";
import {AppState, PackLaterBoxingDataSlice, ImmerStateCreator} from "../../../types/storeTypes";
import {getSearchType} from "../../../components/warehouse/common/helpers";
import {normalize} from "../../helpers";

const WRONG_FLOW_ERROR = "This box was probably created in the Pack First flow";

export const createPackLaterBoxingDataSlice: ImmerStateCreator<AppState, PackLaterBoxingDataSlice> = (set, get) => ({
    shipments: {
        byId: {},
        allIds: [],
    },
    pallets: {
        byId: {},
        allIds: [],
    },
    boxes: {
        byId: {},
        allIds: [],
    },
    state: {},
    loadingShipments: [],
    addLoadingShipment: (id, message) => {
        set((state) => {
            state.loadingShipments = state.loadingShipments.filter((s) => s.id !== id);
            state.loadingShipments.push({id, message});
        });
    },
    removeLoadingShipment: (id) => {
        set((state) => {
            state.loadingShipments = state.loadingShipments.filter((s) => s.id !== id);
        });
    },
    setShipmentType: (shipmentId, shipmentType) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        if (shipment?.shipmentType === "LTL" && (shipment?.palletsIds?.length || 0) > 1) {
            notification.error({
                message: "Cannot change shipment type",
                description: "Shipment has more than 1 pallet, please remove them first",
            });
            return;
        }
        set((state) => {
            state.shipments.byId[shipmentId].shipmentType = shipmentType;
        });
        state.updateShipmentStatus(shipmentId, {lastUpdate: new Date().getTime()});
        state.saveData({shipmentIds: [shipmentId]});
    },
    setState: (state) => {
        set((prevState) => {
            prevState.state = {...prevState.state, ...state};
        });
    },
    getData: async () => {
        set((state) => {
            state.state.isDataLoading = true;
        });

        const {shipments, boxes, pallets} = await getShipments();

        // Add 1 pallet and 1 box to every empty shipment
        const finalBoxes: InboundBoxV2[] = [];
        const finalPallets: InboundPallet[] = [];
        shipments.forEach((shipment) => {
            const verifiedData = verifyShipment(shipment, boxes, pallets);
            finalBoxes.push(...verifiedData.boxes);
            finalPallets.push(...verifiedData.pallets);
        });

        set((state) => {
            state.shipments = normalize(shipments, "shipmentId");
            state.pallets = normalize(finalPallets, "id");
            state.boxes = normalize(finalBoxes, "id");
            state.state.isDataLoading = false;
        });

        set((state) => {
            state.state.isDataLoading = false;
        });
    },
    saveData: async ({shipmentIds, palletIds, boxIds}) => {
        try {
            const shipmentsState = get().shipments;
            const palletsState = get().pallets;
            const boxesState = get().boxes;

            const shipments = shipmentIds?.map((id) => shipmentsState.byId[id]);
            const pallets = palletIds?.map((id) => palletsState.byId[id]);
            const boxes = boxIds?.map((id) => boxesState.byId[id]);

            // Save the data
            await saveShipmentsData(shipments || [], pallets || [], boxes || [], []);
        } catch (e: any) {
            notification.error({
                message: "Error saving data",
                description: e.message,
            });
        }
    },
    addPallet: (shipmentId: string) => {
        const id = randID();
        set((state) => {
            const shipment = state.shipments.byId[shipmentId];
            const pallet: InboundPallet = {
                id,
                shipmentId,
                number: (shipment.palletsIds?.length || 0) + 1,
                boxesIds: [],
                dimensions: {
                    length: 48,
                    width: 40,
                    height: 48,
                    weight: 40,
                },
            };
            if (!shipment.palletsIds) {
                shipment.palletsIds = [id];
            } else {
                shipment.palletsIds.push(id);
            }
            state.pallets.byId[pallet.id] = pallet;
            state.pallets.allIds.push(pallet.id);
        });

        // Automatically add a box to the new pallet, and make it the current box
        get().addBox(id);
        get().saveData({shipmentIds: [shipmentId]});
        return id;
    },
    removePallet: (palletId: string) => {
        const pallet = get().pallets.byId[palletId];
        set((state) => {
            pallet.boxesIds.forEach((boxId) => {
                delete state.boxes.byId[boxId];
                state.boxes.allIds = state.boxes.allIds.filter((id) => id !== boxId);
            });
            delete state.pallets.byId[palletId];
            state.pallets.allIds = state.pallets.allIds.filter((id) => id !== palletId);

            const shipment = state.shipments.byId[pallet.shipmentId];
            if (shipment.palletsIds) {
                shipment.palletsIds = shipment.palletsIds.filter((id) => id !== palletId);
            }
            // Reorder pallets
            shipment.palletsIds?.forEach((id, index) => {
                state.pallets.byId[id].number = index + 1;
            });
        });

        get().updateShipmentStatus(pallet.shipmentId, {lastUpdate: new Date().getTime()});
        get().saveData({shipmentIds: [pallet.shipmentId]});
        removeShipmentsData([palletId], pallet.boxesIds);
    },
    addBox: (palletId: string, save: boolean = true) => {
        const id = randID();
        set((state) => {
            const box: InboundBoxV2 = {
                id,
                shipmentId: state.pallets.byId[palletId].shipmentId,
                number: state.pallets.byId[palletId].boxesIds.length + 1,
                palletId,
                items: [],
            };
            state.boxes.byId[box.id] = box;
            state.boxes.allIds.push(box.id);
            state.pallets.byId[palletId].boxesIds.push(box.id);

            // Make the new box the current box
            state.pallets.byId[palletId].activeBox = box.id;
        });

        // For convenience, we add the first box and pallet to a shipment automatically when we fetch it, but
        // it allows for potential duplicates if we save them immediately in DB
        // (component might get re-rendered quickly which creates a race condition).
        // As a quick and easy fix, we save all pallets and boxes at once when the user adds a new box.
        // It solves the problem and it shouldn't be a performance issue, because adding a box is not a very
        // frequent operation (compared to adding items or changing their quantity).
        if (save) {
            const state = get();
            state.saveData({palletIds: state.pallets.allIds, boxIds: state.boxes.allIds});
        }
        return id;
    },
    removeBox: (boxId: string) => {
        const box = get().boxes.byId[boxId];
        if (!box.palletId || !box.shipmentId) {
            throw new Error(WRONG_FLOW_ERROR);
        }
        const palletId = box.palletId;
        const pallet = get().pallets.byId[box.palletId];
        set((state) => {
            const box = state.boxes.byId[boxId];
            if (!box.palletId) {
                throw new Error(WRONG_FLOW_ERROR);
            }
            const pallet = state.pallets.byId[box.palletId];
            // If this is the last box, just remove items and dimensions
            if (pallet.boxesIds.length === 1) {
                box.items = [];
                pallet.dimensions.weight -= box.dimensions?.weight || 0;
                delete box.dimensions;
                return;
            } else {
                pallet.boxesIds = pallet.boxesIds.filter((id) => id !== boxId);
                delete state.boxes.byId[boxId];
                state.boxes.allIds = state.boxes.allIds.filter((id) => id !== boxId);

                if (pallet.activeBox === boxId) {
                    pallet.activeBox = pallet.boxesIds[pallet.boxesIds.length - 1];
                }
            }

            // Reorder boxes
            pallet.boxesIds.forEach((id, index) => {
                state.boxes.byId[id].number = index + 1;
            });
            pallet.dimensions.weight -= box.dimensions?.weight || 0;
        });

        get().updateShipmentStatus(box.shipmentId, {lastUpdate: new Date().getTime()});
        // Save the pallet to update the activeBox field
        get().saveData({palletIds: [palletId], shipmentIds: [box.shipmentId]});
        if (pallet.boxesIds.length > 1) {
            // Remove this box from DB
            removeShipmentsData([], [boxId]);
        } else {
            get().saveData({boxIds: [boxId]});
        }
    },
    setActiveBox: (palletId: string, boxId?: string) => {
        set((state) => {
            state.pallets.byId[palletId].activeBox = boxId;
        });
    },
    addPalletDimensions: (palletId: string, dimensions: Dimensions) => {
        set((state) => {
            state.pallets.byId[palletId].dimensions = dimensions;
        });

        const pallet = get().pallets.byId[palletId];
        get().updateShipmentStatus(pallet.shipmentId, {lastUpdate: new Date().getTime()});
        get().saveData({shipmentIds: [pallet.shipmentId], palletIds: [palletId]});
    },
    addBoxDimensions: (boxId: string, dimensions: Dimensions) => {
        const box = get().boxes.byId[boxId];
        if (!box.palletId || !box.shipmentId) {
            throw new Error(WRONG_FLOW_ERROR);
        }
        const pallet = get().pallets.byId[box.palletId];
        set((state) => {
            state.boxes.byId[boxId].dimensions = dimensions;
        });

        get().addPalletDimensions(pallet.id, {...pallet.dimensions, weight: dimensions.weight + pallet.dimensions.weight});
        get().updateShipmentStatus(box.shipmentId, {lastUpdate: new Date().getTime()});
        get().saveData({shipmentIds: [box.shipmentId], boxIds: [boxId]});
    },
    addItem: (item, quantity, palletId) => {
        const pallet = get().pallets.byId[palletId];
        let boxId = pallet.activeBox;
        if (!boxId) {
            boxId = pallet.boxesIds[0];
        }
        let limitReached = false;
        // Count already added units
        const boxes = Object.values(get().boxes.byId).filter((b) => b.shipmentId === pallet.shipmentId) as InboundBoxV2[];
        const units = countItems(boxes, item.msku);

        if (units + quantity > item.quantity) {
            notification.error({
                message: "Cannot add item",
                description: "Adding this item would exceed the shipped quantity",
            });
            return;
        }

        set((state) => {
            const box = state.boxes.byId[boxId!];
            const existingItem = box.items.find((i) => i.fnsku === item.fnsku);
            if (existingItem) {
                existingItem.quantityInBox = quantity + (existingItem.quantityInBox || 0);
            } else if (box.items.length < 200) {
                box.items.push({...item, quantityInBox: quantity});
            } else {
                limitReached = true;
            }
        });

        notification.success({
            message: "Item added",
            description: "Item added to the current box",
        });
        if (limitReached) {
            notification.error({
                message: "Item limit reached",
                description: "You can't add more than 200 items to a box",
            });
            return;
        }

        get().updateShipmentStatus(pallet.shipmentId, {lastUpdate: new Date().getTime()});
        get().saveData({shipmentIds: [pallet.shipmentId], boxIds: [boxId!], palletIds: [palletId]});
    },
    changeItemQuantity: (sku, boxId, quantity) => {
        const state = get();
        // Get all boxes for the shipment
        const box = state.boxes.byId[boxId];
        if (!box.palletId || !box.shipmentId) {
            throw new Error(WRONG_FLOW_ERROR);
        }
        const otherBoxes = Object.values(state.boxes.byId).filter(
            (b) => b.shipmentId === box.shipmentId && b.id !== box.id
        ) as InboundBoxV2[];
        const otherBoxesQuantity = countItems(otherBoxes, sku);

        set((state) => {
            const box = state.boxes.byId[boxId];
            const item = box.items.find((i) => i.msku === sku);
            const sumQuantity = otherBoxesQuantity + quantity;
            if (item && quantity >= 0 && sumQuantity <= item.quantity) {
                item.quantityInBox = quantity;
            }
        });
        get().updateShipmentStatus(box.shipmentId, {lastUpdate: new Date().getTime()});
        get().saveData({shipmentIds: [box.shipmentId], boxIds: [boxId]});
    },
    removeItem: (sku, boxId) => {
        set((state) => {
            const box = state.boxes.byId[boxId];
            box.items = box.items.filter((i) => i.msku !== sku);
        });

        const box = get().boxes.byId[boxId];
        if (!box.palletId || !box.shipmentId) {
            throw new Error(WRONG_FLOW_ERROR);
        }
        get().updateShipmentStatus(box.shipmentId, {lastUpdate: new Date().getTime()});
        get().saveData({shipmentIds: [box.shipmentId], boxIds: [boxId]});
    },
    setActivePallet: (shipmentId, palletId) => {
        set((state) => {
            state.shipments.byId[shipmentId].activePallet = palletId;
        });
    },
    updateShipment: (shipmentId: string, shipment: InboundShipmentV2) => {
        set((state) => {
            state.shipments.byId[shipmentId] = {...state.shipments.byId[shipmentId], ...shipment};
        });
    },
    updateShipmentStatus: (shipmentId, status) => {
        set((state) => {
            // This is a workaround to update only the specified fields in the status object
            const defaultStatus = {
                packingInformationStatus: null,
                generateTransportationOptionsStatus: null,
                confirmTransportationOptionsStatus: null,
                transportationOptions: null,
                lastUpdate: null,
                packingInformationTimestamp: null,
                ...status,
            };
            if (defaultStatus.packingInformationStatus !== null) {
                state.shipments.byId[shipmentId].packingInformationStatus = status.packingInformationStatus;
            }

            if (defaultStatus.generateTransportationOptionsStatus !== null) {
                state.shipments.byId[shipmentId].generateTransportationOptionsStatus = status.generateTransportationOptionsStatus;
            }

            if (defaultStatus.confirmTransportationOptionsStatus !== null) {
                state.shipments.byId[shipmentId].confirmTransportationOptionsStatus = status.confirmTransportationOptionsStatus;
            }

            if (defaultStatus.lastUpdate !== null) {
                state.shipments.byId[shipmentId].lastUpdate = status.lastUpdate;
            }

            if (defaultStatus.transportationOptions !== null) {
                state.shipments.byId[shipmentId].transportationOptions = status.transportationOptions;
            }

            if (defaultStatus.packingInformationTimestamp !== null) {
                state.shipments.byId[shipmentId].packingInformationTimestamp = status.packingInformationTimestamp;
            }
        });
    },
    scanItem: (code) => {
        const shipments = get().shipments;
        const boxingState = get().state;

        const searchType = getSearchType(code);
        if (!searchType || searchType === "LPN") {
            throw new Error("The code is not recognized");
        }

        const searchTypeMap: {[key: string]: keyof InboundShipmentItemV2} = {
            UPC: "upc",
            FNSKU: "fnsku",
            ASIN: "asin",
        };
        const key = searchTypeMap[searchType];

        if (boxingState.selectedShipmentId) {
            const shipment = shipments.byId[boxingState.selectedShipmentId];
            const item = shipment.expectedItems.find((i) => i[key] === code);
            if (item && shipment.activePallet) {
                get().addItem(item, 1, shipment.activePallet!);
            } else {
                throw new Error("Item not found in the shipment");
            }
        } else {
            // Find all shipments that contain the item
            const shipmentsWithItem = Object.values(shipments.byId).filter((shipment) =>
                shipment.expectedItems.find((i) => i[key] === code)
            );

            if (shipmentsWithItem.length === 0) {
                throw new Error("Item not found in any shipment");
            }

            // Get the first item from shipments
            const item = shipmentsWithItem[0].expectedItems.find((i) => i[key] === code);
            if (item) {
                get().setState({scannedItem: item, currentShipmentIds: shipmentsWithItem.map((s) => s.shipmentId)});
            }
        }
    },
    duplicateBox: (boxId) => {
        const box = get().boxes.byId[boxId];
        if (!box.palletId || !box.shipmentId) {
            throw new Error(WRONG_FLOW_ERROR);
        }
        const pallet = get().pallets.byId[box.palletId];
        const boxes = Object.values(get().boxes.byId).filter((b) => b.shipmentId === box.shipmentId) as InboundBoxV2[];

        // Count items by SKU in all boxes and the new box
        const skus = countItemsByKey([...boxes, box]);

        // Find all items that exceed the limit
        const items = box.items.filter((i) => skus[i.msku] > i.quantity);

        if (items.length > 0) {
            notification.error({
                message: "Cannot duplicate box",
                description: `Some items exceed the shipped quantity: ${items.map((i) => i.fnsku).join(", ")}`,
            });
            return;
        }

        const newBoxId = get().addBox(pallet.id, false);
        set((state) => {
            const newBox = state.boxes.byId[newBoxId];
            newBox.items = box.items.map((i) => ({...i}));
            if (box.dimensions) {
                newBox.dimensions = {...box.dimensions};
            }
        });
        get().saveData({boxIds: [newBoxId], palletIds: [pallet.id]});
        return newBoxId;
    },
});
