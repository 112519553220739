import React from "react";
import {Modal, Table, Skeleton, Avatar, Typography} from "antd";
import {InboundShipmentItem, InboundShipmentItemV2} from "../../../../types/WarehouseTypes";
import PrintButton from "../../common/PrintButton";
import {ColumnsType} from "antd/lib/table";

interface ItemsToPrintDialogProps {
    items: InboundShipmentItemV2[];
    open: boolean;
    onCancel: () => void;
    onPrint: (item: InboundShipmentItemV2, quantity: number) => void;
}

const ItemsToPrintDialog: React.FC<ItemsToPrintDialogProps> = ({items, open, onCancel, onPrint}) => {
    const columns: ColumnsType<InboundShipmentItemV2> = [
        {
            title: "Image",
            dataIndex: "imgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "150px",
            render: (name) => (
                <Typography.Text ellipsis style={{width: "150px"}}>
                    {name}
                </Typography.Text>
            ),
        },
        {
            title: "UPC",
            dataIndex: "upc",
            key: "upc",
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
        },
        {
            title: "SKU",
            dataIndex: "msku",
            key: "sku",
            width: "150px",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Action",
            key: "action",
            render: (item: InboundShipmentItemV2) => (
                <PrintButton
                    onPrintLabel={(quantity) => onPrint(item, quantity)}
                    printedQuantity={item.printedQuantity}
                    maxQuantity={item.quantity}
                />
            ),
        },
    ];

    return (
        <Modal title="Items to Print" open={open} onCancel={onCancel} footer={null} width={"80vw"}>
            <Table
                style={{height: "50vh", overflow: "auto"}}
                dataSource={items}
                columns={columns}
                rowKey="FulfillmentNetworkSKU"
                pagination={false}
            />
        </Modal>
    );
};

export default ItemsToPrintDialog;
