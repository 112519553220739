import {notification} from "antd";
import {AppState, ImmerStateCreator, ReturnsSlice} from "../../types/storeTypes";
import {makeCall} from "../../services/common";
import {ReturnProduct} from "../../types/WarehouseTypes";
import {fetchProductsData} from "../../components/warehouse/common/helpers";
import {
    CONDITION_MAPPING,
    createOffer,
    createOrReplaceInventoryItem,
    getCategorySuggestions,
    getItemAspectsForCategory,
    getItemConditionPolicies,
    getOffers,
    publishOffer,
    updateOffer,
} from "../../services/EBayService";
import {uploadPhotos} from "../../services/WarehouseService";
import {EbayOfferDetailsWithKeys, InventoryItem} from "ebay-api/lib/types";

const initialReturnsData = {
    code: undefined,
    expectedProducts: [],
    extractedItems: [],
    selectedItem: undefined,
    isLoading: false,
    isScanning: false,
    isEBayFormLoading: false,
};

/**
 * Makes sure that the tracking number is not too long. Typically tracking numbers
 * have 18 characters, but USPS barcodes encode over 30 digits in which only last 22
 * are the tracking number.
 * @param {string} trackingNumber
 */
function parseTrackingNumber(trackingNumber: string) {
    return trackingNumber.slice(-22);
}

export const createReturnsSlice: ImmerStateCreator<AppState, ReturnsSlice> = (set, get) => ({
    returnsData: initialReturnsData,
    setCode: (code: string | undefined) => {
        set((state) => {
            state.returnsData.code = code;
        });
    },
    setIsScanning: (isScanning: boolean) => {
        set((state) => {
            state.returnsData.isScanning = isScanning;
        });
    },
    setSelectedItem: (item: ReturnProduct | undefined) => {
        set((state) => {
            state.returnsData.selectedItem = item;
        });
    },
    setEBayItem: (item: ReturnProduct | undefined) => {
        set((state) => {
            state.returnsData.eBayItem = item;
        });
    },
    getExpectedProducts: async (trackingNumber: string) => {
        trackingNumber = parseTrackingNumber(trackingNumber);
        if (!trackingNumber) {
            notification.error({
                message: "Error",
                description: "Please enter a tracking number",
            });
            return;
        }

        set((state) => {
            state.returnsData.isLoading = true;
        });

        try {
            // First, call the 'getProducts' endpoint
            const productsResponse = await makeCall("returns_v2/getProducts", {trackingNumber}, "GET");
            const products = productsResponse.result as ReturnProduct[];

            // Then, call the 'getExpectedProducts' endpoint
            const expectedResponse = await makeCall("returns_v2/getExpectedProducts", {trackingNumber}, "GET");
            const expectedProducts = expectedResponse.result as ReturnProduct[];

            // Merge the products with expected products by SKU
            const mergedProducts = expectedProducts.map((expected) => {
                const match = products.find((product) => product.sku === expected.sku);
                return match ? {...expected, ...match} : expected;
            });

            if (mergedProducts.length === 0) {
                throw new Error("No products found for this tracking number");
            }

            set((state) => {
                state.returnsData.expectedProducts = mergedProducts;
                state.returnsData.isLoading = false;
                state.returnsData.code = undefined;
            });
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
            set((state) => {
                state.returnsData.isLoading = false;
            });
        }
    },
    scanReturnedProduct: async (code: string) => {
        set((state) => {
            state.returnsData.isScanning = true;
            state.returnsData.code = code;
        });
        get().resetEBayData();

        try {
            // Call the searchCustomerReturns endpoint
            const response = await makeCall("returns_v2/searchCustomerReturns", {lpn: code}, "GET");
            const lpnData = response.result[0];

            if (!lpnData) {
                throw new Error("Product not found in reports");
            }

            lpnData["customer-order-id"] = lpnData["order-id"];
            delete lpnData["order-id"];

            set((state) => {
                // Find and update the matching expected product
                const index = state.returnsData.expectedProducts.findIndex((p) => p.sku === lpnData.sku);
                if (index !== -1) {
                    state.returnsData.expectedProducts[index] = {
                        ...state.returnsData.expectedProducts[index],
                        ...lpnData,
                        scanned: true,
                    };
                }
            });

            // Fetch additional product data using the ASIN
            if (lpnData.asin) {
                const productData = await fetchProductsData(lpnData.asin, "ASIN");
                set((state) => {
                    const matchingProduct = state.returnsData.expectedProducts.find((p) => p.asin === lpnData.asin && !p.finished);
                    if (matchingProduct) {
                        state.returnsData.extractedItems = productData.map((item) => ({
                            ...item,
                            ...matchingProduct,
                        }));
                    } else {
                        state.returnsData.extractedItems = [];
                    }
                });
            }
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
            set((state) => {
                state.returnsData.code = undefined;
            });
        } finally {
            set((state) => {
                state.returnsData.isScanning = false;
            });
        }
    },
    resetReturnsData: () => {
        set((state) => {
            state.returnsData = initialReturnsData;
        });
    },
    resetEBayData: () => {
        set((state) => {
            state.returnsData.eBayItem = undefined;
            state.returnsData.categorySuggestions = undefined;
            state.returnsData.policyConditions = undefined;
            state.returnsData.productAspects = undefined;
            state.returnsData.chosenImages = undefined;
            state.returnsData.eBayOfferProgress = undefined;
            state.returnsData.eBayOfferError = undefined;
            state.returnsData.listingId = undefined;
        });
    },
    setExtractedItems: (items: ReturnProduct[]) => {
        set((state) => {
            state.returnsData.extractedItems = items;
        });
    },
    saveReturnedProduct: async (product: ReturnProduct) => {
        try {
            const productToSave: ReturnProduct = {...product, finished: true, scanDate: new Date().toISOString()};

            let index = get().returnsData.expectedProducts.findIndex(
                (p) => p.sku === productToSave.sku && p["license-plate-number"] === productToSave["license-plate-number"]
            );
            if (index === -1) {
                index = get().returnsData.expectedProducts.findIndex((p) => p.sku === productToSave.sku);
            }

            if (index === -1) {
                throw new Error("Not found in expected products");
            }

            set((state) => {
                state.returnsData.expectedProducts[index] = productToSave;
            });

            await makeCall("returns_v2/addProducts", {}, "POST", {products: [productToSave]});
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        }
    },
    processWithoutLPN: async (sku: string, index?: number) => {
        try {
            set((state) => {
                state.returnsData.isScanning = true;
            });
            get().resetEBayData();
            let foundIndex = index;
            if (foundIndex === undefined) {
                foundIndex = get().returnsData.expectedProducts.findIndex((p) => p.sku === sku && !p.finished);
            }
            if (foundIndex === -1) {
                throw new Error("Not found in expected products");
            }

            let product = get().returnsData.expectedProducts[foundIndex];
            const lpn = `NO-LPN-${product["order-id"]}-${product.fnsku}-${foundIndex}`;

            product = {
                ...product,
                "license-plate-number": lpn,
                "scanDate": new Date().toISOString(),
            };
            set((state) => {
                // For some reason Typescript doesn't recognize that 'foundIndex' is defined
                state.returnsData.expectedProducts[foundIndex || 0] = product;
            });

            if (product.asin) {
                const productData = await fetchProductsData(product.asin, "ASIN");
                set((state) => {
                    if (product) {
                        state.returnsData.extractedItems = productData.map((item) => ({
                            ...item,
                            ...product,
                        }));
                    } else {
                        state.returnsData.extractedItems = [];
                    }
                });
            }
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
        }
        set((state) => {
            state.returnsData.isScanning = false;
        });
    },
    getCategorySuggestions: async (query: string) => {
        set((state) => {
            state.returnsData.categorySuggestions = undefined;
            state.returnsData.isEBayFormLoading = true;
            state.returnsData.productAspects = undefined;
        });
        const response = await getCategorySuggestions(query);

        set((state) => {
            state.returnsData.categorySuggestions = response.categorySuggestions;
            state.returnsData.isEBayFormLoading = false;
        });
    },
    getRequiredEBayDetails: async (categoryId: string) => {
        set((state) => {
            state.returnsData.policyConditions = undefined;
            state.returnsData.productAspects = undefined;
            state.returnsData.isEBayFormLoading = true;
        });
        const conditions = await getItemConditionPolicies(categoryId);
        const aspects = await getItemAspectsForCategory("0", categoryId);

        // Find condition policy with the correct categoryId
        const condition = conditions.find((c) => c.categoryId === categoryId);

        set((state) => {
            state.returnsData.policyConditions = condition;
            state.returnsData.productAspects = aspects;
            state.returnsData.isEBayFormLoading = false;
        });
    },
    setEBayAspects: (aspects) => {
        set((state) => {
            state.returnsData.productAspects = aspects;
        });
    },
    setChosenImages: (images) => {
        set((state) => {
            state.returnsData.chosenImages = images;
        });
    },
    createOffer: async (item: ReturnProduct) => {
        try {
            set((state) => {
                state.returnsData.isEBayFormLoading = true;
                state.returnsData.eBayOfferProgress = 0;
                state.returnsData.eBayOfferError = undefined;
            });
            const returnsData = get().returnsData;

            if (!returnsData.chosenImages || returnsData.chosenImages.length === 0) {
                throw new Error("Please upload photos");
            }

            // saveReturnedProduct({...returnsData.selectedItem, photos: uploadedUrls});

            const sku = `${item.sku}_${item.eBayCondition?.toLowerCase()}`;
            const uploadedIds = await uploadPhotos(returnsData.chosenImages, "ebay_test");

            set((state) => {
                state.returnsData.eBayOfferProgress = 20;
            });
            // const uploadedIds = ["test"];

            const urls = uploadedIds.map((id) => `https://res.cloudinary.com/mooregroup/image/upload/${id}.png`);

            // Make sure that all fields in aspects are arrays
            const aspects = item.eBayAspects ? {...item.eBayAspects} : {};
            for (const key in aspects) {
                if (aspects[key] && !Array.isArray(aspects[key])) {
                    aspects[key] = [aspects[key]];
                }
            }

            const itemData: InventoryItem = {
                availability: {
                    shipToLocationAvailability: {
                        quantity: item.eBayQuantity,
                    },
                },
                condition: CONDITION_MAPPING[item.eBayCondition || ""],
                product: {
                    title: item.name,
                    description: item.description,
                    upc: item.upc ? [item.upc] : undefined,
                    ean: item.ean ? [item.ean] : undefined,
                    brand: item.brand,
                    imageUrls: urls,
                    aspects: aspects,
                    mpn: item.partNumber,
                },
                packageWeightAndSize: {
                    dimensions: {
                        height: item.dimensions?.height,
                        length: item.dimensions?.length,
                        width: item.dimensions?.width,
                        unit: "INCH",
                    },
                    weight: {
                        value: item.dimensions?.weight,
                        unit: item.dimensions?.weightUnits === "ounces" ? "OUNCE" : "POUND",
                    },
                },
            };
            await createOrReplaceInventoryItem(sku, itemData);
            set((state) => {
                state.returnsData.eBayOfferProgress = 40;
            });

            // Check if the offer already exists
            let existingOffer: any;
            try {
                const offers = await getOffers(sku);
                existingOffer = offers.find((offer) => offer.format === "FIXED_PRICE");
            } catch (error) {}

            set((state) => {
                state.returnsData.eBayOfferProgress = 60;
            });

            const offerData: EbayOfferDetailsWithKeys = {
                sku,
                marketplaceId: "EBAY_US",
                format: "FIXED_PRICE",
                availableQuantity: item.eBayQuantity,
                categoryId: item.eBayCategory?.categoryId,
                listingDescription: item.description,
                listingPolicies: {
                    paymentPolicyId: "240783316013",
                    returnPolicyId: "240783319013",
                    fulfillmentPolicyId: "240766292013",
                },
                merchantLocationKey: "chestnut_warehouse",
                listingStartDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // 1 week in the future
                pricingSummary: {
                    price: {
                        currency: "USD",
                        value: item.price?.toString(),
                    },
                },
            };

            let offerId: string;
            if (existingOffer) {
                offerId = existingOffer.offerId;
                await updateOffer(offerId, offerData);
            } else {
                const createOfferResult = await createOffer(offerData);
                offerId = createOfferResult.offerId;
            }

            set((state) => {
                state.returnsData.eBayOfferProgress = 80;
            });

            const publishOfferRes = await publishOffer(offerId);
            const listingId = publishOfferRes.listingId;

            set((state) => {
                state.returnsData.eBayOfferProgress = 90;
                state.returnsData.listingId = listingId;
            });

            await get().saveReturnedProduct({...item, photos: uploadedIds, eBayOfferId: offerId});

            set((state) => {
                state.returnsData.eBayOfferProgress = 100;
            });

            notification.success({
                message: "Offer created",
                // description: result,
            });
        } catch (error: any) {
            notification.error({
                message: "Error",
                description: error.message,
            });
            set((state) => {
                state.returnsData.eBayOfferError = error.message;
            });
        } finally {
            set((state) => {
                state.returnsData.isEBayFormLoading = false;
            });
        }
    },
});
