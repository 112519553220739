import {Avatar, Button, InputNumber, InputRef, Popconfirm, Skeleton, Space, Table, Typography, Tooltip, Select} from "antd";
import {ColumnsType} from "antd/lib/table";
import React, {useRef} from "react";
import {WarehouseProduct} from "../../../types/WarehouseTypes";
import {DeleteOutlined} from "@ant-design/icons";
import {getDefaultFilterSorter} from "../../utilities/TableFilterSorters";
import {LabelOwner, PrepOwner} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import PrintButton from "./PrintButton";

interface BatchTableProps {
    items: WarehouseProduct[];
    onItemDelete?: (item: WarehouseProduct) => void;
    onItemQuantityChange?: (item: WarehouseProduct, quantity: number) => void;
    onMoveItem?: (item: WarehouseProduct) => void;
    onPrintLabels?: (item: WarehouseProduct, quantity: number) => void;
    onInvSelect?: (item: WarehouseProduct) => void;
    title?: (data: readonly WarehouseProduct[]) => React.ReactNode;
    onPrepOwnerChange?: (asin: string, prepOwner: PrepOwner) => void;
    onLabelOwnerChange?: (asin: string, labelOwner: LabelOwner) => void;
}

const BatchTable: React.FC<BatchTableProps> = ({
    items,
    onItemDelete,
    onItemQuantityChange,
    title,
    onMoveItem,
    onPrintLabels,
    onInvSelect,
    onPrepOwnerChange,
    onLabelOwnerChange,
}) => {
    const searchInputRef = useRef<InputRef>(null);
    const columns: ColumnsType<WarehouseProduct> = [
        {
            title: "Image",
            dataIndex: "imgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={55} shape="square" />),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name) => (
                <Tooltip title={name}>
                    <Typography.Paragraph style={{padding: 0, margin: 0, width: 200}} ellipsis={{rows: 4}}>
                        {name}
                    </Typography.Paragraph>
                </Tooltip>
            ),
            ...getDefaultFilterSorter("name", searchInputRef, true),
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
            ...getDefaultFilterSorter("asin", searchInputRef, true),
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku",
            width: 80,
            ...getDefaultFilterSorter("sku", searchInputRef, true),
            onCell: (data) => {
                if (!data.sku) {
                    let style = {
                        backgroundColor: `rgba(255, 0, 0, 0.5)`,
                    };
                    return {style};
                } else {
                    return {};
                }
            },
            render: (sku) => (
                <Typography.Paragraph copyable style={{width: 140}}>
                    {sku}
                </Typography.Paragraph>
            ),
        },
        {
            title: "FNSKU",
            dataIndex: "fnsku",
            key: "fnsku",
            ...getDefaultFilterSorter("fnsku", searchInputRef, true),
        },
        {
            title: "UPC",
            dataIndex: "upc",
            key: "upc",
            ...getDefaultFilterSorter("upc", searchInputRef, true),
        },
        {
            title: "Inv Qty",
            dataIndex: "invQuantity",
            key: "invQuantity",
        },
        {
            title: "Condition",
            dataIndex: "condition",
            key: "condition",
        },
    ];

    if (onPrepOwnerChange) {
        columns.push({
            title: "Prep Owner",
            dataIndex: "prepOwner",
            key: "prepOwner",
            fixed: "right",
            render: (text, record) => (
                <Select
                    style={{width: 85}}
                    value={record.prepOwner}
                    onChange={(newPrepOwner) => onPrepOwnerChange(record.asin, newPrepOwner)}
                    options={Object.values(PrepOwner).map((prepOwner) => ({
                        value: prepOwner,
                        label: <span>{prepOwner}</span>,
                    }))}
                />
            ),
        });
    } else {
        columns.push({
            title: "Prep Owner",
            dataIndex: "prepOwner",
            key: "prepOwner",
            fixed: "right",
        });
    }

    if (onLabelOwnerChange) {
        columns.push({
            title: "Label Owner",
            dataIndex: "labelOwner",
            key: "labelOwner",
            fixed: "right",
            render: (text, record) => (
                <Select
                    style={{width: 85}}
                    value={record.labelOwner}
                    onChange={(newLabelOwner) => onLabelOwnerChange(record.asin, newLabelOwner)}
                    options={Object.values(LabelOwner).map((labelOwner) => ({
                        value: labelOwner,
                        label: <span>{labelOwner}</span>,
                    }))}
                />
            ),
        });
    } else {
        columns.push({
            title: "Label Owner",
            dataIndex: "labelOwner",
            key: "labelOwner",
            fixed: "right",
        });
    }

    if (onItemQuantityChange) {
        columns.push({
            title: "Qty",
            dataIndex: "quantity",
            key: "quantity",
            fixed: "right",
            render: (text, record) => (
                <InputNumber
                    min={1}
                    value={record.quantity}
                    onChange={(newQuantity) => onItemQuantityChange(record, newQuantity as number)}
                    style={{width: 70}}
                />
            ),
        });
    } else {
        columns.push({
            title: "Qty",
            dataIndex: "quantity",
            fixed: "right",
            key: "quantity",
        });
    }

    if (onPrintLabels) {
        columns.push({
            title: "Print",
            key: "action",
            width: 120,
            fixed: "right",
            render: (item: WarehouseProduct) => <PrintButton onPrintLabel={(quantity) => onPrintLabels(item, quantity)} />,
        });
    }

    if (onItemDelete) {
        columns.push({
            title: "Action",
            key: "action",
            fixed: "right",
            render: (text, record) => (
                <Space>
                    <Popconfirm
                        title="Are you sure you want to delete this item?"
                        onConfirm={() => onItemDelete(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                    {onMoveItem && <Button onClick={() => onMoveItem(record)}>Move</Button>}
                    {onInvSelect && (
                        <Button disabled={!record.invData} onClick={() => onInvSelect(record)}>
                            Inv
                        </Button>
                    )}
                </Space>
            ),
        });
    }

    return (
        <Table
            rowKey={(p) => p.asin + p.sku}
            columns={columns}
            dataSource={items}
            title={() => (title ? title(items) : undefined)}
            scroll={{x: "max-content"}}
        />
    );
};

export default BatchTable;
