import {notification} from "antd";
import {AppState, PackLaterBoxingActionsSlice, ImmerStateCreator} from "../../../types/storeTypes";
import {
    confirmTransportationOptions,
    generateTransportationOptions,
    getBillOfLading,
    getLabels,
    getShipment,
    listTransportationOptions,
    setShipmentPackingInformation,
    waitForOperation,
} from "../../../services/SPApiService";
import {
    ContactInformation,
    FbaInboundApiGenerateTransportationOptionsRequest,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {countItemsByKey} from "../../../components/warehouse/pack-later/boxing/helpers";

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const devMode = false;

export const createBoxingActionsSlice: ImmerStateCreator<AppState, PackLaterBoxingActionsSlice> = (set, get) => ({
    submitShipment: async (shipmentId: string) => {
        const state = get();

        state.addLoadingShipment(shipmentId, "Submitting shipment");

        const shipment = state.shipments.byId[shipmentId];
        const shipmentBoxes = Object.values(state.boxes.byId).filter((box) => box.shipmentId === shipmentId);
        const shipmentPallets = Object.values(state.pallets.byId).filter((pallet) => pallet.shipmentId === shipmentId);

        try {
            // Check if all boxes have dimensions
            const boxesWithoutDimensions = shipmentBoxes.filter((box) => !box.dimensions);
            if (boxesWithoutDimensions.length > 0) {
                throw new Error("All boxes must have dimensions before submitting the shipment");
            }

            // Check if all boxes have items
            const boxesWithoutItems = shipmentBoxes.filter((box) => box.items.length === 0);
            if (boxesWithoutItems.length > 0) {
                throw new Error("All boxes must have items before submitting the shipment");
            }
            // Check if all pallets have dimensions
            const palletsWithoutDimensions = shipmentPallets.filter((pallet) => !pallet.dimensions);
            if (shipment.shipmentType === "LTL" && palletsWithoutDimensions.length > 0) {
                throw new Error("All pallets must have dimensions before submitting the shipment");
            }

            // Check if all items have been assigned to a box
            const boxed = countItemsByKey(shipmentBoxes);
            const remainingItems = shipment.expectedItems
                .map((item) => ({
                    ...item,
                    quantityLeft: item.quantity - (boxed[item.msku] || 0),
                }))
                .filter((item) => item.quantityLeft > 0);
            if (remainingItems.length > 0) {
                throw new Error("All items must be assigned to a box before submitting the shipment");
            }

            state.updateShipmentStatus(shipmentId, {
                confirmTransportationOptionsStatus: undefined,
                generateTransportationOptionsStatus: undefined,
                packingInformationStatus: undefined,
                packingInformationTimestamp: new Date().getTime(),
            });

            const packingRes = await setShipmentPackingInformation(shipment.inboundPlanId, shipment, shipmentBoxes, devMode);
            const waitRes = await waitForOperation(packingRes.operationId, 2000, devMode);

            state.updateShipmentStatus(shipment.shipmentId, {packingInformationStatus: waitRes});
            state.saveData({shipmentIds: [shipmentId]});

            if (waitRes.operationStatus === "SUCCESS") {
                notification.success({
                    message: "Shipment submitted",
                    description: "The shipment has been submitted successfully",
                });
            } else {
                const problems = waitRes.operationProblems.map((problem) => problem.message).join(" | ");
                const message = problems ? `Failed: ${problems}` : "Failed to confirm placement";
                throw new Error(message);
            }
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
        }
        state.removeLoadingShipment(shipmentId);
    },
    getTransportOptions: async (shipmentId: string, details: FbaInboundApiGenerateTransportationOptionsRequest) => {
        const shipment = get().shipments.byId[shipmentId];
        get().addLoadingShipment(shipmentId, "Generating transportation options");
        try {
            const genRes = await generateTransportationOptions(details, devMode);
            const waitRes = await waitForOperation(genRes.operationId, 2000, devMode);
            get().updateShipmentStatus(shipmentId, {generateTransportationOptionsStatus: waitRes});

            if (waitRes.operationStatus === "FAILED") {
                throw new Error(waitRes.operationProblems.map((problem) => problem.message).join(" | "));
            }

            get().addLoadingShipment(shipmentId, "Fetching transport options");

            const transportationOptions = await listTransportationOptions(shipment.inboundPlanId, shipmentId);
            const updatedShipment = await getShipment(shipment.inboundPlanId, shipmentId);
            get().updateShipment(shipmentId, {
                ...shipment,
                ...updatedShipment,
                transportationOptions,
                generateTransportationOptionsStatus: waitRes,
            });

            notification.success({
                message: "Transport options generated",
                description: "The transport options have been generated successfully",
            });
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
        }
        get().saveData({shipmentIds: [shipmentId]});
        get().removeLoadingShipment(shipmentId);
    },
    confirmTransportationOptions: async (shipmentId: string, transportationOptionId: string) => {
        const shipment = get().shipments.byId[shipmentId];
        const contact = get().warehouseContactInfo;
        get().addLoadingShipment(shipmentId, "Confirming transportation options");

        let contactInformation: ContactInformation | undefined;
        if (contact) {
            contactInformation = {
                name: contact.name,
                phoneNumber: contact.phone,
                email: contact.email,
            };
        }
        try {
            const res = await confirmTransportationOptions(
                {
                    inboundPlanId: shipment.inboundPlanId,
                    body: {
                        transportationSelections: [
                            {
                                shipmentId,
                                transportationOptionId,
                                contactInformation,
                            },
                        ],
                    },
                },
                devMode
            );
            const waitRes = await waitForOperation(res.operationId, 2000, devMode);
            get().updateShipmentStatus(shipmentId, {confirmTransportationOptionsStatus: waitRes});

            if (waitRes.operationStatus === "FAILED") {
                throw new Error(waitRes.operationProblems.map((problem) => problem.message).join(" | "));
            }

            const updatedShipment = await getShipment(shipment.inboundPlanId, shipmentId);
            get().updateShipment(shipmentId, {...shipment, ...updatedShipment});
        } catch (e: any) {
            notification.error({
                message: "Error",
                description: e.message,
            });
        }
        get().saveData({shipmentIds: [shipmentId]});
        get().removeLoadingShipment(shipmentId);
    },
    getLabels: async (shipmentId: string) => {
        const state = get();
        const shipment = state.shipments.byId[shipmentId];
        const boxes = Object.values(state.boxes.byId).filter((box) => box.shipmentId === shipmentId);
        const pallets = Object.values(state.pallets.byId).filter((pallet) => pallet.shipmentId === shipmentId);
        state.addLoadingShipment(shipmentId, "Printing labels");

        try {
            await getLabels(
                shipment,
                boxes.map((box) => box.id),
                pallets.length
            );

            notification.success({
                message: "Labels downloaded",
            });
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
        }
        state.removeLoadingShipment(shipmentId);
    },
    getBillOfLading: async (shipmentId: string) => {
        const shipment = get().shipments.byId[shipmentId];
        get().addLoadingShipment(shipmentId, "Downloading bill of lading");
        try {
            await getBillOfLading(shipment.shipmentConfirmationId || "");
        } catch (err: any) {
            notification.error({
                message: "Error",
                description: err.message,
            });
        }
        get().removeLoadingShipment(shipmentId);
    },
    removeItemFromShipment: async (shipmentId: string, sku: string, quantity: number) => {
        // const state = get();
        // state.addLoadingShipment(shipmentId, "Removing item from shipment");
        // try {
        //     const newItems = await removeItemFromShipment(shipmentId, sku, quantity, false);
        //     set((s) => {
        //         s.shipments.byId[shipmentId].ExpectedItems = newItems;
        //     });
        //     notification.success({
        //         message: "Item removed",
        //         description: "The item has been removed from the shipment",
        //     });
        // } catch (err: any) {
        //     notification.error({
        //         message: "Error",
        //         description: err.message,
        //     });
        // }
        // state.removeLoadingShipment(shipmentId);
    },
});
