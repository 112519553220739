/* eslint-disable @typescript-eslint/no-unused-vars */
import {Col, Row, Tabs, TabsProps} from "antd";
import React, {useEffect, useState} from "react";
import {SupplierProvider} from "../contexts/SupplierContext";
import {useTabLocationDashboard} from "../components/dashboard/utilities/queryTab";
import WarehouseImport from "../components/warehouse/WarehouseImport";
import WarehouseLookup from "../components/warehouse/WarehouseLookup";
import WarehouseDashboard from "../components/warehouse/WarehouseDashboard";
import ReceivingDashboard from "../components/warehouse/common/ReceivingDashboard";
import {useAuth} from "../contexts/AuthContext";
import {default as PLShipmentPlanningDashboard} from "../components/warehouse/pack-later/receiving/ShipmentPlanningDashboard";
import LabelingDashboard from "../components/warehouse/pack-later/receiving/LabelingDashboard";
import {useQueryClient} from "@tanstack/react-query";
import {default as PLBoxingDashboard} from "../components/warehouse/pack-later/boxing/BoxingDashboard";
import WarehouseSettings from "../components/warehouse/WarehouseSettings";
import {SettingOutlined} from "@ant-design/icons";
import UPCMappingDashboard from "../components/warehouse/UPCMappingDashboard";
import ReturnsManagement from "../components/warehouse/returns/ReturnsManagement";
import {default as PFShipmentPlanningDashboard} from "../components/warehouse/pack-first/ShipmentPlanningDashboard";
import {default as PFBoxingDashboard} from "../components/warehouse/pack-first/BoxingDashboard";

const packLaterItems: TabsProps["items"] = [
    {
        key: "pack_later_shipment_planning",
        label: `Shipment Planning`,
        children: <PLShipmentPlanningDashboard />,
    },
    {
        key: "labeling",
        label: "Labeling",
        children: <LabelingDashboard />,
    },
    {
        key: "pack_later_boxing",
        label: "Boxing",
        children: <PLBoxingDashboard />,
    },
];

const packFirstItems: TabsProps["items"] = [
    {
        key: "pack_first_shipment_planning",
        label: `Shipment Planning`,
        children: <PFShipmentPlanningDashboard />,
    },
    {
        key: "pack_first_boxing",
        label: "Boxing",
        children: <PFBoxingDashboard />,
    },
];

const globalIitems: TabsProps["items"] = [
    {
        key: "shiplater_inbound",
        label: `Add Stored Inventory`,
        children: <WarehouseImport></WarehouseImport>,
    },
    {
        key: "shiplater_find",
        label: `Find Stored Inventory`,
        children: <WarehouseLookup></WarehouseLookup>,
    },
    {
        key: "upc_mapping",
        label: "UPC Mapping",
        children: <UPCMappingDashboard></UPCMappingDashboard>,
    },
    {
        key: "receiving",
        label: `Receiving Dashboard`,
        children: <ReceivingDashboard />,
    },
    {
        key: "pack_first",
        label: "Pack First (LTL & SPD)",
        children: <Tabs items={packFirstItems} />,
    },
    {
        key: "pack_later",
        label: "Pack Later (only LTL)",
        children: <Tabs items={packLaterItems} />,
    },
    {
        key: "returns_management",
        label: "Returns",
        children: <ReturnsManagement />,
    },
    {
        key: "settings",
        children: <WarehouseSettings />,
        label: "Settings",
        icon: <SettingOutlined />,
    },
];

const adminItems: TabsProps["items"] = [
    {
        key: "dashboard",
        label: `Dashboard`,
        children: <WarehouseDashboard></WarehouseDashboard>,
    },
    ...globalIitems,
];

const Warehouse: React.FC = () => {
    const {currentUser} = useAuth();
    const [currentItems, setCurrentItems] = useState<TabsProps["items"]>(adminItems);
    const [activeKey, onChange] = useTabLocationDashboard(
        adminItems.map((item) => item.key),
        "shiplater_inbound",
        "warehouse_tab"
    );
    const queryClient = useQueryClient();

    useEffect(() => {
        if (activeKey === "pack_first" || activeKey === "pack_later") {
            queryClient.refetchQueries({
                queryKey: ["shipment_planning_batches", currentUser?.uid],
            });
        }
    }, [queryClient, activeKey, currentUser?.uid]);

    // useEffect(() => {
    //     if (userData?.role.includes('MANAGER') || userData?.role === 'ADMIN') {
    //         setCurrentItems(adminItems)
    //     }
    // }, [userData])

    return (
        <SupplierProvider>
            <Row>
                <Col span={24}>
                    <Tabs activeKey={activeKey} items={currentItems} onChange={onChange} destroyInactiveTabPane={false}></Tabs>
                </Col>
            </Row>
        </SupplierProvider>
    );
};

export default Warehouse;
