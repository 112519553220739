import {Button, Col, Form, Input, Row, Space, Tabs, TabsProps, Typography, notification} from "antd";
import React, {useEffect, useState} from "react";
import {WarehouseAddress, WarehouseContact} from "../../types/WarehouseTypes";
import {useStore} from "../../store/useStore";
import {FormProps} from "antd/lib";
import {Rule} from "antd/es/form";

const COMMON_RULES: Rule[] = [
    {required: true, message: "Please enter the value"},
    {pattern: /^\S.*\S$|^.$/, message: "Remove all trailing and leading spaces"},
];

const WarehouseAddressTab: React.FC = () => {
    const [form] = Form.useForm();
    const [isSaving, setIsSaving] = useState(false);
    const {warehouseAddress, saveWarehouseAddress} = useStore(({warehouseAddress, saveWarehouseAddress}) => ({
        warehouseAddress,
        saveWarehouseAddress,
    }));

    useEffect(() => {
        form.setFieldsValue(warehouseAddress);
    }, [warehouseAddress, form]);

    const onFinish: FormProps<WarehouseAddress>["onFinish"] = async (values) => {
        setIsSaving(true);
        try {
            await saveWarehouseAddress(values);
            notification.success({message: "Address saved"});
        } catch (e: any) {
            notification.error({message: "Error saving address", description: e.message});
        }
        setIsSaving(false);
    };

    return (
        <Form
            form={form}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: "60%"}}
            name="warehouseAddress"
            onFinish={onFinish}
        >
            <Form.Item label="Name" name="name" rules={COMMON_RULES}>
                <Input />
            </Form.Item>

            <Form.Item label="Company Name" name="companyName">
                <Input />
            </Form.Item>
            <Form.Item label="Phone Number" name="phoneNumber" rules={COMMON_RULES}>
                <Input />
            </Form.Item>
            <Form.Item label="Address Line 1" name="addressLine1" rules={COMMON_RULES}>
                <Input />
            </Form.Item>

            <Form.Item label="Address Line 2" name="addressLine2">
                <Input />
            </Form.Item>

            <Form.Item label="City" name="city" rules={COMMON_RULES}>
                <Input />
            </Form.Item>

            <Form.Item label="State or Province Code" name="stateOrProvinceCode" rules={COMMON_RULES}>
                <Input />
            </Form.Item>

            <Form.Item label="Postal Code" name="postalCode" rules={COMMON_RULES}>
                <Input />
            </Form.Item>

            <Form.Item label="Country Code" name="countryCode" rules={COMMON_RULES}>
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Space>
                    <Button loading={isSaving} type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button type="default" onClick={() => form.setFieldsValue(warehouseAddress)}>
                        Reset
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

const WarehouseContactInfoTab: React.FC = () => {
    const [form] = Form.useForm();
    const [isSaving, setIsSaving] = useState(false);
    const {warehouseContactInfo, saveWarehouseContactInfo} = useStore(({warehouseContactInfo, saveWarehouseContactInfo}) => ({
        warehouseContactInfo,
        saveWarehouseContactInfo,
    }));

    useEffect(() => {
        form.setFieldsValue(warehouseContactInfo);
    }, [warehouseContactInfo, form]);

    const onFinish: FormProps<WarehouseContact>["onFinish"] = async (values) => {
        setIsSaving(true);
        try {
            await saveWarehouseContactInfo(values);
            notification.success({message: "Contact information saved"});
        } catch (e: any) {
            notification.error({message: "Error saving address", description: e.message});
        }
        setIsSaving(false);
    };

    return (
        <Form
            form={form}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: "60%"}}
            name="warehouseAddress"
            onFinish={onFinish}
        >
            <Row>
                <Col offset={8}>
                    <Typography.Text>
                        Contact information for the person in the seller's organization who is responsible for a Less Than Truckload/Full
                        Truckload (LTL/FTL) shipment.
                    </Typography.Text>
                </Col>
            </Row>
            <Form.Item label="Name" name="name" rules={COMMON_RULES}>
                <Input />
            </Form.Item>
            <Form.Item label="Phone" name="phone" rules={COMMON_RULES}>
                <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={COMMON_RULES}>
                <Input />
            </Form.Item>
            <Form.Item label="Fax" name="fax">
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Space>
                    <Button loading={isSaving} type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button type="default" onClick={() => form.setFieldsValue(warehouseContactInfo)}>
                        Reset
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

const tabItems: TabsProps["items"] = [
    {
        key: "address",
        label: `Address`,
        children: <WarehouseAddressTab />,
    },
    {
        key: "contactInfo",
        label: "Contact Info",
        children: <WarehouseContactInfoTab />,
    },
];

const WarehouseSettings: React.FC = () => {
    return (
        <Space style={{width: "100%", justifyContent: "center"}} styles={{item: {width: "80%"}}} align="center">
            <Tabs items={tabItems} />
        </Space>
    );
};

export default WarehouseSettings;
