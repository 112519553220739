import React from "react";
import {Modal, Table, Skeleton, Avatar, Typography, InputNumber, Button} from "antd";
import {InboundBoxV2, InboundShipmentItemV2} from "../../../types/WarehouseTypes";
import {ColumnsType} from "antd/lib/table";
import {DeleteOutlined} from "@ant-design/icons";

interface EditBoxDialogProps {
    box: InboundBoxV2;
    open: boolean;
    onCancel: () => void;
    onQuantityChange: (sku: string, boxId: string, quantity: number) => void;
    onItemRemove: (sku: string, boxId: string) => void;
    title?: string;
}

const EditBoxDialog: React.FC<EditBoxDialogProps> = ({box, open, onCancel, onQuantityChange, onItemRemove, title}) => {
    const columns: ColumnsType<InboundShipmentItemV2> = [
        {
            title: "Image",
            dataIndex: "imgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "150px",
            render: (name) => (
                <Typography.Text ellipsis style={{width: "150px"}}>
                    {name}
                </Typography.Text>
            ),
        },
        {
            title: "UPC",
            dataIndex: "upc",
            key: "upc",
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
        },
        {
            title: "FNSKU",
            dataIndex: "fnsku",
            key: "fnsku",
        },
        {
            title: "SKU",
            dataIndex: "msku",
            key: "sku",
            width: "150px",
        },
        {
            title: "Quantity",
            key: "action",
            render: (item: InboundShipmentItemV2) => (
                <InputNumber
                    title="Quantity"
                    min={1}
                    max={(item.quantityLeft || 0) + (item.quantityInBox || 1)}
                    value={item.quantityInBox}
                    onChange={(value) => onQuantityChange(item.msku, box.id, value || 0)}
                />
            ),
        },
        {
            title: "Remove",
            key: "action",
            render: (item: InboundShipmentItemV2) => (
                <Button icon={<DeleteOutlined />} onClick={() => onItemRemove(item.msku, box.id)} type="text" />
            ),
        },
    ];

    return (
        <Modal title={title || `Box ${box.number} Items`} open={open} onCancel={onCancel} footer={null} width={"80vw"}>
            <Table style={{height: "50vh", overflow: "auto"}} dataSource={box.items} columns={columns} rowKey="msku" pagination={false} />
        </Modal>
    );
};

export default EditBoxDialog;
