import {Item} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {AppState, ImmerStateCreator, PackLaterReceivingSlice} from "../../../types/storeTypes";
import {confirmPlacementOption, getShipment, listShipmentItems, waitForOperation} from "../../../services/SPApiService";
import {notification} from "antd";
import {InboundShipmentItemV2, WarehouseProduct} from "../../../types/WarehouseTypes";
import {finishBatch, saveCreatedShipments, saveReceivedProducts} from "../../../services/WarehouseService";

export const createPackLaterReceivingSlice: ImmerStateCreator<AppState, PackLaterReceivingSlice> = (set, get) => ({
    packLaterReceivingData: {
        currentInboundPlanId: undefined,
        placementOptions: [],
        placementShipments: {},
        placementItems: {},
        confirmPlacementLoading: false,
        finishBatchLoading: false,
    },
    fetchShipments: async (inboundPlanId, placementOptionId, shipmentIds) => {
        try {
            const shipmentsLocal = {...get().packLaterReceivingData.placementShipments};
            shipmentsLocal[placementOptionId] = [];
            const itemsLocal: {[key: string]: Item[]} = {...get().packLaterReceivingData.placementItems};
            for (const shipmentId of shipmentIds) {
                const shipment = await getShipment(inboundPlanId, shipmentId);
                const items = await listShipmentItems(inboundPlanId, shipmentId);
                shipmentsLocal[placementOptionId].push(shipment);
                itemsLocal[shipmentId] = items;
            }
            set((state) => {
                state.packLaterReceivingData.placementShipments = shipmentsLocal;
                state.packLaterReceivingData.placementItems = itemsLocal;
            });
        } catch (e: any) {
            notification.error({
                message: "Error fetching shipments",
                description: e.message,
            });
        }
    },
    confirmPlacementOption: async (placementOption) => {
        const receivingData = get().packLaterReceivingData;
        try {
            if (!receivingData.currentInboundPlanId) {
                throw new Error("No inbound plan ID set");
            }
            set((state) => {
                state.packLaterReceivingData.confirmPlacementLoading = true;
            });
            const operationId = await confirmPlacementOption(receivingData.currentInboundPlanId, placementOption.placementOptionId, false);
            const waitRes = await waitForOperation(operationId, 2000, false);
            if (waitRes.operationStatus !== "SUCCESS") {
                const problems = waitRes.operationProblems.map((problem) => problem.message).join(" | ");
                const message = problems ? `Failed: ${problems}` : "Failed to confirm placement";
                throw new Error(message);
            }

            set((state) => {
                state.packLaterReceivingData.confirmedOption = placementOption;
                state.packLaterReceivingData.confirmPlacementLoading = false;
            });

            notification.success({
                message: "Placement confirmed",
                description: "Successfully confirmed placement",
            });
        } catch (e: any) {
            notification.error({
                message: "Error confirming placement",
                description: e.message,
            });
            set((state) => {
                state.packLaterReceivingData.confirmPlacementLoading = false;
            });
        }
    },
    packLaterFinishBatch: async (scannedItems, category) => {
        const receivingData = get().packLaterReceivingData;
        if (!receivingData.confirmedOption || !receivingData.currentInboundPlanId) {
            throw new Error("No placement option confirmed");
        }
        const placementOption = receivingData.confirmedOption;
        const shipments = receivingData.placementShipments[placementOption.placementOptionId] || [];
        const items: InboundShipmentItemV2[] = [];
        for (const shipment of shipments) {
            const shipmentItems = receivingData.placementItems[shipment.shipmentId] || [];
            items.push(...shipmentItems.map((item) => ({...item, shipmentId: shipment.shipmentId})));
        }

        await saveCreatedShipments(receivingData.currentInboundPlanId, shipments, items);

        // Save submitted quantities to make sure that nothing is lost
        const mergedItems: {[key: string]: number} = {};
        items.forEach((item) => {
            const {msku, quantity} = item;
            if (mergedItems[msku]) {
                mergedItems[msku] += quantity;
            } else {
                mergedItems[msku] = quantity;
            }
        });

        const mergedProducts: WarehouseProduct[] = scannedItems.map((product) => {
            const submittedQuantity = mergedItems[product.sku || ""] || 0;
            return {...product, submittedQuantity};
        });

        await saveReceivedProducts(
            mergedProducts
                .filter((product) => product.submittedQuantity && product.submittedQuantity > 0)
                .map(({asin, batchId, submittedQuantity}) => ({asin, batchId, submittedQuantity}))
        );
        // Finish batch
        const batch = await finishBatch(category);
        return batch;
    },
    setCurrentInboundPlanId: (inboundPlanId) => {
        set((state) => {
            state.packLaterReceivingData.currentInboundPlanId = inboundPlanId;
        });
    },
    setPlacementOptions: (options) => {
        set((state) => {
            state.packLaterReceivingData.placementOptions = options;
        });
    },
    setPlacementShipments: (shipments) => {
        set((state) => {
            state.packLaterReceivingData.placementShipments = shipments;
        });
    },
    setFinishBatchLoading: (loading) => {
        set((state) => {
            state.packLaterReceivingData.finishBatchLoading = loading;
        });
    },
    resetPackLaterReceivingData: () => {
        set((state) => {
            state.packLaterReceivingData.currentInboundPlanId = undefined;
            state.packLaterReceivingData.placementOptions = [];
            state.packLaterReceivingData.placementShipments = {};
            state.packLaterReceivingData.placementItems = {};
            state.packLaterReceivingData.confirmedOption = undefined;
        });
    },
});
