import {
    Button,
    Col,
    Descriptions,
    Divider,
    Dropdown,
    Modal,
    notification,
    Progress,
    Row,
    Space,
    Spin,
    Tabs,
    TabsProps,
    Typography,
} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {useStore} from "../../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import {InboundPallet, InboundShipmentV2} from "../../../../types/WarehouseTypes";
import {DeleteOutlined, DownOutlined} from "@ant-design/icons";
import PalletView from "./PalletView";
import {MenuProps} from "antd/lib";
import DimensionsDialog from "../../common/DimensionsDialog";
import {prepareTransportDetails} from "../../../../services/WarehouseService";
import ConfirmTransportDetailsDialog from "./ConfirmTransportDetailsDialog";
import {FbaInboundApiGenerateTransportationOptionsRequest} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import TransportationOptionsDialog from "./TransportationOptionsDialog";
import OperationStatusInfo from "./OperationStatusInfo";
import BoxingRemainingItemsDialog from "../../common/BoxingRemainingItemsDialog";
import {calcInboundProgress} from "../../common/helpers";

interface SingleShipmentViewProps {
    shipment?: InboundShipmentV2;
}

const SingleShipmentView: React.FC<SingleShipmentViewProps> = ({shipment}) => {
    const {palletsStore, boxes, loadingShipments} = useStore(
        useShallow((state) => ({
            palletsStore: state.pallets,
            boxes: state.boxes,
            loadingShipments: state.loadingShipments,
        }))
    );
    const boxingActions = useStore(
        useShallow((state) => ({
            addPallet: state.addPallet,
            removePallet: state.removePallet,
            addBox: state.addBox,
            addItem: state.addItem,
            setActivePallet: state.setActivePallet,
            submitShipment: state.submitShipment,
            getLabels: state.getLabels,
            getBillOfLading: state.getBillOfLading,
            addPalletDimensions: state.addPalletDimensions,
            addLoadingShipment: state.addLoadingShipment,
            removeLoadingShipment: state.removeLoadingShipment,
            removeItemFromShipment: state.removeItemFromShipment,
            getTransportOptions: state.getTransportOptions,
            confirmTransportationOptions: state.confirmTransportationOptions,
            updateShipment: state.updateShipment,
            saveData: state.saveData,
        }))
    );
    const [showRemainingItemsDialog, setShowRemainingItemsDialog] = useState(false);
    const [showTransportationOptionsDialog, setShowTransportationOptionsDialog] = useState(false);
    const [showDimensionsDialog, setShowDimensionsDialog] = useState(false);
    const [showTransportDetailsDialog, setShowTransportDetailsDialog] = useState(false);
    const [transportDetails, setTransportDetails] = useState<FbaInboundApiGenerateTransportationOptionsRequest | undefined>();
    const [modal, contextHolder] = Modal.useModal();

    const pallets: InboundPallet[] = useMemo(
        () => Object.values(palletsStore.byId).filter((p) => p.shipmentId === shipment?.shipmentId),
        [palletsStore, shipment]
    );

    useEffect(() => {
        // Make sure that the active tab is always a valid pallet
        if (shipment && !pallets.find((p) => p.id === shipment?.activePallet)) {
            boxingActions.setActivePallet(shipment.shipmentId, pallets[0].id);
        }
    }, [pallets, shipment, boxingActions]);

    const tabItems: TabsProps["items"] = useMemo(() => {
        const items: TabsProps["items"] = pallets.map((pallet) => ({
            key: pallet.id,
            label: `Pallet ${pallet.number}`,
            children: <PalletView pallet={pallet} />,
        }));
        return items;
    }, [pallets]);

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            const newId = boxingActions.addPallet(shipment!.shipmentId);
            boxingActions.setActivePallet(shipment!.shipmentId, newId);
        } else if (typeof targetKey === "string") {
            if (shipment?.palletsIds?.length === 1) {
                modal.warning({
                    title: "You can't remove the last pallet",
                });
                return;
            } else {
                modal.confirm({
                    title: "Do you want to remove this pallet?",
                    onOk: () => {
                        boxingActions.removePallet(targetKey);
                        boxingActions.setActivePallet(shipment!.shipmentId, pallets[0].id);
                    },
                });
            }
        }
    };

    const newActions: MenuProps["items"] = [
        {
            key: "getLabels",
            label: "Get labels",
            onClick: () => {
                boxingActions.getLabels(shipment!.shipmentId);
            },
        },
        {
            key: "getBillOfLading",
            label: "Get bill of lading",
            disabled: shipment?.shipmentType === "SP",
            onClick: () => {
                boxingActions.getBillOfLading(shipment!.shipmentId);
            },
        },
    ];
    const activePallet = palletsStore.byId[shipment?.activePallet || ""];

    const weight = activePallet?.dimensions?.weight.toString() || "--";
    const height = activePallet?.dimensions?.height.toString() || "--";
    const width = activePallet?.dimensions?.width.toString() || "--";
    const length = activePallet?.dimensions?.length.toString() || "--";

    const setPackingInformationTimestamp = shipment?.packingInformationTimestamp || 0;
    const lastUpdateTimestamp = shipment?.lastUpdate || 0;

    const shipmentBoxes = useMemo(() => {
        if (!shipment) return [];
        return Object.values(boxes.byId).filter((b) => b.shipmentId === shipment.shipmentId);
    }, [boxes.byId, shipment]);

    const progressPercent = useMemo(() => {
        if (!shipment) return 0;
        return calcInboundProgress(shipment.expectedItems, shipmentBoxes);
    }, [shipment, shipmentBoxes]);

    if (!shipment) {
        return <Typography.Title>First select a shipment</Typography.Title>;
    }

    const shipmentLoadingState = loadingShipments.find((s) => s.id === shipment.shipmentId);

    return (
        <Spin spinning={shipmentLoadingState !== undefined} tip={shipmentLoadingState?.message}>
            <Space direction="vertical" style={{width: "100%"}}>
                <Space direction="vertical" style={{width: "100%", alignItems: "center"}} styles={{item: {width: "100%"}}}>
                    <Row align="middle">
                        <Col span={6}>
                            <Space>
                                <Button disabled={progressPercent === 100} onClick={() => setShowRemainingItemsDialog(true)}>
                                    Items
                                </Button>
                                <Progress percent={progressPercent} size="small" strokeWidth={12} style={{width: 60}} />
                                {/* <Button onClick={() => boxingActions.checkTransport(shipment.shipmentId)} icon={<ReloadOutlined />} /> */}
                            </Space>
                        </Col>
                        <Col span={18}>
                            <Typography.Title level={4} style={{margin: 8}}>
                                Shipment: {shipment.name}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Descriptions
                        size="small"
                        column={2}
                        bordered
                        style={{width: "100%"}}
                        items={[
                            {
                                key: "shipment_status",
                                label: "Shipment Status",
                                children: <Space style={{width: 150}}>{shipment?.status || "---"}</Space>,
                            },
                            {
                                key: "packingInformationStatus",
                                label: "Packing Information",
                                children: <OperationStatusInfo operationStatus={shipment.packingInformationStatus} />,
                            },
                            {
                                key: "generateTransportationOptions",
                                label: "Generate Transportation Options",
                                children: <OperationStatusInfo operationStatus={shipment.generateTransportationOptionsStatus} />,
                            },
                            {
                                key: "confirmTransportationOptionsStatus",
                                label: "Confirm Transportation Options",
                                children: <OperationStatusInfo operationStatus={shipment.confirmTransportationOptionsStatus} />,
                            },
                        ]}
                    />
                </Space>
                <Space direction="horizontal" style={{justifyContent: "space-evenly", width: "100%"}}>
                    <Button
                        type="primary"
                        onClick={() => boxingActions.submitShipment(shipment.shipmentId)}
                        disabled={shipment.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS"}
                    >
                        Submit
                    </Button>
                    <Button
                        type="primary"
                        disabled={
                            shipment?.packingInformationStatus?.operationStatus !== "SUCCESS" ||
                            shipment.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS" ||
                            setPackingInformationTimestamp < lastUpdateTimestamp
                        }
                        onClick={async () => {
                            boxingActions.addLoadingShipment(shipment.shipmentId, "Preparing transport details...");
                            const transportDetails = await prepareTransportDetails(shipment.shipmentId);
                            setTransportDetails(transportDetails);
                            boxingActions.removeLoadingShipment(shipment.shipmentId);
                            setShowTransportDetailsDialog(true);
                        }}
                    >
                        Get transportation options
                    </Button>
                    <Button
                        type="primary"
                        disabled={
                            shipment?.generateTransportationOptionsStatus?.operationStatus !== "SUCCESS" ||
                            !shipment?.transportationOptions?.length ||
                            setPackingInformationTimestamp < lastUpdateTimestamp
                        }
                        onClick={() => {
                            setShowTransportationOptionsDialog(true);
                        }}
                    >
                        View transportation options
                    </Button>
                    <Button
                        type="primary"
                        disabled={shipment.confirmTransportationOptionsStatus?.operationStatus !== "SUCCESS"}
                        onClick={() => {
                            boxingActions.updateShipment(shipment.shipmentId, {...shipment, status: "SHIPPED"});
                            notification.success({
                                message: "Shipment status updated",
                                description: "The shipment has been updated to shipped",
                            });
                            boxingActions.saveData({shipmentIds: [shipment.shipmentId]});
                        }}
                    >
                        Finish
                    </Button>
                    <Dropdown menu={{items: newActions}} placement="bottomRight" arrow>
                        <Button shape="circle" icon={<DownOutlined />} />
                    </Dropdown>
                </Space>
                <Divider style={{margin: 2}} />
                <Spin spinning={shipment?.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS"} indicator={<></>}>
                    {shipment.shipmentType === "SP" ? (
                        <PalletView pallet={pallets[0]} />
                    ) : (
                        <Tabs
                            type="editable-card"
                            activeKey={shipment.activePallet}
                            onChange={(key) => boxingActions.setActivePallet(shipment.shipmentId, key)}
                            items={tabItems}
                            onEdit={onEdit}
                            size="large"
                            removeIcon={<DeleteOutlined style={{fontSize: 20}} />}
                            renderTabBar={(props, DefaultTabBar) => {
                                return (
                                    <Row style={{width: "100%"}} justify={"start"} align={"middle"}>
                                        <DefaultTabBar {...props} style={{width: "70%"}} />
                                        <Divider type="vertical" style={{height: 30, marginLeft: 8}} />
                                        <Space direction="horizontal" style={{justifyContent: "center", width: "20%"}}>
                                            <Space.Compact direction="vertical">
                                                <Typography.Text type="secondary" style={{padding: 0}}>
                                                    {length} x {width} x {height} in
                                                </Typography.Text>
                                                <Typography.Text type="secondary">{weight} lbs</Typography.Text>
                                            </Space.Compact>
                                            <Button style={{marginLeft: 8}} onClick={() => setShowDimensionsDialog(true)}>
                                                Edit
                                            </Button>
                                        </Space>
                                    </Row>
                                );
                            }}
                        />
                    )}
                </Spin>

                <BoxingRemainingItemsDialog
                    expectedItems={shipment.expectedItems}
                    boxes={shipmentBoxes}
                    open={showRemainingItemsDialog}
                    onCancel={() => setShowRemainingItemsDialog(false)}
                    onSelect={(item, quantity) => {
                        boxingActions.addItem(item, quantity, shipment.activePallet || pallets[0].id);
                    }}
                    onRemove={(item, quantity) => {
                        modal.confirm({
                            title: "Are you sure you want to remove this item?",
                            content: (
                                <>
                                    You are about to remove <b>{quantity}</b> unit(s) of <b>{item.msku}</b> from the shipment. This action
                                    cannot be undone.
                                </>
                            ),
                            okText: "Yes",
                            okType: "danger",
                            cancelText: "No",
                            onOk() {
                                boxingActions.removeItemFromShipment(shipment.shipmentId, item.msku, quantity);
                                setShowRemainingItemsDialog(false);
                            },
                            onCancel() {},
                        });
                    }}
                />
                <TransportationOptionsDialog
                    open={showTransportationOptionsDialog}
                    onClose={() => {
                        setShowTransportationOptionsDialog(false);
                    }}
                    onConfirm={(option) => {
                        boxingActions.confirmTransportationOptions(shipment.shipmentId, option.transportationOptionId);
                        setShowTransportationOptionsDialog(false);
                    }}
                    shipment={shipment}
                />
                <DimensionsDialog
                    title="Edit Pallet Dimensions"
                    open={showDimensionsDialog}
                    onSave={(dimensions) => {
                        boxingActions.addPalletDimensions(activePallet.id, dimensions);
                        setShowDimensionsDialog(false);
                    }}
                    onCancel={() => setShowDimensionsDialog(false)}
                    dimensions={activePallet?.dimensions}
                />
                <ConfirmTransportDetailsDialog
                    open={showTransportDetailsDialog}
                    onCancel={() => setShowTransportDetailsDialog(false)}
                    transportDetails={transportDetails}
                    onConfirm={(details) => {
                        setShowTransportDetailsDialog(false);
                        boxingActions.getTransportOptions(shipment.shipmentId, details);
                    }}
                />
                {contextHolder}
            </Space>
        </Spin>
    );
};

export default SingleShipmentView;
