import {message} from "antd";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import {downloadHelper, excelDownloadHelper} from "./Downloads";
import {grabPectyData, requestPectyData} from "../../../services/OmegaService";
import {PectyOffer} from "../../../types/OmegaTypes";
import {getDashboardItems} from "../../../services/WholesaleService";
import ExcelJS from "exceljs";
import {getIdToken} from "../../../services/common";
import {autoWidth} from "../../wholesale/ExcelDownload";

export const processDataPectyHelper = async (data: ArrayBuffer[], collectionName: string) => {
    try {
        // read all the files
        let fullOutput: any[] = [];
        for (const input of data) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge.fromCSV(jnputData).subset(["ASIN", "asin"]);
            console.log(keepaFrame.first());

            fullOutput = fullOutput.concat(keepaFrame.toArray());
        }

        // get unique ASINs
        const uniqueASINs = Array.from(new Set(fullOutput.map((row) => row["ASIN"] ?? row["asin"])));

        console.log(`Unique ASINs: ${uniqueASINs.length} | Total ASINs: ${fullOutput.length} | Collection: ${collectionName}`);
        console.log("Unique ASINs:", uniqueASINs);
        requestPectyData(uniqueASINs, "helper", collectionName.toLowerCase()).then((res) => {
            console.log("pectyrequest", res);
            if (res.error) {
                message.error("Failed to begin requesting Pecty Data");
            } else {
                message.success(`Successfully began requesting Pecty Data for ${uniqueASINs.length} ASINs!`);
            }
        });
    } catch (ex) {
        console.log(ex);
        message.error("Failed to process! Please send file to Karol.");
    }
};

export const downloadPectyData = async (collectionName: string, mapMode: boolean) => {
    try {
        const data = await grabPectyData("helper");
        console.log(data);
        const pectyMap = data
            .filter((row) => row.mgrequestid === collectionName.toLowerCase())
            .reduce(
                (acc: any, curr) => (
                    // eslint-disable-next-line no-sequences
                    curr.dateProcessed > (acc[curr?.data?.asin]?.dateProcessed ?? 0) ? (acc[curr.data.asin] = curr) : null, acc
                ),
                {}
            );

        message.success(`Successfully downloaded data! ${Object.values(pectyMap).length} ASINs were found!`);

        // download brand dashboard items section for the asins downloaded
        const brandDashboardItems = await getDashboardItems(await getIdToken(), Object.keys(pectyMap));

        console.log("downloaded brand dashboard items", brandDashboardItems);

        const perSellerFrame: any[] = [];
        const finalFrame = new dataForge.DataFrame(
            Object.values(pectyMap)
                .map((obj: any) => ({...obj.data, ...obj}))
                .map((obj) => ({
                    ...obj,
                    offers: obj?.data?.offers ?? [],
                    QtyFBA:
                        obj?.data?.offers
                            ?.filter((offer: PectyOffer) => offer.fba)
                            .reduce((acc: any, curr: PectyOffer) => (acc += curr.inventoryCount), 0) ?? 0,
                    QtyFBM:
                        obj?.data?.offers
                            ?.filter((offer: PectyOffer) => !offer.fba)
                            .reduce((acc: any, curr: PectyOffer) => (acc += curr.inventoryCount), 0) ?? 0,
                }))
                .map(({asin, itemName, dateProcessed, totalOffers, totalQty, minPrice, avgPrice, maxPrice, QtyFBA, QtyFBM, offers}) => ({
                    asin,
                    itemName,
                    dateProcessed,
                    totalOffers,
                    totalQty,
                    minPrice,
                    avgPrice,
                    maxPrice,
                    offers,
                    QtyFBA,
                    QtyFBM,
                }))
                .map((offer) => ({...offer, dateProcessed: dayjs(offer.dateProcessed).format("MM-DD-YYYY")}))
        );

        finalFrame.forEach((row) => {
            row.offers.forEach((offer: PectyOffer) => {
                if (offer.sellerName !== "M Emporium") {
                    const dashboardItem = brandDashboardItems.find((item) => item.ASIN === row.asin);
                    perSellerFrame.push({
                        asin: row.asin,
                        itemName: row.itemName,
                        dateProcessed: row.dateProcessed,

                        moq: offer.moq,
                        sellerName: offer.sellerName,
                        fba: offer.fba,
                        price: offer.priceTotal?.toFixed(2),
                        inventoryCount: offer.inventoryCount,
                        mapPrice: dashboardItem?.MAP ?? -1,
                        mapBreak: dashboardItem?.MAP ? (dashboardItem.MAP > offer.priceTotal ? "Yes" : "No") : "Unknown",
                        dashboardTitle: dashboardItem?.Title ?? "",
                        dashboardUPC: dashboardItem?.UPC ?? "",
                        dashboardSKU: dashboardItem?.SupplierSKU ?? "",
                        sellerId: offer.sellerId,
                    });
                }
            });
        });

        if (mapMode) {
            // prepare excel file with two tabs
            const resultWorkbook = new ExcelJS.Workbook();
            const violationSheet = resultWorkbook.addWorksheet("Violations");
            const perSellerSheet = resultWorkbook.addWorksheet("Sellers");

            violationSheet.columns = [
                {header: "ASIN", key: "asin"},
                {header: "SKU", key: "dashboardSKU"},
                {header: "UPC", key: "dashboardUPC"},
                {header: "Item Name", key: "itemName"},
                {header: "Date Processed", key: "dateProcessed"},
                {header: "Seller Name", key: "sellerName"},
                {header: "MAP Price", key: "mapPrice"},
                {header: "Offer Price", key: "offerPrice"},
                {header: "Inventory", key: "inventoryCount"},
            ];

            perSellerSheet.columns = [
                {header: "Seller Name", key: "sellerName"},
                {header: "Type", key: "type"},
                {header: "Inventory", key: "inventoryCount"},
                {header: "Violations", key: "violations"},
                {header: "Storefront Link", key: "link"},
            ];

            violationSheet.addRows(
                perSellerFrame
                    .filter((row) => row.mapBreak === "Yes")
                    .map((row) => ({
                        asin: row.asin,
                        itemName: row.dashboardTitle.length > 0 ? row.dashboardTitle : row.itemName,
                        dateProcessed: row.dateProcessed,
                        sellerName: row.sellerName,
                        mapPrice: row.mapPrice,
                        offerPrice: parseFloat(row.price),
                        inventoryCount: row.inventoryCount,
                        dashboardSKU: row.dashboardSKU,
                        dashboardUPC: row.dashboardUPC,
                    }))
            );

            perSellerSheet.addRows(
                perSellerFrame
                    .filter((row) => row.mapBreak === "Yes")
                    .reduce((acc: any, curr) => {
                        const seller = acc.find((row: any) => row.sellerName === curr.sellerName);
                        if (seller) {
                            seller.inventoryCount += curr.inventoryCount;
                            seller.violations += 1;
                        } else {
                            acc.push({
                                sellerName: curr.sellerName,
                                type: curr.fba ? "FBA" : "FBM",
                                inventoryCount: curr.inventoryCount,
                                violations: 1,
                                link: `https://www.amazon.com/sp?_encoding=UTF8&isAmazonFulfilled=1&ref_=olp_merch_name_1&seller=${curr.sellerId}`,
                            });
                        }
                        return acc;
                    }, [])
                    .sort((a: any, b: any) => b.violations - a.violations)
            );

            autoWidth(violationSheet, 5, 50);
            autoWidth(perSellerSheet, 4, 50);

            console.log(violationSheet.getColumnKey("offerPrice"));

            violationSheet.getColumn("offerPrice").numFmt = "$#,##0.00";
            violationSheet.getColumn("mapPrice").numFmt = "$#,##0.00";
            violationSheet.getColumn("inventoryCount").numFmt = "#0";

            // format the link column as a Link
            perSellerSheet.getColumn("link").eachCell((cell, idx) => {
                if (idx === 1) {
                    return;
                }

                cell.value = {
                    text: "Link",
                    hyperlink: cell.value as string,
                };

                cell.font = {
                    color: {argb: "FF0000FF"},
                    underline: true,
                };
            });

            for (const sheet of [violationSheet, perSellerSheet]) {
                sheet.getRow(1).eachCell((cell) => {
                    cell.font = {
                        bold: true,
                    };
                });
            }

            excelDownloadHelper(resultWorkbook, `${collectionName}-${dayjs().format("YYYY-MM-DD")}-MAP Violations`);
        } else {
            downloadHelper(perSellerFrame, `${collectionName}-${dayjs().format("YYYY-MM-DD")}`);
        }
    } catch (ex) {
        console.log(ex);
        message.error("Failed to download data! Please send file to Karol.");
    }
};
