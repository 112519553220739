import {Button, Collapse, Divider, Dropdown, Form, Input, message, Modal, Select, Space, Statistic, Table, Tag, Typography} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {ColumnsType} from "antd/lib/table";
import React, {useEffect, useState} from "react";
import {NoteItem, OMSItem, SingularItem} from "../../types/OmegaTypes";
import {
    getBooleanFilter,
    getColumnTitle,
    getNumberFilterSorter,
    getNumberRenderer,
    getPriceRenderer,
    getShareRenderer,
} from "../utilities/TableFilterSorters";
import ReactDragListView from "react-drag-listview";
import {GetExpandedFilter} from "../utilities/ExpandedFilterDropdown";
import {OMEGA_LUCAS_PRESET, OMEGA_LUCAS_SECOND_PRESET, OMEGA_MAX_LS_PRESET, OMEGA_MAX_PRESET} from "../utilities/TableColumnPresets";
import {OMEGA_POSSIBLE_ISSUES, OMEGA_POSSIBLE_PRESETS} from "./BrandCalcs";
import {POSSIBLE_ACTIONS, POSSIBLE_TAGS} from "./SingularItemCard";
import {ExcelExport} from "./ExcelExport";
import {CSVDownload} from "../utilities/CSVDownload";
import dayjs from "dayjs";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {requestPectyData} from "../../services/OmegaService";
import {useQueryClient} from "@tanstack/react-query";
import {ColumnType, FilterValue} from "antd/lib/table/interface";
import {useOmega} from "../../contexts/OmegaContext";
import {confirmModal} from "../wholesale/table/WholesaleUtilities";

const {Link} = Typography;
interface DataType extends SingularItem {
    key: React.Key;
}

export const filterOmegaTableData = (filterInfo: Record<string, FilterValue | null>, data: any[]) => {
    // @ts-ignore
    const filterEntries: [string, (string | number | boolean)[]][] = Object.entries(filterInfo).filter(([_, value]) => value !== null);
    if (filterEntries.length > 0) {
        const filtered = data.filter((record) =>
            filterEntries.every(([key, value]) => {
                const filterLogic = OmegaColumns.find((column: ColumnType<DataType>) => column.dataIndex === key)?.onFilter;
                return filterLogic && filterLogic(value[0], record);
            })
        );

        return filtered;
    } else {
        return data;
    }
};

export const copyToClipboard = (text: string, type: string) =>
    navigator.clipboard
        .writeText(text)
        .then((x) => {
            message.success(`${type} copied!`);
        })
        .catch((err) => {
            message.error(`Failed to copy ${type}!`);
        });

const OmegaColumns: ColumnsType<DataType> = [
    {
        title: "SKU",
        dataIndex: "SKU",
        key: "SKU",
        width: "110px",
        fixed: "left",
        ...GetExpandedFilter([], "SKU"),
        onCell: (record) => {
            return {
                onClick: (event) => {
                    if ((event.target as HTMLElement).nodeName.includes("TD")) {
                        navigator.clipboard
                            .writeText(record.SKU)
                            .then((x) => {
                                message.success("SKU copied!");
                            })
                            .catch((err) => {
                                console.log("failed copy", err);
                                message.error("Failed to copy SKU!");
                            });
                    }
                },
            };
        },
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        width: "110px",
        ...GetExpandedFilter([], "ASIN"),
        render: (text: string, record: DataType) => (
            <Link href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`} target="_blank">
                {text}
            </Link>
        ),
        onCell: (record) => {
            return {
                onClick: (event) => {
                    if ((event.target as HTMLElement).nodeName.includes("TD")) {
                        navigator.clipboard
                            .writeText(record.ASIN)
                            .then((x) => {
                                message.success("ASIN copied!");
                            })
                            .catch((err) => {
                                console.log("failed copy", err);
                                message.error("Failed to copy ASIN!");
                            });
                    }
                },
            };
        },
    },
    {
        title: "Amazon Image",
        key: "Amazon Image",
        dataIndex: "ASIN",
        width: "125px",
        render: (text: string, record: DataType) => {
            return (
                <a target="_blank" rel="noopener noreferrer" href={`https://www.amazon.com/dp/${text}/?th=1&psc=1`}>
                    <img src={record.ssItem.image_url.replace("_SL75_", "_SL125_")} height={125} width={125} alt="Amazon product" />
                </a>
            );
        },
    },
    {
        title: "Parent",
        dataIndex: "Parent",
        key: "Parent",
        width: "110px",
        ...GetExpandedFilter([], "Parent"),
    },
    {
        title: "Title",
        dataIndex: "Title",
        key: "Title",
        width: "250px",
        ...GetExpandedFilter([], "Title"),
    },
    {
        title: "Brand",
        dataIndex: "Brand",
        key: "Brand",
        width: "110px",
        ...GetExpandedFilter([], "Brand"),
    },
    {
        title: "Preset",
        dataIndex: "Preset",
        key: "Preset",
        width: "110px",
        ...GetExpandedFilter(OMEGA_POSSIBLE_PRESETS, "Preset"),
    },
    {
        title: "Issues",
        dataIndex: "Issues",
        key: "Issues",
        width: "85px",
        ...GetExpandedFilter(OMEGA_POSSIBLE_ISSUES, "Issues"),
        render: (issues) => (
            <Space direction="vertical" align="center" style={{width: "100%"}}>
                {issues.map((issue: string, idx: number) => (
                    <Tag style={{marginRight: 0}} key={idx}>
                        {issue.toUpperCase()}
                    </Tag>
                ))}
            </Space>
        ),
    },
    {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        width: "85px",
        ...GetExpandedFilter(POSSIBLE_ACTIONS, "notes", (record) =>
            record.notes.slice(0, 1).map((note: NoteItem) => note.actions.join(","))
        ),
        render: (notes) => (
            <Space direction="vertical" align="center" style={{width: "100%"}}>
                {notes
                    .slice(0, 3)
                    .map((note: NoteItem, noteIdx: number) =>
                        note.actions.map((action: string, idx: number) => (
                            <Tag style={{marginRight: 0}} key={`${noteIdx}-${idx}`}>
                                {action}
                            </Tag>
                        ))
                    )
                    .flat()}
            </Space>
        ),
    },
    {
        title: "SinceNote",
        dataIndex: "sinceNote",
        key: "sinceNote",
        width: "115px",
        ...getNumberFilterSorter("sinceNote"),
        onFilter: (value, record) => {
            const [min, max] = (value as string).split(" ").map((val) => parseFloat(val));
            return (record.sinceNote >= min && (max === 0 || record.sinceNote <= max)) || record.sinceNote === -1;
        },
        // render: (notes) => notes?.[0]?.insertTimestamp ? dayjs().diff(dayjs(notes?.[0]?.insertTimestamp), 'days') : -1
    },
    {
        title: "BBShare",
        key: "BBShare",
        dataIndex: "BBShare",
        width: "110px",
        ...getNumberFilterSorter("BBShare"),
        render: (val) => <Typography>{val >= 0 ? `${val.toFixed(2)}%` : val ? val : "?"}</Typography>,
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "110px",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer(),
    },
    {
        title: "Listed Price",
        dataIndex: "Listed Price",
        key: "Listed Price",
        width: "110px",
        ...getNumberFilterSorter("Listed Price"),
        ...getPriceRenderer(),
    },
    {
        title: "Min Price",
        dataIndex: "Min Price",
        key: "Min Price",
        width: "110px",
        ...getNumberFilterSorter("Min Price"),
        ...getPriceRenderer(),
    },
    {
        title: "Max Price",
        dataIndex: "Max Price",
        key: "Max Price",
        width: "110px",
        ...getNumberFilterSorter("Max Price"),
        ...getPriceRenderer(),
    },
    {
        title: "MVSP",
        dataIndex: "MVSP",
        key: "MVSP",
        width: "90px",
        ...getNumberFilterSorter("MVSP"),
        ...getPriceRenderer(),
    },
    {
        title: "CurrBB",
        dataIndex: "CurrBB Price",
        key: "CurrBB Price",
        width: "110px",
        ...getNumberFilterSorter("CurrBB Price"),
        ...getPriceRenderer(),
    },
    {
        title: "AvgSell",
        dataIndex: "Avg Sell Price",
        key: "Avg Sell Price",
        width: "110px",
        ...getNumberFilterSorter("Avg Sell Price"),
        ...getPriceRenderer(),
    },
    {
        title: "MAP",
        dataIndex: "MAP",
        key: "MAP",
        width: "110px",
        ...getNumberFilterSorter("MAP"),
        ...getPriceRenderer(),
    },
    {
        title: "Profit",
        dataIndex: "Profit",
        key: "Profit",
        width: "110px",
        ...getNumberFilterSorter("Profit"),
        ...getPriceRenderer(),
    },
    {
        title: "Future Profit",
        dataIndex: "futureProfit",
        key: "futureProfit",
        width: "110px",
        ...getNumberFilterSorter("futureProfit"),
        ...getPriceRenderer(),
    },
    {
        title: "Inbound Fee",
        dataIndex: "inboundFee",
        key: "inboundFee",
        width: "110px",
        ...getNumberFilterSorter("inboundFee"),
        ...getPriceRenderer(),
    },

    {
        title: "Freight Fee",
        dataIndex: "freightCost",
        key: "freightCost",
        width: "110px",
        ...getNumberFilterSorter("freightCost"),
        ...getPriceRenderer(),
    },
    {
        title: "Storage Fee",
        dataIndex: "storageCost",
        key: "storageCost",
        width: "110px",
        ...getNumberFilterSorter("storageCost"),
        ...getPriceRenderer(),
    },
    {
        title: "Total Storage Fee",
        dataIndex: "extraStorageCost",
        key: "extraStorageCost",
        width: "120px",
        ...getNumberFilterSorter("extraStorageCost"),
        ...getPriceRenderer(),
    },
    {
        title: "ROI",
        key: "ROI",
        dataIndex: "ROI",
        width: "85px",
        ...getNumberFilterSorter("ROI"),
        render: (val, record) => <Typography>{`${val.toFixed(2)}%`}</Typography>,
    },
    {
        title: "MinROI",
        key: "MinROI",
        dataIndex: "MinROI",
        width: "85px",
        ...getNumberFilterSorter("MinROI"),
        render: (val, record) => <Typography>{`${val.toFixed(2)}%`}</Typography>,
    },
    {
        title: "MaxROI",
        key: "MaxROI",
        dataIndex: "MaxROI",
        width: "85px",
        ...getNumberFilterSorter("MaxROI"),
        render: (val, record) => <Typography>{`${val.toFixed(2)}%`}</Typography>,
    },
    {
        title: "DIS",
        dataIndex: "Days In Stock",
        key: "Days In Stock",
        width: "85px",
        ...getNumberFilterSorter("Days In Stock"),
        ...getNumberRenderer(),
    },
    {
        title: "Stock",
        dataIndex: "Stock",
        key: "Stock",
        width: "85px",
        ...getNumberFilterSorter("Stock"),
        ...getNumberRenderer(),
    },
    {
        title: "Fulfil. Inv",
        dataIndex: "availableInventory",
        key: "availableInventory",
        width: "110px",
        ...getNumberFilterSorter("availableInventory"),
        ...getNumberRenderer(),
    },
    {
        title: "Fulfil. Value",
        dataIndex: "availableInvValue",
        key: "availableInvValue",
        width: "110px",
        ...getNumberFilterSorter("availableInvValue"),
        ...getPriceRenderer(),
    },
    {
        title: "Unavail. Inv",
        dataIndex: "unavailableInventory",
        key: "unavailableInventory",
        width: "110px",
        ...getNumberFilterSorter("unavailableInventory"),
        ...getNumberRenderer(),
    },
    {
        title: "Unavail. Value",
        dataIndex: "unavailableInvValue",
        key: "unavailableInvValue",
        width: "110px",
        ...getNumberFilterSorter("unavailableInvValue"),
        ...getPriceRenderer(),
    },
    {
        title: "InvOver270D",
        dataIndex: "InventoryOver270D",
        key: "InventoryOver270D",
        width: "110px",
        ...getNumberFilterSorter("InventoryOver270D"),
        ...getNumberRenderer(),
    },
    {
        title: "InvOver180D",
        dataIndex: "InventoryOver180D",
        key: "InventoryOver180D",
        width: "110px",
        ...getNumberFilterSorter("InventoryOver180D"),
        ...getNumberRenderer(),
    },
    {
        title: "InvOver90D",
        dataIndex: "InventoryOver90D",
        key: "InventoryOver90D",
        width: "110px",
        ...getNumberFilterSorter("InventoryOver90D"),
        ...getNumberRenderer(),
    },
    {
        title: "InvUnder90D",
        dataIndex: "InventoryUnder90D",
        key: "InventoryUnder90D",
        width: "115px",
        ...getNumberFilterSorter("InventoryUnder90D"),
        ...getNumberRenderer(),
    },
    {
        title: "Sold (7D)",
        dataIndex: "Sold (7D)",
        key: "Sold (7D)",
        width: "85px",
        ...getNumberFilterSorter("Sold (7D)"),
        ...getNumberRenderer(0),
    },
    {
        title: "Velocity (7D)",
        dataIndex: "Velocity (7D)",
        key: "Velocity (7D)",
        width: "95px",
        ...getNumberFilterSorter("Velocity (7D)"),
        ...getNumberRenderer(2),
    },
    {
        title: "Sold (14D)",
        dataIndex: "Sold (14D)",
        key: "Sold (14D)",
        width: "85px",
        ...getNumberFilterSorter("Sold (14D)"),
        ...getNumberRenderer(0),
    },
    {
        title: "Sold (30D)",
        dataIndex: "Sold (30D)",
        key: "Sold (30D)",
        width: "85px",
        ...getNumberFilterSorter("Sold (30D)"),
        ...getNumberRenderer(0),
    },
    {
        title: "Velocity (30D)",
        dataIndex: "Velocity (30D)",
        key: "Velocity (30D)",
        width: "95px",
        ...getNumberFilterSorter("Velocity (30D)"),
        ...getNumberRenderer(2),
    },
    {
        title: "EstSales (30D)",
        dataIndex: "EstSales (30D)",
        key: "EstSales (30D)",
        width: "95px",
        ...getNumberFilterSorter("EstSales (30D)"),
        ...getNumberRenderer(2),
    },
    {
        title: "Sold (60D)",
        dataIndex: "Sold (60D)",
        key: "Sold (60D)",
        width: "85px",
        ...getNumberFilterSorter("Sold (60D)"),
        ...getNumberRenderer(0),
    },
    {
        title: "Sold (90D)",
        dataIndex: "Sold (90D)",
        key: "Sold (90D)",
        width: "85px",
        ...getNumberFilterSorter("Sold (90D)"),
        ...getNumberRenderer(0),
    },
    {
        title: "TTS",
        dataIndex: "EstTTS",
        key: "EstTTS",
        width: "85px",
        ...getNumberFilterSorter("EstTTS"),
        ...getNumberRenderer(0),
    },
    {
        title: "GMROI",
        dataIndex: "GMROI",
        key: "GMROI",
        width: "95px",
        ...getNumberFilterSorter("GMROI"),
        ...getNumberRenderer(2),
    },
    {
        title: "Returns",
        dataIndex: "returnRate",
        key: "returnRate",
        width: "95px",
        ...getNumberFilterSorter("returnRate"),
        ...getShareRenderer(2, "?", 100),
    },
    {
        title: "Unsellables",
        dataIndex: "returnUnsellableRate",
        key: "returnUnsellableRate",
        width: "115px",
        ...getNumberFilterSorter("returnUnsellableRate"),
        ...getShareRenderer(2, "?", 100),
        // ...getNumberRenderer(2),
    },
    {
        title: "Return Cost",
        dataIndex: "totalReturnCost",
        key: "totalReturnCost",
        width: "115px",
        ...getNumberFilterSorter("totalReturnCost"),
        ...getPriceRenderer(),
    },
    {
        title: "Rec. Buy",
        dataIndex: "Recommended Buy",
        key: "Rec. Buy",
        width: "85px",
        ...getNumberFilterSorter("Recommended Buy"),
        ...getNumberRenderer(),
    },
    {
        title: "OMS",
        dataIndex: "omsItems",
        key: "omsItems",
        width: "85px",
        // ...getNumberFilterSorter('OMS'),
        ...GetExpandedFilter([], "omsItems", (record) =>
            record.omsItems.map((item: OMSItem) => `${item.Supplier_SO},${item.Supplier_PO}`).join(",")
        ),
        render: (value) => (
            <Space direction="vertical" align="center" style={{width: "100%", maxHeight: "150px", overflowY: "auto"}}>
                {value.map((item: OMSItem, idx: number) => (
                    <Tag style={{marginRight: 0}} key={idx}>
                        {`${item.Supplier_SO || item.Supplier_PO} - ${item.ShipDate} - ${item.Quantity} units ${
                            item.ShipDateType === "LSD" ? " - LSD" : ""
                        }${item.IsPaid ? " - Paid" : ""}`}
                    </Tag>
                ))}
            </Space>
        ),
    },
    {
        title: "Ship Later",
        dataIndex: "Ship Later",
        key: "Ship Later",
        width: "85px",
        ...getNumberFilterSorter("Ship Later"),
        ...getNumberRenderer(),
    },
    {
        title: "SLTTS",
        dataIndex: "ShipLaterTTS",
        key: "ShipLaterTTS",
        width: "85px",
        ...getNumberFilterSorter("ShipLaterTTS"),
        ...getNumberRenderer(),
    },
    {
        title: "SLVal",
        dataIndex: "ShipLaterVal",
        key: "ShipLaterVal",
        width: "85px",
        ...getNumberFilterSorter("ShipLaterVal"),
        ...getPriceRenderer(),
    },
    {
        title: "Tags",
        dataIndex: "Tags",
        key: "Tags",
        width: "85px",
        ...GetExpandedFilter(POSSIBLE_TAGS, "Tags"),
        render: (tags) => (
            <Space direction="vertical" align="center" style={{width: "100%"}}>
                {tags.map((tag: string, idx: number) => (
                    <Tag style={{marginRight: 0}} key={idx}>
                        {tag.toUpperCase()}
                    </Tag>
                ))}
            </Space>
        ),
    },
    {
        title: "Sessions",
        dataIndex: "Sessions",
        key: "Sessions",
        width: "90px",
        ...getNumberFilterSorter("Sessions"),
        ...getNumberRenderer(),
    },
    {
        title: "OurSess",
        dataIndex: "OurSessions",
        key: "OurSessions",
        width: "90px",
        ...getNumberFilterSorter("OurSessions"),
        ...getNumberRenderer(),
    },
    {
        title: "OurSess-Sales",
        dataIndex: "OurSessions-Sales",
        key: "OurSessions-Sales",
        width: "105px",
        ...getNumberFilterSorter("OurSessions-Sales"),
        ...getNumberRenderer(),
    },
    {
        title: "Cost / Sale",
        dataIndex: "Cost / Sale",
        key: "Cost / Sale",
        width: "85px",
        ...getNumberFilterSorter("Cost / Sale"),
        ...getNumberRenderer(2),
    },
    {
        title: "CR",
        dataIndex: "CR",
        key: "CR",
        width: "70px",
        ...getNumberFilterSorter("CR"),
        render: (val, record) => <Typography>{`${val.toFixed(2)}%`}</Typography>,
    },
    {
        title: "Out of Season?",
        dataIndex: "Out of Season?",
        key: "Out of Season?",
        width: "85px",
        render: (val) => (val ? "True" : "False"),
        ...getBooleanFilter("Out of Season?"),
    },
    {
        title: "Pecty",
        dataIndex: "pectyData",
        key: "pectyData",
        width: "95px",
        render: (val) => (val ? dayjs().diff(dayjs(val.dateProcessed), "hour") : "None"),
        filters: [
            {
                text: "Exists",
                value: true,
            },
            {
                text: "None",
                value: false,
            },
        ],
        filterMultiple: false,
        onFilter: (value, record) => (value ? record["pectyData"] !== undefined : record["pectyData"] === undefined),
    },
    {
        title: "Action",
        dataIndex: "insight",
        key: "insight.action",
        width: "95px",
        render: (val) => {
            if (val) {
                if (val["EstSales (30D)"] === -1 || val.MOQExists) {
                    return "Review";
                } else {
                    return `${val.SuggestedAction} ${val.SuggestedAction.toLowerCase() !== "stay" ? ` to ${val.SuggestedPrice}` : ""}`;
                }
            } else {
                return "None";
            }
        },
        filters: [
            {
                text: "Exists",
                value: true,
            },
            {
                text: "None",
                value: false,
            },
        ],
        filterMultiple: false,
        onFilter: (value, record) => (value ? record.insight?.SuggestedAction !== undefined : record.insight === undefined),
    },
    {
        title: "BelowMin",
        dataIndex: "hoursBelowMin",
        key: "hoursBelowMin",
        width: "100px",
        ...getNumberFilterSorter("hoursBelowMin"),
        ...getNumberRenderer(),
    },
    {
        title: "InactiveSellers",
        dataIndex: "inactiveSellersCount",
        key: "inactiveSellersCount",
        width: "190px",
        ...getNumberFilterSorter("inactiveSellersCount"),
        ...getNumberRenderer(),
    },
    {
        title: "ActiveSellers",
        dataIndex: "activeSellers",
        key: "activeSellers",
        width: "120px",
        ...getNumberFilterSorter("activeSellers"),
        ...getNumberRenderer(),
    },
    {
        title: "FBA Comps",
        dataIndex: "fbaSellers",
        key: "fbaSellers",
        width: "110px",
        ...getNumberFilterSorter("fbaSellers"),
        ...getNumberRenderer(),
    },
    {
        title: "FBM Comps",
        dataIndex: "fbmSellers",
        key: "fbmSellers",
        width: "110px",
        ...getNumberFilterSorter("fbmSellers"),
        ...getNumberRenderer(),
    },
    {
        title: "Backordered",
        dataIndex: "isBackordered",
        key: "isBackordered",
        width: "100px",
        ...getBooleanFilter("isBackordered"),
        render: (val) => (val ? "True" : "False"),
    },
    {
        title: "QTYBelow",
        dataIndex: "QTYBelow",
        key: "QTYBelow",
        width: "105px",
        render: (val) => (val ? val : "Unknown"),
        ...getNumberFilterSorter("QTYBelow"),
    },
    {
        title: "Repricing",
        dataIndex: "repricing",
        key: "repricing",
        width: "95px",
        render: (val) => (val ? "True" : "False"),
        ...getBooleanFilter("repricing"),
    },
];

// const FILTER_PRESETS = [
//     'None - Reset',
//     'MCW',
// ]

const ItemBrowser: React.FC<{
    tableData: DataType[];
    setTopFilteredInfo: (filteredInfo: Record<string, FilterValue | null>) => void;
    setSingularBrowserData: (tableData: any[]) => void;
}> = React.memo(({tableData, setTopFilteredInfo, setSingularBrowserData}) => {
    const queryClient = useQueryClient();
    const [modal, contextHolder] = Modal.useModal();
    const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
    const [actualTableData, setActualTableData] = useState<DataType[]>([]);
    const [localFilteredData, setLocalFilteredData] = useState<DataType[]>([]);
    const [columns, setColumns] = useState<ColumnsType<DataType>>(OmegaColumns);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(["Amazon Image", "Action", "QTYBelow"]);
    const [inputForm] = Form.useForm();
    const [bulkForm] = Form.useForm();
    const {userCollections, setUserCollections} = useOmega();

    useEffect(() => {
        // let resultingData = tableData
        // switch (selectedFilterPreset) {
        //     case 'MCW':
        //         resultingData = tableData.filter((row) => {
        //             return row.ssItem.cur_buybox_price && !row.Tags.map((tag) => tag.toLowerCase()).includes('supp') && row.availableInvValue > 200 && parseFloat(row.ssItem.cur_buybox_price) < row["Min Price"]
        //         })
        //         break;
        //     default:
        //         break;
        // }

        setActualTableData(tableData);
    }, [tableData]);

    useEffect(() => {
        console.log("wtf is going on", filteredInfo);
        const filtered = filterOmegaTableData(filteredInfo, actualTableData);
        setLocalFilteredData(filtered);
    }, [actualTableData, filteredInfo]);

    const dragProps = {
        onDragEnd(fromIndex: any, toIndex: any) {
            const localColumns: any = [...(columns as any)];
            const item = localColumns.splice(fromIndex, 1)[0];
            localColumns.splice(toIndex, 0, item);
            setColumns(localColumns);
        },
        nodeSelector: "button",
    };

    const SetColumnsToPreset = (columnsToShow: string[]) => {
        const actualColumnsToShow = columnsToShow.filter((colToShow) =>
            columns.map((column) => getColumnTitle(column)).includes(colToShow)
        );
        setHiddenColumns(columns.map((column) => getColumnTitle(column)).filter((column) => !actualColumnsToShow.includes(column)));

        const asinColumn = columns.find((col) => getColumnTitle(col) === "SKU")!;

        const desiredOrder = actualColumnsToShow
            .map((colTitle) => {
                const desiredCol = columns.find((col) => getColumnTitle(col) === colTitle)!;
                return desiredCol;
            })
            .filter((col) => !["SKU"].includes(getColumnTitle(col)));

        const undesiredOrder = columns
            .filter((col) => !actualColumnsToShow.includes(getColumnTitle(col)))
            .filter((col) => !["SKU"].includes(getColumnTitle(col)));

        setColumns([asinColumn, ...desiredOrder, ...undesiredOrder]);
    };

    const requestPecty = () => {
        const itemsToRequest = localFilteredData
            .map((row) => row.ASIN)
            .filter((curr, idx, arr) => arr.findIndex((val) => val === curr) === idx)
            .slice(0, 500);
        modal.confirm({
            title: `Karol's sanity check`,
            icon: <ExclamationCircleOutlined />,
            content: `You are about to request quantity data of ${itemsToRequest.length} ASINs (limited to 500). Are you sure you want to proceed?`,
            onOk() {
                requestPectyData(itemsToRequest).then((res) => {
                    console.log("pectyrequest", res);
                    if (res.error) {
                        message.error("Failed to begin requesting Pecty Data");
                    } else {
                        message.success("Successfully began requesting Pecty Data");
                    }
                });
            },
            onCancel() {
                console.log("Cancelled");
            },
        });
    };

    const refreshPecty = () => {
        queryClient.invalidateQueries({queryKey: ["pectySingular_data"]});
    };

    return (
        <>
            <Collapse defaultActiveKey={3}>
                <CollapsePanel key={1} header={"Columns"}>
                    <ReactDragListView.DragColumn {...dragProps}>
                        <div style={{gap: "8px", display: "flex", justifyContent: "space-around", flexWrap: "wrap", flexDirection: "row"}}>
                            {columns.map((column) => {
                                if (hiddenColumns.includes(getColumnTitle(column))) {
                                    return (
                                        <Button
                                            style={{marginBottom: "4px", marginRight: "2px"}}
                                            size="small"
                                            key={getColumnTitle(column)}
                                            type="dashed"
                                            onClick={() =>
                                                setHiddenColumns((hidden) => hidden.filter((col) => col !== getColumnTitle(column)))
                                            }
                                        >
                                            {getColumnTitle(column)}
                                        </Button>
                                    );
                                } else {
                                    return (
                                        <Button
                                            style={{marginBottom: "4px", marginRight: "2px"}}
                                            size="small"
                                            key={getColumnTitle(column)}
                                            type="primary"
                                            onClick={() => setHiddenColumns((hidden) => hidden.concat([getColumnTitle(column)]))}
                                        >
                                            {getColumnTitle(column)}
                                        </Button>
                                    );
                                }
                            })}
                        </div>
                    </ReactDragListView.DragColumn>
                </CollapsePanel>
                <CollapsePanel key={2} header={"Column Presets"}>
                    <Space align="center" style={{width: "100%", overflow: "auto"}}>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                SetColumnsToPreset(OMEGA_LUCAS_PRESET);
                            }}
                        >
                            Lucas
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                SetColumnsToPreset(OMEGA_LUCAS_SECOND_PRESET);
                            }}
                        >
                            Lucas V2
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                SetColumnsToPreset(OMEGA_MAX_PRESET);
                            }}
                        >
                            Max
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                SetColumnsToPreset(OMEGA_MAX_LS_PRESET);
                            }}
                        >
                            Max LS
                        </Button>
                    </Space>
                </CollapsePanel>
                <CollapsePanel key={4} header={"User Collections"}>
                    <Space direction="horizontal" align="center">
                        <Button
                            type="primary"
                            onClick={() => {
                                modal.confirm({
                                    title: `Fill in the values for this action`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: (
                                        <Form
                                            form={inputForm}
                                            name="inputFormCustom"
                                            layout="vertical"
                                            initialValues={{days: 30}}
                                            onFinish={() => null}
                                        >
                                            <Form.Item
                                                name="colName"
                                                label="What would you like to call your new filter collection?"
                                                rules={[{required: true, message: "Filter collection name cannot be blank!"}]}
                                            >
                                                <Input title="Name" />
                                            </Form.Item>
                                        </Form>
                                    ),
                                    onOk() {
                                        inputForm.validateFields().then((values) => {
                                            console.log("bulk form values", values);
                                            const cleanedFilteredInfo = Object.fromEntries(
                                                Object.entries(filteredInfo).filter(([_, v]) => v != null)
                                            );
                                            setUserCollections({...userCollections, [values.colName]: cleanedFilteredInfo});
                                            inputForm.resetFields();
                                        });
                                    },
                                    onCancel() {
                                        console.log("Cancelled Bulk");
                                        inputForm.resetFields();
                                    },
                                });
                            }}
                        >
                            Save Current Filters
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                modal.confirm({
                                    title: `Fill in the values for this action`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: (
                                        <Form
                                            form={inputForm}
                                            name="inputFormCustom"
                                            layout="vertical"
                                            initialValues={{days: 30}}
                                            onFinish={() => null}
                                        >
                                            <Form.Item
                                                name="colName"
                                                label="What would you like to call your new filter collection?"
                                                rules={[{required: true, message: "Filter collection name cannot be blank!"}]}
                                            >
                                                <Input title="Name" />
                                            </Form.Item>
                                            <Form.Item
                                                name="filterValues"
                                                label="Paste your received filter string here"
                                                rules={[{required: true, message: "Filter values cannot be blank!"}]}
                                            >
                                                <Input.TextArea title="Filters" />
                                            </Form.Item>
                                        </Form>
                                    ),
                                    onOk() {
                                        inputForm
                                            .validateFields()
                                            .then((values) => {
                                                console.log("bulk form values", values);
                                                console.log("filter values", values.filterValues, JSON.parse(values.filterValues));
                                                setUserCollections({...userCollections, [values.colName]: JSON.parse(values.filterValues)});
                                                inputForm.resetFields();
                                            })
                                            .catch((ex) => {
                                                message.error(
                                                    `Failed to add Filter Preset, contact Karol with error + the values you've tried to add`
                                                );
                                            });
                                    },
                                    onCancel() {
                                        console.log("Cancelled Bulk");
                                        inputForm.resetFields();
                                    },
                                });
                            }}
                        >
                            Add Preset
                        </Button>
                        <Divider type="vertical"></Divider>
                        {Object.entries(userCollections).map(([col, filters], idx) => (
                            <Dropdown.Button
                                key={idx}
                                menu={{
                                    items: [
                                        {
                                            key: 1,
                                            label: "Share",
                                            onClick: (e) => {
                                                const filterCopy = userCollections[col];
                                                console.log("share this lule", col, filterCopy, JSON.stringify(filterCopy));
                                                copyToClipboard(JSON.stringify(filterCopy), "Filter Preset");
                                            },
                                        },
                                        {
                                            key: 2,
                                            label: "Delete",
                                            onClick: (e) => {
                                                console.log("delete this lule", col);
                                                confirmModal(
                                                    modal,
                                                    () => {
                                                        const filterCopy = {...userCollections};
                                                        delete filterCopy[col];
                                                        setUserCollections(filterCopy);
                                                    },
                                                    "Do you really want to delete this preset?"
                                                );
                                            },
                                        },
                                    ],
                                }}
                                onClick={() => {
                                    setFilteredInfo(filters);
                                    setTopFilteredInfo(filters);
                                    setSingularBrowserData(filterOmegaTableData(filters, actualTableData));
                                }}
                            >
                                {col}
                            </Dropdown.Button>
                        ))}
                    </Space>
                </CollapsePanel>
                <CollapsePanel key={3} header={"Actions"}>
                    <Space direction="horizontal" align="center">
                        <CSVDownload
                            data={localFilteredData
                                .map((item) => ({
                                    ASIN: item.ASIN,
                                    AMZTitle: item.Title,
                                    UPC: item.omsItems[0]?.UPC || "",
                                    SupplierSKU: item.omsItems[0]?.Supplier_SKU || "",
                                    SupplierTitle: item.omsItems[0]?.Supplier_Title || "",
                                    SupplierNotes: item.omsItems[0]?.Supplier_Notes || "",
                                    Cost: item.omsItems[0]?.Cost || item.Cost || -1,
                                }))
                                .filter((curr, idx, arr) => arr.findIndex((val) => val.ASIN === curr.ASIN) === idx)}
                            isLoading={true}
                            collection={`${dayjs().format("MM-DD-YYYY")}_Omega_Replen_ASINs`}
                            title={`Replen Export`}
                        ></CSVDownload>
                        <ExcelExport data={localFilteredData.map(({itemArr, ...rest}) => rest)} filteredData={[]}></ExcelExport>
                        <Button type="primary" onClick={requestPecty}>
                            Request Pecty
                        </Button>
                        <Button type="primary" onClick={refreshPecty}>
                            Refresh Pecty
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                modal.confirm({
                                    title: `Fill in the values - priority is from top to bottom!`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: (
                                        <Form
                                            form={bulkForm}
                                            name="bulkFormCustom"
                                            layout="vertical"
                                            initialValues={{clipboardColumnCopy: "ASIN"}}
                                            onFinish={() => null}
                                            preserve={false}
                                        >
                                            <Form.Item name="clipboardColumnCopy" label="Which column data do you to copy to clipboard?">
                                                <Select
                                                    showSearch
                                                    filterOption={true}
                                                    optionFilterProp="label"
                                                    options={[
                                                        {
                                                            label: "ASIN",
                                                            value: "ASIN",
                                                        },
                                                        {
                                                            label: "Parent",
                                                            value: "Parent",
                                                        },
                                                        {
                                                            label: "SKU",
                                                            value: "SKU",
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Form>
                                    ),
                                    onOk() {
                                        bulkForm.validateFields().then((values) => {
                                            const itemsToCopy = localFilteredData;
                                            const columnToCopy = bulkForm.getFieldValue("clipboardColumnCopy");
                                            const copyText = itemsToCopy.map((item) => item[columnToCopy]).join("\n");
                                            copyToClipboard(copyText, columnToCopy);
                                            console.log(copyText);
                                        });
                                    },
                                    onCancel() {
                                        console.log("Cancelled Bulk");
                                        bulkForm.resetFields();
                                    },
                                });
                            }}
                        >
                            Copy Data
                        </Button>
                    </Space>
                </CollapsePanel>
                {/* <CollapsePanel key={3} header={"Filter Presets - use them before filtering the table!"}>
                    <Space align="center" style={{width: '100%', overflow: 'auto'}}>
                        {
                            FILTER_PRESETS.map((filterPreset) => 
                            <Button 
                                style={{marginBottom: '4px', marginRight: '2px'}} 
                                size="small" 
                                type="primary"
                                danger={selectedFilterPreset !== 'None - Reset' && selectedFilterPreset === filterPreset}
                                onClick={() => {
                                    setSelectedFilterPreset(filterPreset)
                                }}
                            >
                                {filterPreset}
                            </Button>
                            )
                        }
                        
                    </Space>
                </CollapsePanel> */}
            </Collapse>
            <Table<DataType>
                rowClassName={() => "editable-row"}
                scroll={{y: "80vh", x: "max-content"}}
                rowKey={(record) => `${record.ASIN}-${record.SKU}`}
                title={() => (
                    <Space align="center" style={{justifyContent: "space-between", width: "100%", overflow: "auto"}}>
                        <Space split={<Divider type="vertical" />}>
                            <Statistic title="SKUs" value={localFilteredData.length} />
                            <Statistic title="Units" value={localFilteredData.reduce((acc, curr) => (acc += curr.Stock), 0)} />
                            <Statistic
                                title="Fulfil. units"
                                value={localFilteredData.reduce((acc, curr) => (acc += curr.availableInventory), 0)}
                            />
                            {/* <Statistic title="Unavail. units" value={localFilteredData.reduce((acc, curr) => acc += (curr.unavailableInventory), 0)} /> */}
                            <Statistic title="Units sold" value={localFilteredData.reduce((acc, curr) => (acc += curr["Sold (30D)"]), 0)} />
                            <Statistic
                                title="Value sold"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr["Sold (30D)"] * curr["Cost"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Inventory Val."
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr.invValue), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Fulfil. Val."
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr.availableInvValue), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Unavail. Val."
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr.unavailableInvValue), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Profit"
                                prefix={"$"}
                                value={localFilteredData.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0).toFixed(2)}
                            />
                            <Statistic
                                title="Mean BB Share"
                                suffix={"%"}
                                value={localFilteredData
                                    .filter((item) => item.Stock > 0)
                                    .reduce((acc, curr, _, {length}) => (acc += curr.BBShare / length), 0)}
                                precision={2}
                            />
                            <Statistic
                                title="Mean CR"
                                suffix={"%"}
                                value={localFilteredData.reduce((acc, curr, _, {length}) => (acc += Math.max(curr.CR, 0) / length), 0)}
                                precision={2}
                            />
                            <Statistic
                                title="Mean ROI"
                                suffix={"%"}
                                value={
                                    (localFilteredData.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0) /
                                        (localFilteredData.reduce((acc, curr) => (acc += curr.Cost * curr["Sold (30D)"]), 0) || 1)) *
                                    100
                                }
                                precision={2}
                            />
                            <Statistic
                                title="Mean GMROI"
                                value={
                                    (12 * localFilteredData.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0)) /
                                    (localFilteredData.reduce(
                                        (acc, curr) => (acc += curr.Cost * (curr["Sold (30D)"] + curr.availableInventory)),
                                        0
                                    ) || 1)
                                }
                                precision={2}
                            />
                            <Statistic
                                title="Mean ROIC"
                                suffix={"%"}
                                value={
                                    (100 * localFilteredData.reduce((acc, curr) => (acc += curr.Profit * curr["Sold (30D)"]), 0)) /
                                    (localFilteredData.reduce(
                                        (acc, curr) => (acc += ((curr.Stock + curr["Sold (30D)"]) * curr.Cost) / 2),
                                        0
                                    ) || 1)
                                }
                                precision={2}
                            />
                            <Statistic title="Pecty" value={localFilteredData.filter((item) => item.pectyData !== undefined).length} />
                        </Space>
                        <Space>
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => {
                                    setFilteredInfo({});
                                    setTopFilteredInfo({});
                                    setSingularBrowserData(filterOmegaTableData({}, actualTableData));
                                }}
                            >
                                Reset Filters
                            </Button>
                        </Space>
                    </Space>
                )}
                bordered={true}
                size="small"
                dataSource={actualTableData}
                pagination={{
                    pageSizeOptions: ["25", "50", "100"],
                    defaultPageSize: 25,
                    showSizeChanger: true,
                }}
                sticky={{
                    offsetScroll: 0,
                }}
                columns={columns
                    .filter((column) => !hiddenColumns.includes(getColumnTitle(column)))
                    .map((column) => ({
                        ...column,
                        filteredValue: filteredInfo[(column as ColumnType<DataType>).dataIndex! as string] || null,
                    }))}
                showSorterTooltip={false}
                onChange={(pagination, filters, sorter, extra) => {
                    console.log("nice filters looooool holy shit", filters);
                    setFilteredInfo(filters);
                    setTopFilteredInfo(filters);
                    setSingularBrowserData(extra.currentDataSource);
                }}
            ></Table>
            {contextHolder}
        </>
    );
});

export default ItemBrowser;
