import { Incentive } from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import {InboundBoxV2, InboundPackingGroup, InboundPackingOption, InboundShipmentItemV2} from "../../../types/WarehouseTypes";
import {randID} from "../pack-later/boxing/helpers";

export function verifyPackingOption(
    packingOption: InboundPackingOption,
    boxes: InboundBoxV2[],
    items: InboundShipmentItemV2[]
): {
    packingOption: InboundPackingOption;
    packingGroups: InboundPackingGroup[];
    boxes: InboundBoxV2[];
} {
    // Create packing groups from the packing option
    const packingGroups: InboundPackingGroup[] = packingOption.packingGroups.map((groupId) => {
        // Find all items that belong to this packing group
        const expectedItems = items.filter((item) => item.packingGroupId === groupId);

        return {
            packingGroupId: groupId,
            inboundPlanId: packingOption.inboundPlanId,
            packingOptionId: packingOption.packingOptionId,
            expectedItems,
        };
    });

    const localBoxes = boxes
        .filter((b) => b.packingGroupId && b.inboundPlanId === packingOption.inboundPlanId)
        .sort((a, b) => a.number - b.number);

    // Make sure each box has correct numbering within its packing group
    const boxesByGroup: {[key: string]: InboundBoxV2[]} = {};
    localBoxes.forEach((box) => {
        if (box.packingGroupId) {
            if (!boxesByGroup[box.packingGroupId]) {
                boxesByGroup[box.packingGroupId] = [];
            }
            boxesByGroup[box.packingGroupId].push(box);
        }
    });

    // If a packing group has no boxes, create a new box
    packingGroups.forEach((group) => {
        if (!boxesByGroup[group.packingGroupId] || boxesByGroup[group.packingGroupId].length === 0) {
            const newBox: InboundBoxV2 = {
                id: randID(),
                number: 1,
                packingGroupId: group.packingGroupId,
                inboundPlanId: packingOption.inboundPlanId,
                items: [],
            };
            boxesByGroup[group.packingGroupId] = [newBox];
            group.activeBoxId = newBox.id;
            localBoxes.push(newBox);
        }
    });

    // Renumber boxes within each packing group
    Object.values(boxesByGroup).forEach((groupBoxes) => {
        groupBoxes.forEach((box, index) => {
            box.number = index + 1;
        });
    });

    const updatedPackingOption: InboundPackingOption = {
        ...packingOption,
    };

    const lastUpdateTimestamp = packingOption.lastUpdate || 0;
    const packingInformationTimestamp = packingOption.packingInformationTimestamp || 0;
    if (packingOption.placementOptions?.length && lastUpdateTimestamp < packingInformationTimestamp) {
        updatedPackingOption.viewMode = "PLACEMENT";
    } else {
        updatedPackingOption.viewMode = "BOXING";
    }

    return {
        packingOption: updatedPackingOption,
        packingGroups,
        boxes: localBoxes,
    };
}

export const sumIncentives = (incentives: Incentive[]): number => {
    return incentives.reduce((sum, incentive) => sum + (incentive.value?.amount || 0), 0);
};
