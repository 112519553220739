import {Col, Row, Tabs, TabsProps, Select} from "antd";
import React, {useEffect} from "react";
import BrandInformation from "./BrandInformation";
import {useSupplierContext} from "../../contexts/SupplierContext";
import BrandItems from "./BrandItems";
import {useTabLocationDashboard} from "./utilities/queryTab";
import BrandSellthrough from "./BrandSellthrough";

const items: TabsProps["items"] = [
    {
        key: "information",
        label: `Information`,
        children: <BrandInformation></BrandInformation>,
    },
    {
        key: "items",
        label: `Items`,
        children: <BrandItems></BrandItems>,
    },
    {
        key: "sellthrough",
        label: `Sell-through`,
        children: <BrandSellthrough></BrandSellthrough>,
    },
    {
        key: "performance",
        label: `Performance`,
        children: (
            <>
                <iframe
                    title="Online Report"
                    width="100%"
                    height="1000"
                    src="https://app.powerbi.com/view?r=eyJrIjoiN2QwOWFiYTItZDUyNS00ZWJkLWFlM2ItOWM0ODYxYWI0YTFhIiwidCI6IjMxMTU2NjA5LTg3NzgtNGVhNi1hYjhiLWZiMWNkZTI3MDY3NSJ9"
                    frameBorder="0"
                    allowFullScreen={true}
                ></iframe>
            </>
        ),
    },
];

const BrandDashboard: React.FC = () => {
    const {suppliers, selectedSupplier, setSelectedSupplier} = useSupplierContext();
    const [activeKey, onChange] = useTabLocationDashboard(
        items.map((item) => item.key),
        "information",
        "dashboard_tab"
    );

    const [activeSupplier, onSupplierChange] = useTabLocationDashboard(
        (suppliers ?? []).map((supplier) => supplier.name),
        "",
        "selected_supplier"
    );

    useEffect(() => {
        if (activeSupplier) {
            setSelectedSupplier(suppliers.find((supplier) => supplier.name === activeSupplier));
        }
    }, [activeSupplier, setSelectedSupplier, suppliers]);

    return (
        <Row>
            <Col span={24}>
                <Select
                    value={activeSupplier}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children! as unknown as string).toLowerCase().includes(input.toLowerCase())}
                    placeholder="Please select Supplier"
                    onChange={(name: string) => {
                        setSelectedSupplier(suppliers.find((supplier) => supplier.name === name));
                        onSupplierChange(name);
                    }}
                    style={{width: "100%"}}
                    disabled={!suppliers}
                    allowClear
                >
                    {suppliers?.map(({name: Supplier}, idx) => (
                        <Select.Option key={idx} value={Supplier}>
                            {Supplier}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
            {selectedSupplier && (
                <Col span={24}>
                    <Tabs items={items} activeKey={activeKey} onChange={onChange} />
                </Col>
            )}
        </Row>
    );
};

export default BrandDashboard;
