import React, {useEffect, useState} from "react";
import {Button, Space, Progress, Spin} from "antd";
import {InboundPackingGroup} from "../../../types/WarehouseTypes";
import {PlusOutlined} from "@ant-design/icons";
import {useStore} from "../../../store/useStore";
import {useShallow} from "zustand/react/shallow";
import BoxView from "../common/BoxView";
import BoxingRemainingItemsDialog from "../common/BoxingRemainingItemsDialog";
import {useMemo} from "react";
import {calcInboundProgress} from "../common/helpers";

interface PackingGroupViewProps {
    packingGroup: InboundPackingGroup;
    disabled?: boolean;
}

const PackingGroupView: React.FC<PackingGroupViewProps> = ({packingGroup, disabled}) => {
    const [showRemainingItemsDialog, setShowRemainingItemsDialog] = useState(false);
    const {data, actions} = useStore(
        useShallow((state) => ({
            data: state.packFirstBoxingData,
            actions: state.packFirstBoxingActions,
        }))
    );

    const boxes = useMemo(
        () => data.boxes.allIds.map((id) => data.boxes.byId[id]).filter((box) => box.packingGroupId === packingGroup.packingGroupId),
        [data.boxes.allIds, data.boxes.byId, packingGroup.packingGroupId]
    );

    useEffect(() => {
        // If the active box is not in the boxes array, set it to the last box
        if (!packingGroup.activeBoxId || !boxes.find((b) => b.id === packingGroup.activeBoxId)) {
            actions.setActiveBoxId(packingGroup.packingGroupId, boxes[boxes.length - 1].id);
        }
    }, [packingGroup.activeBoxId, boxes, actions, packingGroup.packingGroupId]);

    const progressPercent = useMemo(() => {
        return calcInboundProgress(packingGroup.expectedItems, boxes);
    }, [packingGroup.expectedItems, boxes]);

    return (
        <Spin spinning={disabled} indicator={<></>}>
            <Space direction="vertical" style={{height: "35vw", overflow: "auto", width: "100%"}}>
                <Space>
                    <Button disabled={progressPercent === 100} onClick={() => setShowRemainingItemsDialog(true)}>
                        Items
                    </Button>
                    <Progress percent={progressPercent} size="small" strokeWidth={12} style={{width: 60}} />
                </Space>
                <Space direction="horizontal" wrap>
                    {boxes.map((box) => (
                        <BoxView
                            key={box.id}
                            box={box}
                            isActive={packingGroup.activeBoxId === box.id}
                            onSelect={() => actions.setActiveBoxId(packingGroup.packingGroupId, box.id)}
                            onRemove={() => actions.removeBox(box.id)}
                            onDuplicate={() => actions.duplicateBox(box.id)}
                            onDimensionsUpdate={(boxId, dimensions) => actions.addBoxDimensions(boxId, dimensions)}
                            onItemQuantityChange={(sku, boxId, quantity) => actions.changeItemQuantity(sku, boxId, quantity)}
                            onItemRemove={(sku, boxId) => actions.removeItem(sku, boxId)}
                        />
                    ))}
                    <Button
                        style={{width: 50, height: 50}}
                        icon={<PlusOutlined style={{fontSize: 40}} />}
                        onClick={() => actions.addBox(packingGroup.packingGroupId)}
                    />
                </Space>
                <BoxingRemainingItemsDialog
                    expectedItems={packingGroup.expectedItems}
                    boxes={boxes}
                    open={showRemainingItemsDialog}
                    onCancel={() => setShowRemainingItemsDialog(false)}
                    onSelect={(item, quantity) => {
                        actions.addItem(item, quantity, packingGroup.packingGroupId);
                    }}
                    onRemove={(item, quantity) => {
                        // Implementation for removing items if needed
                    }}
                />
            </Space>
        </Spin>
    );
};

export default PackingGroupView;
