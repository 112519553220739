import {Button, Collapse, DatePicker, Descriptions, Form, Input, Modal, Select, notification} from "antd";
import {Rule} from "antd/es/form";
import {FormProps} from "antd/lib";
import {DescriptionsItemType} from "antd/lib/descriptions";
import React, {useEffect, useState} from "react";
import {FREIGHT_CLASSES, FreightClass} from "../../../../types/WarehouseTypes";
import dayjs from "dayjs";
import {
    ContactInformation,
    FbaInboundApiGenerateTransportationOptionsRequest,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";

interface Props {
    open: boolean;
    transportDetails?: FbaInboundApiGenerateTransportationOptionsRequest;
    onConfirm: (transportDetails: FbaInboundApiGenerateTransportationOptionsRequest) => void;
    onCancel: () => void;
}

const COMMON_RULES: Rule[] = [{required: true, message: "Pleast enter the value"}];

const ConfirmTransportDetailsDialog: React.FC<Props> = ({open, transportDetails, onCancel, onConfirm}) => {
    const [contact, setContact] = useState<ContactInformation | undefined>(undefined);
    const [freightClass, setFreightClass] = useState<FreightClass | undefined>(undefined);
    const [readyToShipDate, setReadyToShipDate] = useState<string | undefined>(undefined);
    const [form] = Form.useForm();

    useEffect(() => {
        if (transportDetails) {
            const transportConfiguration = transportDetails.body.shipmentTransportationConfigurations[0];
            const contactDetails = transportConfiguration.contactInformation;
            setContact(contactDetails);
            form.setFieldsValue(contactDetails);

            const freightClass = transportConfiguration.freightInformation?.freightClass;
            setFreightClass(freightClass as FreightClass);

            const readyToShipDate = transportConfiguration.readyToShipWindow?.start;
            setReadyToShipDate(readyToShipDate);
        }
    }, [transportDetails, form]);

    const transportConfiguration = transportDetails?.body?.shipmentTransportationConfigurations[0];

    const onContactFinish: FormProps<ContactInformation>["onFinish"] = async (values) => {
        setContact(values);
    };

    const items: DescriptionsItemType[] = [
        {
            label: "Contact",
            children: (
                <Collapse
                    size="small"
                    style={{width: "100%"}}
                    items={[
                        {
                            key: "contact",
                            label: contact ? `${contact?.name} (${contact?.phoneNumber}, ${contact?.email})` : "NOT SET",
                            children: (
                                <Form form={form} onFinish={onContactFinish} labelCol={{span: 4}} wrapperCol={{span: 20}}>
                                    <Form.Item label="Name" name="name" rules={COMMON_RULES}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Phone" name="phoneNumber" rules={COMMON_RULES}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Email" name="email" rules={COMMON_RULES}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 4, span: 16}}>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            ),
                        },
                    ]}
                />
            ),
        },
        {
            key: "freightReadyDate",
            label: "Freight Ready Date",
            children: (
                <DatePicker
                    value={dayjs(readyToShipDate)}
                    format={"YYYY-MM-DD"}
                    onChange={(date) => {
                        setReadyToShipDate(date?.toISOString());
                    }}
                />
            ),
        },
        {
            key: "freightClass",
            label: "Freight Class",
            children: (
                <Select
                    style={{width: "30%"}}
                    options={FREIGHT_CLASSES.map((cls) => ({label: cls, value: cls}))}
                    value={freightClass}
                    onChange={(value) => {
                        setFreightClass(value as FreightClass);
                    }}
                />
            ),
        },
        {
            key: "value",
            label: "Declared Value",
            children: `$${transportConfiguration?.freightInformation?.declaredValue?.amount.toFixed(2)}`,
        },
    ];

    return (
        <Modal
            title="Transport details"
            open={open}
            onCancel={onCancel}
            footer={
                <>
                    <Button onClick={onCancel}>Close</Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            const transportConfiguration = transportDetails?.body.shipmentTransportationConfigurations[0];
                            if (transportConfiguration?.pallets && !contact) {
                                notification.error({message: "Contact information is required"});
                                return;
                            }
                            if (!transportDetails || !transportConfiguration) {
                                notification.error({message: "Transport details not found"});
                                return;
                            }
                            if (!readyToShipDate) {
                                notification.error({message: "Ready to ship date is required"});
                                return;
                            }
                            const transportDetailsLocal: FbaInboundApiGenerateTransportationOptionsRequest = {
                                ...transportDetails,
                                body: {
                                    ...transportDetails.body,
                                    shipmentTransportationConfigurations: [
                                        {
                                            ...transportConfiguration,
                                            contactInformation: contact,
                                            freightInformation: {
                                                ...transportConfiguration.freightInformation,
                                                freightClass,
                                            },
                                            readyToShipWindow: {
                                                ...transportConfiguration.readyToShipWindow,
                                                start: readyToShipDate,
                                            },
                                        },
                                    ],
                                },
                            };
                            onConfirm(transportDetailsLocal);
                        }}
                    >
                        Confirm
                    </Button>
                </>
            }
            width={"50vw"}
        >
            <Descriptions labelStyle={{width: "20%"}} bordered column={1} items={items} />
        </Modal>
    );
};

export default ConfirmTransportDetailsDialog;
