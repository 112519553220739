import React, {useMemo, useState} from "react";
import {Modal, Button, Row, Col, Descriptions, Card, List, Space} from "antd";
import {InboundShipmentV2} from "../../../../types/WarehouseTypes";
import {TransportationOption} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model-v20240320";
import dayjs from "dayjs";

interface TransportationOptionViewProps {
    option: TransportationOption;
    selected: boolean;
    onSelect: () => void;
}

const TransportationOptionView: React.FC<TransportationOptionViewProps> = ({option, selected, onSelect}) => {
    return (
        <Card
            style={{
                // Add border to every item to prevent the list from jumping, only change color if selected
                backgroundColor: selected ? "#28682E44" : undefined,
                borderRadius: "5px",
                width: "98%",
            }}
        >
            <Row style={{width: "100%", paddingLeft: 4}}>
                <Col span={20}>
                    <Descriptions
                        bordered
                        items={[
                            {
                                label: "Quote",
                                children: (
                                    <Space style={{width: 70}}>
                                        {option.quote?.cost.amount ? `$${option.quote?.cost.amount.toFixed(2)}` : "N/A"}
                                    </Space>
                                ),
                            },
                            {
                                label: "Carrier Name",
                                children: <Space style={{width: 150}}>{option.carrier.name}</Space>,
                            },
                            {
                                label: "Carrier Appointment",
                                children: `${dayjs(option.carrierAppointment?.startTime).format("YYYY-MM-DD HH:mm")} - ${dayjs(
                                    option.carrierAppointment?.endTime
                                ).format("YYYY-MM-DD HH:mm")}`,
                            },
                        ]}
                    />
                </Col>
                <Col span={4}>
                    <Space style={{width: "100%", justifyContent: "center"}}>
                        <Button onClick={onSelect}>{selected ? "Selected" : "Select"}</Button>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
};

interface TransportationOptionsDialogProps {
    open: boolean;
    shipment: InboundShipmentV2;
    onClose: () => void;
    onConfirm: (option: TransportationOption) => void;
}

const TransportationOptionsDialog: React.FC<TransportationOptionsDialogProps> = ({open, onClose, onConfirm, shipment}) => {
    const [selectedOption, setSelectedOption] = useState<TransportationOption | undefined>();
    const options = useMemo(
        () =>
            shipment.transportationOptions?.filter(
                (o) => o.shippingSolution === "AMAZON_PARTNERED_CARRIER" && o.shippingMode === "FREIGHT_LTL"
            ),
        [shipment]
    );

    return (
        <Modal
            title="Transportation Options"
            open={open}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    key="confirm"
                    type="primary"
                    onClick={() => {
                        if (selectedOption) {
                            onConfirm(selectedOption);
                        }
                    }}
                    disabled={!selectedOption || shipment?.confirmTransportationOptionsStatus?.operationStatus === "SUCCESS"}
                >
                    Confirm
                </Button>,
            ]}
            width={"80vw"}
        >
            <List
                dataSource={options}
                style={{maxHeight: "70vh", overflow: "auto"}}
                renderItem={(option) => (
                    <TransportationOptionView
                        option={option}
                        selected={option.transportationOptionId === selectedOption?.transportationOptionId}
                        onSelect={() => {
                            setSelectedOption(option);
                        }}
                    />
                )}
            />
        </Modal>
    );
};

export default TransportationOptionsDialog;
