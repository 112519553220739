import React, {useMemo} from "react";
import {Modal, Table, Skeleton, Avatar, Typography} from "antd";
import {InboundBoxV2, InboundShipmentItemV2} from "../../../types/WarehouseTypes";
import {ColumnsType} from "antd/lib/table";
import {countItemsByKey} from "../pack-later/boxing/helpers";
import QuantityButton from "../pack-later/boxing/QuantityButton";

interface BoxingRemainingItemsDialogProps {
    expectedItems: InboundShipmentItemV2[];
    boxes: InboundBoxV2[];
    open: boolean;
    onCancel: () => void;
    onSelect: (item: InboundShipmentItemV2, quantity: number) => void;
    onRemove: (item: InboundShipmentItemV2, quantity: number) => void;
    title?: string;
}

const BoxingRemainingItemsDialog: React.FC<BoxingRemainingItemsDialogProps> = ({
    expectedItems,
    boxes,
    open,
    onCancel,
    onSelect,
    onRemove,
    title,
}) => {
    const columns: ColumnsType<InboundShipmentItemV2> = [
        {
            title: "Image",
            dataIndex: "imgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "150px",
            render: (name) => (
                <Typography.Text ellipsis style={{width: "150px"}}>
                    {name}
                </Typography.Text>
            ),
        },
        {
            title: "UPC",
            dataIndex: "upc",
            key: "upc",
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
        },
        {
            title: "FNSKU",
            dataIndex: "fnsku",
            key: "fnsku",
        },
        {
            title: "SKU",
            dataIndex: "msku",
            key: "sku",
            width: "150px",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Action",
            key: "action",
            render: (item: InboundShipmentItemV2) => (
                <QuantityButton
                    title="Add"
                    initialQuantity={item.quantityLeft || 1}
                    maxQuantity={item.quantityLeft || 1}
                    onAdd={(quantity) => onSelect(item, quantity)}
                    onDelete={(quantity) => {
                        onRemove(item, quantity);
                    }}
                />
            ),
        },
    ];

    const remainingItems = useMemo(() => {
        const boxed = countItemsByKey(boxes);

        const allItems = expectedItems.map((item) => ({
            ...item,
            quantityLeft: item.quantity - (boxed[item.msku] || 0),
        }));
        return allItems.filter((item) => item.quantityLeft > 0);
    }, [boxes, expectedItems]);

    return (
        <Modal title={title || "Remaining items"} open={open} onCancel={onCancel} footer={null} width={"85vw"}>
            <Table
                style={{height: "50vh", overflow: "auto"}}
                dataSource={remainingItems}
                columns={columns}
                rowKey="msku"
                pagination={false}
            />
        </Modal>
    );
};

export default BoxingRemainingItemsDialog;
