import { WholesaleDataType } from "./table/WholesaleColumns";
import dayjs from "dayjs";
import { OMSItem } from "../../types/OmegaTypes";
import { findSales } from "../brand/AnalyzeData";
import { RevFeeFromSellerSnap } from "../omega/BrandCalcs";
import { GetCategoryEstSalesData } from "../brand/SalesRankConstants";

export const PRICE_PER_DIMPOUND = 0.25;
const AZ_VOLUME_DIMPOUND_RATIO = 139;

const GMROI = [
    {
        ROI: 25,
        Days: 30,
    },
    {
        ROI: 50,
        Days: 61,
    },
    {
        ROI: 250,
        Days: 180,
    },
];

const MINIMUM_PROFIT = (group: string) => {
    switch (group) {
        case "Shoes":
            return 5;
        default:
            return 2.5;
    }
};

export const getRefFeeValueWS = ({ sellPrice, rootCategory, productGroup, treeCategory }: WholesaleDataType) => {
    if (sellPrice > 0) {
        if (treeCategory?.toLowerCase().includes("clothing")) {
            return Math.max(sellPrice * 0.17, 0.3);
        }

        if (rootCategory === "Grocery & Gourmet Food" && sellPrice < 15) {
            return Math.max(sellPrice * 0.08, 0.3);
        }

        if (rootCategory === "Electronics" || treeCategory?.toLowerCase().includes("battery")) {
            return Math.min(100, sellPrice) * 0.15 + 0.08 * Math.max(sellPrice - 100, 0);
        }

        if (rootCategory === "Health & Household") {
            return Math.max(sellPrice > 10 ? sellPrice * 0.08 : sellPrice * 0.15, 0.3);
        }

        if (treeCategory?.toLowerCase().includes("power & hand tools")) {
            return Math.max(sellPrice * 0.12, 0.3);
        }

        if (productGroup === "Apparel") {
            if (sellPrice < 15) {
                return Math.max(sellPrice * 0.05, 0.3);
            } else if (sellPrice < 20) {
                return Math.max(sellPrice * 0.1, 0.3);
            } else {
                return Math.max(sellPrice * 0.17, 0.3);
            }
        }

        return Math.max(sellPrice * 0.15, 0.3);
    } else {
        return -1;
    }
};

const approxMVSP = (totalCost: number, rootCategory: string, productGroup: string, treeCategory: any) => {
    let currTotal = totalCost;
    let refFee = getRefFeeValueWS({ sellPrice: totalCost, rootCategory, productGroup, treeCategory } as WholesaleDataType);
    for (let i = 0; i < 7; i++) {
        refFee = getRefFeeValueWS({ sellPrice: totalCost + refFee, rootCategory, productGroup, treeCategory } as WholesaleDataType);
    }

    return currTotal + refFee;
};

export const RecalculateProfits = (row: WholesaleDataType) => {
    // Ensure we're still working on numbers
    row.cost = parseFloat(row.cost as unknown as string);
    row.BuyQTY = parseFloat(row.BuyQTY as unknown as string);
    row.AskPrice = parseFloat(row.AskPrice as unknown as string);
    row.sellPrice = parseFloat(row.sellPrice as unknown as string);
    row.refFeeWS = getRefFeeValueWS(row);

    // Determine referral fee and if it still matches current sell price
    if (row.refFeeSPAPI && row.refFeeSPAPIBase === row.sellPrice) {
        row.refFee = row.refFeeSPAPI;
    } else {
        row.refFee = row.refFeeWS;
    }

    row.shippingCost =
        row.editedShippingCost ??
        (row.shipDimWeight > 0 ? row.shipDimWeight * PRICE_PER_DIMPOUND : row.adjustedWeight * row.shippingFeeMulti + row.shippingFeeBase);

    // Check for S&L violation (must be under $12)
    if (row.sellPrice > 12 && row.SmallLightFeeApplied) {
        row.prepFee = row.Fees;
        row.SmallLightFeeApplied = false;
    }

    // Find out storage fee
    if (row.SizeTier !== "Unknown") {
        const volume = row.packVol / 1728;
        let storageFeeRate;

        if (row.SizeTier.includes("oversize")) {
            // 0.56 per cubic feet in january-september, 1.40 otherwise
            storageFeeRate = row.Q4StorageFee ? 1.4 : 0.56;
        } else {
            // 0.87 per cubic feet in january september, 2.40 otherwise
            storageFeeRate = row.Q4StorageFee ? 2.4 : 0.87;
        }
        row.storageFeeRate = storageFeeRate;
        row.storageFee = storageFeeRate * volume; // 45 days, but on average only half of the units will incur the charge
    } else {
        row.storageFeeRate = -1;
        row.storageFee = 0.5;
    }

    // Re-calculate ROI
    if (row.sellPrice !== -1 && row.AskPrice > 0) {
        row.ROI = parseFloat(
            (
                ((row.sellPrice - row.refFee - row.totalReturnCost - row.storageFee - row.AskPrice - row.prepFee) / row.AskPrice) *
                100
            ).toFixed(0)
        );
        row.ROI2 = parseFloat(
            (
                ((row.sellPrice - row.refFee - row.totalReturnCost - row.storageFee - row.AskPrice - row.shippingCost - row.prepFee) /
                    row.AskPrice) *
                100
            ).toFixed(0)
        );
        row.Profit = parseFloat(
            (row.sellPrice - row.refFee - row.totalReturnCost - row.storageFee - row.shippingCost - row.prepFee - row.AskPrice).toFixed(2)
        );

        row.ProfitGMROI = parseFloat(
            (
                row.sellPrice -
                row.refFee -
                row.totalReturnCost -
                row.storageFee -
                row.shippingCost -
                row.prepFee -
                row.AskPrice * (row.ccAccepted ? 0.98 : 1)
            ).toFixed(2)
        );
        // Custom Lucas Column ProfitStFee
        row.ProfitStFee = row.Profit / row.storageFee;
    } else {
        row.ROI = -2;
        row.ROI2 = -2;
        row.Profit = -2;
        row.ProfitGMROI = -2;
        row.ProfitStFee = -2;
    }

    // Find minimum viable prices (for reaching the desired ROI)
    if (row.desiredRoi > 0) {
        if (row.sellPrice > 0) {
            row.MaximumViableCost = parseFloat(
                (
                    (row.sellPrice - row.refFee - row.totalReturnCost - row.storageFee - row.shippingCost - row.prepFee) /
                    (1 + row.desiredRoi)
                ).toFixed(2)
            );
            // find how big of a discount we need to apply to the AskPrice to reach the MaximumViableCost
            row.DiscountNeeded = Math.max(0, parseInt((((row.AskPrice - row.MaximumViableCost) / row.AskPrice) * 100).toFixed(0)));
        } else {
            row.MaximumViableCost = -1;
            row.DiscountNeeded = -1;
        }
        if (row.AskPrice > 0) {
            let totalCost: number =
                row.AskPrice * (1 + row.desiredRoi) + row.shippingCost + row.totalReturnCost + row.storageFee + row.prepFee;
            let breakevenCost: number = row.AskPrice * 1.02 + row.shippingCost + row.totalReturnCost + row.storageFee + row.prepFee; // 2% ROI buffer for breakeven
            row.MinimumViableSellPrice = parseFloat(approxMVSP(totalCost, row.rootCategory, row.productGroup, row.treeCategory).toFixed(2));
            row.BreakevenSellPrice = parseFloat(approxMVSP(breakevenCost, row.rootCategory, row.productGroup, row.treeCategory).toFixed(2));
        } else {
            row.MinimumViableSellPrice = -1;
            row.BreakevenSellPrice = -1;
        }
    } else {
        row.MinimumViableSellPrice = -1;
        row.MaximumViableCost = -1;
        row.DiscountNeeded = -1;
    }
};

export const FindBuyQTY = (row: WholesaleDataType) => {
    if (row.ROI2 >= (row.desiredRoi * 100 || 25) && MINIMUM_PROFIT(row.productGroup) < row.Profit) {
        const lowerBoundary = GMROI.find((pred) => pred.ROI > row.ROI2);
        const higherBoundary = [...GMROI].reverse().find((pred) => pred.ROI <= row.ROI2);

        if (lowerBoundary && higherBoundary) {
            row.DaysToBuy =
                lowerBoundary.Days +
                (row.ROI2 - lowerBoundary.ROI) * ((higherBoundary.Days - lowerBoundary.Days) / (higherBoundary.ROI - lowerBoundary.ROI));
        } else {
            row.DaysToBuy = lowerBoundary?.Days || higherBoundary?.Days;
        }
    } else {
        row.DaysToBuy = -1;
    }

    const DaysToBuy = row.targetDIS >= 0 ? row.targetDIS : row.DaysToBuy;

    if (DaysToBuy > 0 && row.EstSales! > 0 && row.DaysToBuy > -1) {
        if (row.InvQuantity && row.InvQuantity > 0) {
            row.SuggestedQTY = Math.max(Math.round((row.EstSales! / 30) * DaysToBuy - 0.75 * row.InvQuantity), 0);
        } else {
            row.SuggestedQTY = Math.round((row.EstSales! / 30) * DaysToBuy);
        }
    } else {
        row.SuggestedQTY = 0;
    }
};

export const FindWsTTS = (row: WholesaleDataType) => {
    if (row.BuyQTY >= 0 || row.SuggestedQTY > 0) {
        const toBuy = row.BuyQTY >= 0 ? row.BuyQTY : row.SuggestedQTY;
        const dailySales = row.EstSales! / 30;
        const velocity = row.Velocity;

        row.wsTTS =
            dailySales > 0
                ? Math.round(
                    Math.max((toBuy + (row.InvQuantity ?? 0) + (row.IncomingQuantity30D ?? 0) + (row.ShipLater ?? 0)) / dailySales, 0)
                )
                : -1;
        row.wsVelTTS =
            velocity > 0
                ? Math.round(
                    Math.max((toBuy + (row.InvQuantity ?? 0) + (row.IncomingQuantity30D ?? 0) + (row.ShipLater ?? 0)) / velocity, 0)
                )
                : -1;
    } else {
        row.wsTTS = -1;
        row.wsVelTTS = -1;
    }

    const minTTS = [row.wsTTS, row.wsVelTTS].filter((val) => val >= 0);
    row.wsMinTTS = minTTS.length > 0 ? Math.min(...minTTS) : -1;

    row.wsGMROITTS = -1;
    if (row.wsVelTTS > 0 && row.DIS > 7) {
        row.wsGMROITTS = row.wsVelTTS;
    } else if (row.wsTTS >= 0) {
        row.wsGMROITTS = row.wsTTS;
    }

    if (row.wsGMROITTS >= 0) {
        row.wsGMROI = parseFloat(
            (
                (row.ProfitGMROI / (row.AskPrice + row.shippingCost)) *
                (365 / Math.max(row.wsGMROITTS + row.prepDelay - row.netTerm, 1))
            ).toFixed(2)
        );
    } else {
        row.wsGMROI = -1;
    }

    // FINAL FIXEZ TO ZE ITEMZ
    row.MinPrice = row.MinPrice ? parseFloat(row.MinPrice) : row.MinimumViableSellPrice ?? -1;
    row.MaxPrice = row.MaxPrice ? parseFloat(row.MaxPrice) : row.lowestPrice > 0 ? row.lowestPrice * 2 : -1;
    row["Seasonal Tags"] = row["Seasonal Tags"] ?? "";
    row["Bought in past month"] = parseInt(row["Bought in past month"] || -1);

    // AFTER-CALCS SUMMARIES PLS GET THIS OUT OF HERE LATER
    row.OrderAmount = row.BuyQTY * row.AskPrice;

    // CUSTOM ADIDAS CALCS WTFFFF
    if (row.Supplier_RunningQtySum && row.Supplier_SinceFirstShip && row.EstSales! > 0) {
        if (row.Supplier_SinceFirstShip === "-1") {
            row.adidasStockAvail = -2;
        } else {
            row.adidasStockAvail =
                (parseInt(row.Supplier_RunningQtySum) /
                    (parseInt(row.Supplier_SinceFirstShip) * ((row.EstSales! / 30 / row.bbMultiplier) * 0.5))) *
                100;
        }
    }
};

export const FindSellPrice = (row: WholesaleDataType) => {
    row.sellPrice = -1;

    if (row.buyBox30Avg > 0 && row.currentBuyBoxPrice > 0) {
        row.sellPrice = (row.buyBox30Avg + row.currentBuyBoxPrice) / 2;
    } else if (row.buyBox30Avg > 0) {
        row.sellPrice = row.buyBox30Avg;
    } else if (row.buyBox90Avg > 0) {
        row.sellPrice = row.buyBox90Avg;
    } else if (row.currentBuyBoxPrice > 0) {
        row.sellPrice = row.currentBuyBoxPrice;
    } else {
        row.sellPrice = -1;
    }

    if (row.sellPrice > row.currentBuyBoxPrice && row.currentBuyBoxPrice !== -1) {
        row.sellPrice = row.currentBuyBoxPrice;
    }
};

export const FindEstSales = (row: WholesaleDataType & { calcRank: number | undefined }) => {
    // @ts-ignore why would I even need to do this jesus christ
    row.EstSalesTotal = findSales(row) * (GetCategoryEstSalesData().categorySales[row.rootCategory] as number);
    row.salesRankDrops =
        row.salesRankDrops30Days !== undefined && row.salesRankDrops90Days !== undefined
            ? row.salesRankDrops30Days * 0.75 + 0.25 * (row.salesRankDrops90Days / 3)
            : -1;
    row.salesRankDropsUsed = false;

    if ((!row.parentASIN || row.parentASIN === row.ASIN) && row.EstSalesTotal < 15 && row.salesRankDrops > 0) {
        row.EstSalesTotal = row.salesRankDrops30Days * 0.75 + 0.25 * (row.salesRankDrops90Days / 3);
        row.EstSales = row.EstSalesTotal * row.bbMultiplier * row.reviewMultiplier;
        row.salesRankDropsUsed = true;
    } else {
        if (row.EstSalesTotal > -1) {
            if (row.reviewMultiplier < 1) {
                row.EstSales = row.EstSalesTotal! * row.bbMultiplier * row.reviewMultiplier;
            } else {
                row.EstSales =
                    (row.EstSalesTotal! / row.VariationCount) *
                    row.bbMultiplier *
                    row.colorMultiplier *
                    row.sizeMultiplier *
                    row.reviewMultiplier;
            }
        } else {
            row.EstSalesTotal = -1;
            row.EstSales = -1;
        }
    }

    if (row.EstSales > 0 && row.bbMultiplier > 0) {
        row.EstChildSales = (row.EstSales / row.bbMultiplier) * row.sellPrice;
        row.EstChildSpend = (row.EstSales / row.bbMultiplier) * row.AskPrice;
        row.EstChildUnitSales = row.EstSales / row.bbMultiplier;
    } else {
        row.EstChildSales = 0;
        row.EstChildSpend = 0;
        row.EstChildUnitSales = 0;
    }
};

export const FindReplenParams = (row: WholesaleDataType) => {
    let fees = 0;
    let replenFees = 0;
    let ssItemCount = 0;
    for (const ssItem of row.ssItems) {
        const fulfillmentFee = parseFloat(ssItem.fulfillment_channel === "FBA" ? ssItem.fulfillment_cost : "0");
        row.replenPrepFee = fulfillmentFee > 0 ? fulfillmentFee : ssItem.fulfillment_channel === "FBA" ? row.prepFee : 0;
        if (ssItem.avg_selling_price !== null) {
            const refFee = RevFeeFromSellerSnap(row.AvgSellPrice, ssItem);
            replenFees += refFee;
            fees += refFee + row.replenPrepFee;
            ssItemCount++;
        }
    }
    fees /= ssItemCount;
    row.replenRefFee = replenFees / ssItemCount;
    row.replenProfit = row.AvgSellPrice - row.AskPrice - fees - row.shippingCost - row.totalReturnCost - row.storageFee;
    row.replenROI = parseFloat(((row.replenProfit / row.AskPrice) * 100).toFixed(2));

    if (row.BBShare > 0 && row.DIS > 0) {
        row.replenAllSales = parseFloat(((row.OrdersAll * (30 / row.DIS) * row.anticipatedVelocity * 100) / row.BBShare).toFixed(2));
        row.replenEstSales = parseFloat((row.replenAllSales * row.replenBBMultiplier).toFixed(2));
    } else {
        row.replenAllSales = row.Orders30D;
        row.replenEstSales = row.Orders30D;
    }

    const EstSales: number = row.replenEstSales / 30;
    const IncomingQuantity = row.OMSItems.filter(
        (item: OMSItem) =>
            (dayjs().diff(dayjs(item.ShipDate), "day") <= 0 - row.prepDelay &&
                dayjs().diff(dayjs(item.ShipDate), "day") >= -60 - row.prepDelay) ||
            (item.ShipDateType === "SR" && dayjs().diff(dayjs(item.ShipDate), "day") >= 0)
    ).reduce((acc: number, curr: OMSItem) => (acc += curr.Quantity || 0), 0);

    row.replenIncoming = IncomingQuantity;
    if (row.poEvalIncomingSubtractOH) {
        row.replenIncoming -= row.onHand;
    }
    row.replenTTS = EstSales > 0 ? Math.round((row.InvAvailableQuantity + +row.replenShipLaterInclude * row.ShipLater) / EstSales) : 365;

    let DaysToBuy = 0;

    if (row.targetDIS >= 0) {
        if (row.replenROI >= (row.desiredRoi * 100 || 25)) {
            DaysToBuy = row.targetDIS;
        } else {
            DaysToBuy = -1;
        }
    } else if (row.replenROI >= (row.desiredRoi * 100 || 25) && MINIMUM_PROFIT(row.productGroup) < row.replenProfit) {
        const lowerBoundary = GMROI.find((pred) => pred.ROI > row.replenROI);
        const higherBoundary = [...GMROI].reverse().find((pred) => pred.ROI <= row.replenROI);

        if (lowerBoundary && higherBoundary) {
            DaysToBuy =
                lowerBoundary.Days +
                (row.replenROI - lowerBoundary.ROI) *
                ((higherBoundary.Days - lowerBoundary.Days) / (higherBoundary.ROI - lowerBoundary.ROI));
        } else {
            DaysToBuy = lowerBoundary?.Days || higherBoundary!.Days;
        }
    } else {
        DaysToBuy = -1;
    }

    DaysToBuy = Math.round(DaysToBuy);
    row.replenTargetDTB = DaysToBuy;

    if (DaysToBuy > 0 && EstSales > 0) {
        if (row.replenTTS >= row.prepDelay) {
            DaysToBuy = DaysToBuy - row.replenTTS + row.prepDelay;
        }

        row.replenToBuy =
            Math.max(Math.round(EstSales * DaysToBuy - +row.replenIncomingInclude * row.replenIncoming), 0) *
            (1 - +!row.canEditCancel * 0.1);
    } else {
        row.replenToBuy = 0;
    }

    row.replenDaysToBuy = DaysToBuy;
};

export const FindSales = (row: WholesaleDataType) => {
    if (!row.calcRank || row.calcRank === -1) {
        let calcRank = -1;

        if (row.currentSalesRank > 0 && row.salesRank30Avg > 0) {
            calcRank = (row.currentSalesRank + row.salesRank30Avg) / 2;
        } else if (row.currentSalesRank > 0) {
            calcRank = row.currentSalesRank;
        } else if (row.salesRank30Avg > 0) {
            calcRank = row.salesRank30Avg;
        }

        row.calcRank = calcRank;
    }
};

export const FindStockMetrics = (row: WholesaleDataType) => {
    if (row.StockData) {
        row.stockBelow = row.StockData.filter((offer) => offer.offerPrice < row.sellPrice && offer.freshStock !== -1).reduce(
            (acc, curr) => acc + curr.freshStock,
            0
        );
        row.estTTBB = row.stockBelow / (row.EstSales! / 30);
    } else {
        row.stockBelow = -1;
        row.estTTBB = -1;
    }

    if (row.BuyQTY || row.SuggestedQTY > 0) {
        row.GMROI =
            (row.Profit / (row.AskPrice + row.shippingCost)) *
            (365 / ((row.BuyQTY || row.SuggestedQTY) / (row.EstSales! / 30) + Math.max(row.estTTBB, 0) + (row.prepDelay || 14))); // 14 = base prep delay, 365 = a year
    } else {
        row.GMROI = -999;
    }

    if (row.azStock !== -1 && row.EstSales! > 0) {
        row.azTTS = row.azStock / (row.EstSales! / (row.bbMultiplier || 1) / 30);
    } else {
        row.azTTS = -1;
    }
};

export const FindSizeTier = (product: {
    packWidth: number;
    packHeight: number;
    packLength: number;
    adjustedWeight: number;
    shipDimWeight?: number;
    packVol?: number;
}): [string, number] => {
    const dims = [product.packWidth || 0, product.packHeight || 0, product.packLength || 0];

    dims.sort(function (a, b) {
        return a - b;
    });

    const weight = product.adjustedWeight || 0;

    if (weight > 0 && dims[2] > 0) {
        const dimWeight =
            (Math.max(product.packWidth, 2) * Math.max(product.packHeight, 2) * product.packLength) / AZ_VOLUME_DIMPOUND_RATIO;
        const shipDimWeight = Math.max(weight, dimWeight);
        product.shipDimWeight = Math.max(weight, dimWeight);
        product.packVol = product.packWidth * product.packHeight * product.packLength;

        //Small standard-size
        if (weight <= 0.75) {
            if (dims[2] <= 15 && dims[1] <= 12 && dims[0] <= 0.75) {
                return ["Small", weight];
            }
        }

        //Large standard-size
        if (weight <= 20) {
            if (dims[2] <= 18 && dims[1] <= 14 && dims[0] <= 8) {
                return ["Large", shipDimWeight];
            }
        }

        //Small oversize
        const girth = (dims[0] + dims[1]) * 2;
        if (weight <= 70) {
            if (dims[2] <= 60 && dims[1] <= 30 && dims[2] + girth <= 130) {
                return ["Small oversize", shipDimWeight];
            }
        }

        //Medium overisze
        if (weight <= 150) {
            if (dims[2] <= 108 && dims[2] + girth <= 130) {
                return ["Medium oversize", shipDimWeight];
            }
        }

        //Large oversize
        if (weight <= 150) {
            if (dims[2] + girth <= 165) {
                return ["Large oversize", shipDimWeight];
            }
        }

        // If we're special oversize, go back to unit weight for shipping weight, reference https://sellercentral.amazon.com/help/hub/reference/GEVWP48HPBLEFJEY
        return ["Special oversize", weight];
    } else {
        return ["Unknown", -1];
    }
};

const FindReturnFee = (sizeTier: string, weight: number) => {
    const ounceWeight = weight * 16;
    const lbWeight = weight;
    switch (sizeTier) {
        case "Small":
            if (ounceWeight <= 4) {
                return 2.12;
            } else if (ounceWeight <= 8) {
                return 2.16;
            } else if (ounceWeight <= 12) {
                return 2.23;
            } else if (ounceWeight <= 16) {
                return 2.32;
            }
            return -1;
        case "Large":
            if (ounceWeight <= 4) {
                return 2.4;
            } else if (ounceWeight <= 8) {
                return 2.44;
            } else if (ounceWeight <= 12) {
                return 2.53;
            } else if (ounceWeight <= 16) {
                return 2.61;
            } else if (lbWeight <= 1.5) {
                return 2.71;
            } else if (lbWeight <= 2) {
                return 2.81;
            } else if (lbWeight <= 2.5) {
                return 2.95;
            } else if (lbWeight <= 3) {
                return 3.13;
            } else if (lbWeight <= 20) {
                return 3.41 + (lbWeight - 3) * 0.2;
            }
            return -1;
        case "Unknown":
            return -1;
        default:
            if (sizeTier.includes("Small oversize")) {
                return 4.19 + Math.max(lbWeight - 2, 0) * 0.2;
            } else if (sizeTier.includes("Medium oversize")) {
                return 10.57 + Math.max(lbWeight - 2, 0) * 0.25;
            } else if (sizeTier.includes("Large oversize")) {
                return 43.7 + Math.max(lbWeight - 90, 0) * 0.25;
            } else if (sizeTier.includes("Special oversize")) {
                return 75.08 + Math.max(lbWeight - 90, 0) * 0.25;
            }
            return -1;
    }
};

export const FindFulfillmentFee = (sizeTier: string, weight: number, peakRate: boolean, productGroup: string = "Apparel") => {
    const isApparel = productGroup === "Apparel";
    const ounceWeight = weight * 16;
    const lbWeight = weight;

    switch (sizeTier) {
        case "Small":
            const peakRateAddon = peakRate ? 0.2 : 0;
            if (ounceWeight <= 4) {
                return (isApparel ? 3.43 : 3.22) + peakRateAddon;
            } else if (ounceWeight <= 8) {
                return (isApparel ? 3.43 : 3.4) + peakRateAddon;
            } else if (ounceWeight <= 12) {
                return (isApparel ? 3.43 : 3.58) + peakRateAddon;
            } else if (ounceWeight <= 16) {
                return (isApparel ? 3.43 : 3.77) + peakRateAddon;
            }
            return -1;
        case "Large":
            const smallPeakRateAddon = peakRate ? 0.3 : 0;
            const largePeakRateAddon = peakRate ? 0.5 : 0;
            if (ounceWeight <= 4) {
                return (isApparel ? 4.43 : 3.86) + smallPeakRateAddon;
            } else if (ounceWeight <= 8) {
                return (isApparel ? 4.63 : 4.08) + smallPeakRateAddon;
            } else if (ounceWeight <= 12) {
                return (isApparel ? 4.84 : 4.24) + smallPeakRateAddon;
            } else if (ounceWeight <= 16) {
                return (isApparel ? 5.32 : 4.75) + smallPeakRateAddon;
            } else if (lbWeight <= 1.5) {
                return (isApparel ? 6.1 : 5.4) + smallPeakRateAddon;
            } else if (lbWeight <= 2) {
                return (isApparel ? 6.37 : 5.69) + smallPeakRateAddon;
            } else if (lbWeight <= 2.5) {
                return (isApparel ? 6.83 : 6.1) + largePeakRateAddon;
            } else if (lbWeight <= 3) {
                return (isApparel ? 7.05 : 6.39) + largePeakRateAddon;
            } else if (lbWeight <= 20) {
                return 7.17 + largePeakRateAddon + (lbWeight - 3) * 2 * 0.16; // 0.16 per half-lb above first 3 lb
            }
            return -1;
        case "Unknown":
            return -1;
        default:
            if (sizeTier.includes("Small oversize")) {
                return 9.73 + (peakRate ? 1.0 : 0) + Math.max(lbWeight - 1, 0) * 0.42;
            } else if (sizeTier.includes("Medium oversize")) {
                return 19.05 + (peakRate ? 2.5 : 0) + Math.max(lbWeight - 1, 0) * 0.42;
            } else if (sizeTier.includes("Large oversize")) {
                return 89.98 + (peakRate ? 2.5 : 0) + Math.max(lbWeight - 90, 0) * 0.83;
            } else if (sizeTier.includes("Special oversize")) {
                return 158.49 + (peakRate ? 2.5 : 0) + Math.max(lbWeight - 90, 0) * 0.83;
            }
            return -1;
    }
};

const FindRemovalFee = (sizeTier: string, weight: number) => {
    if (sizeTier.includes("oversize")) {
        if (weight <= 1) {
            return 3.12;
        } else if (weight <= 2) {
            return 4.07;
        } else if (weight <= 4) {
            return 5.56;
        } else if (weight <= 10) {
            return 9.43;
        } else {
            return 13.05 + (weight - 10) * 1.06;
        }
    } else {
        if (weight <= 0.5) {
            return 0.97;
        } else if (weight <= 1) {
            return 1.46;
        } else if (weight <= 2) {
            return 2.2;
        } else {
            return 2.83 + (weight - 2) * 1.06;
        }
    }
};

// reference https://sellercentral.amazon.com/help/hub/reference/G201706140
export const CheckSmallLight = (product: WholesaleDataType) => {
    if (
        product.packLength <= 18 &&
        product.packLength <= 14 &&
        product.packHeight <= 8 &&
        product.shipDimWeight < 3 &&
        product.shipDimWeight >= 0
        // && product.sellPrice < 12 // maybe make this start suggesting at 13, but need to clear
    ) {
        const ounceWeight = product.shipDimWeight * 16;
        const lbWeight = product.shipDimWeight;
        switch (product.SizeTier) {
            case "Small":
                if (ounceWeight <= 4) {
                    return 2.47;
                } else if (ounceWeight <= 8) {
                    return 2.54;
                } else if (ounceWeight <= 12) {
                    return 2.61;
                } else if (ounceWeight <= 16) {
                    return 3.15;
                }
                return -1;
            case "Large":
                if (ounceWeight <= 4) {
                    return 2.66;
                } else if (ounceWeight <= 8) {
                    return 2.77;
                } else if (ounceWeight <= 12) {
                    return 2.94;
                } else if (ounceWeight <= 16) {
                    return 3.77;
                } else if (lbWeight <= 1.5) {
                    return 4.42;
                } else if (lbWeight <= 2) {
                    return 4.68;
                } else if (lbWeight <= 2.5) {
                    return 5.19;
                } else if (lbWeight <= 3) {
                    return 5.4;
                }
                return -1;
            default:
                return -1;
        }
    } else {
        return -1;
    }
};

export const FindReturnMetrics = (row: WholesaleDataType) => {
    if (row.SizeTier === "Unknown") {
        row.returnProcessingFee = -1;
        row.returnAdminFee = -1;
        row.returnRemovalFee = -1;
        row.returnCostWriteOff = -1;
        row.returnProcessingFlatWSFee = -1;
        row.returnShipBackFee = -1;
        row.totalReturnCost = 2;
    } else {
        // all refunds
        const returnProcessingFee = FindReturnFee(row.SizeTier, row.adjustedWeight);
        const returnAdminFee = Math.min(5, getRefFeeValueWS(row) * 0.2); // amazon's formula
        const pickPackFee = row.Fees; // pick and pack fee is lost on return

        // unfulfillable
        const removalFee = FindRemovalFee(row.SizeTier, row.adjustedWeight);
        const costWriteOff = 0.5 * row.AskPrice; // we assume 30% of item's cost is lost straight up when it's deemed unfulfillable
        const processingFlatFee = 0.5; // cost of warehouse processing
        const shipBackFee = 0.5 * row.adjustedWeight * 0.7; // 70% of "unsellable" are shipped back to Amazon, at 0.5 / lb

        // final
        const singularReturnCost =
            row.returnRate * (returnProcessingFee + returnAdminFee + pickPackFee) +
            row.returnUnsellableRate * (removalFee + costWriteOff + processingFlatFee + shipBackFee);

        row.returnProcessingFee = returnProcessingFee;
        row.returnAdminFee = returnAdminFee;
        row.returnRemovalFee = removalFee;
        row.returnCostWriteOff = costWriteOff;
        row.returnProcessingFlatWSFee = processingFlatFee;
        row.returnShipBackFee = shipBackFee;
        row.totalReturnCost = singularReturnCost;
    }
};

export const WS_POSSIBLE_ISSUES = [
    "Orphaned (?)",
    "Check ParentRevs",
    "Lacks Dims",
    "Special oversize",
    "Dangerous Parent ROI",
    "RefFee Mismatch",
    "Fees Differ",
    "Fully Bought",
    "Pack Item",
    "SellPrice Deviation",
    "NoRank",
    "MAP Violation",
    "Past MAP Violation",
    "Above MAP",
    "Restricted",
    "Hazmat",
    "Duplicate UPC",
    "Advertised",
    "Coupon Exists",
];

export const POSSIBLE_STAGES_WS = ["STAGE_01", "STAGE_02", "STAGE_03", "STAGE_X", "STAGE_Y"];

const FindHighestRevParent = (data: WholesaleDataType[], parentAsin: string) => {
    const vars = data.filter((row) => row.parentASIN === parentAsin && row.variationChecked);
    const maxRevs = Math.max(...vars.map((row) => row.variationReviewsTotal), -1);

    return maxRevs;
};

const uniqBy = (arr: any[], predicate: any) => {
    const cb = typeof predicate === "function" ? predicate : (o: any) => o[predicate];

    return [
        ...arr
            .reduce((map, item) => {
                const key = item === null || item === undefined ? item : cb(item);

                map.has(key) || map.set(key, item);

                return map;
            }, new Map())
            .values(),
    ];
};

const FindVarSum = (data: WholesaleDataType[], parentAsin: string) => {
    const vars = uniqBy(
        data.filter((row) => row.parentASIN === parentAsin && row.variationChecked),
        "ASIN"
    );
    const totalRevs = vars.reduce((acc, curr) => (acc += curr.variationReviews), 0);

    return totalRevs;
};

export const FindStages = (row: WholesaleDataType) => {
    row.Stages = [];

    if (
        (row.EstSales! >= 1 && row.ROI2 > 25 && row.variationReviews > 1 && row.Profit > 2) ||
        ((!row.parentASIN || row.parentASIN === row.ASIN) && row.EstSales! >= 1 && row.ROI2 > 25 && row.reviewCount > 10 && row.Profit > 2)
    ) {
        row.Stages.push("STAGE_01");
    }

    if (
        row.saved === false &&
        row.offerCount <= 0 &&
        (row.variationReviews > 1 || ((!row.parentASIN || row.parentASIN === row.ASIN) && row.reviewCount > 10))
    ) {
        row.Stages.push("STAGE_02");
    }

    if (
        row.saved === false &&
        (!row.parentASIN || row.parentASIN === row.ASIN) &&
        row.salesRankDrops > 5 &&
        row.ROI2 > 25 &&
        row.reviewCount > 10
    ) {
        row.Stages.push("STAGE_03");
    }

    if (
        row.saved === true &&
        row.EstSales &&
        row.EstSales > 0 &&
        (row.BuyQTY + row.InvQuantity + row.IncomingQuantity30D + row.ShipLater + Math.max(row.Discrepancy, 0)) / row.EstSales >
        (row.targetDIS >= 0 ? 2 * row.targetDIS : 4)
    ) {
        row.Stages.push("STAGE_X");
    }
};

export const CreateIssueNecessities = (data: WholesaleDataType[]) => {
    let parentAsinMap: {
        [key: string]: WholesaleDataType[];
    } = {};
    let upcAsinMap: {
        [key: string]: WholesaleDataType[];
    } = {};
    let totalOrderAmount = 0;

    data.forEach((row) => {
        if (row.parentASIN) {
            if (parentAsinMap[row.parentASIN]) {
                parentAsinMap[row.parentASIN].push(row);
            } else {
                parentAsinMap[row.parentASIN] = [row];
            }
        } else {
            if (parentAsinMap[row.ASIN]) {
                parentAsinMap[row.ASIN].push(row);
            } else {
                parentAsinMap[row.ASIN] = [row];
            }
        }

        upcAsinMap[row.UPC] = upcAsinMap[row.UPC] ? [...upcAsinMap[row.UPC], row] : [row];

        totalOrderAmount += row.OrderAmount * +row.saved!;
    });

    return { parentAsinMap, totalOrderAmount, upcAsinMap };
};

export const FindIssues = (
    row: WholesaleDataType,
    parentAsinMap: { [key: string]: WholesaleDataType[] },
    upcAsinMap: { [key: string]: WholesaleDataType[] },
    totalOrderAmount: number
) => {
    row.Issues = [];
    const parentAsins = parentAsinMap[row.parentASIN || row.ASIN] || [row];
    // const parentAsins = [row];

    if (
        ((row.rootCategory === "Clothing, Shoes & Jewelry" || row.rootCategory === "Sports & Outdoors") &&
            (row.parentASIN === "" || row.parentASIN === row.ASIN)) ||
        (row.reviewCount > 0 && row.reviewCount90Avg > 0 && row.reviewCount <= 0.75 * row.reviewCount90Avg)
    ) {
        row.Issues.push("Orphaned (?)");
    }

    if (row.EstSales! <= 0 && row.reviewCount > 25 && row.reviewCount > 1.01 * row.reviewCount90Avg) {
        row.Issues.push("NoRank");
    }

    if (row.parentASIN?.length > 0 && row.parentASIN !== row.ASIN && row.variationChecked) {
        const maxRevs = FindHighestRevParent(parentAsins, row.parentASIN);
        const totalRevs = FindVarSum(parentAsins, row.parentASIN);

        if ((maxRevs > -1 && 1.1 * row.variationReviewsTotal < maxRevs) || totalRevs > 1.1 * row.variationReviewsTotal) {
            row.Issues.push("Check ParentRevs");
        }
    }

    if (row.SizeTier === "Unknown") {
        row.Issues.push("Lacks Dims");
    }

    if (row.SizeTier === "Special oversize" || row.shipDimWeight > 48) {
        row.Issues.push("Special oversize");
    }

    if (row.refFeeSPAPIBase > 0 && row.refFeeSPAPIBase !== row.sellPrice) {
        row.Issues.push("RefFee Mismatch");
    }

    // write a function that pushes an Issue when the difference between refFeeSPAPI and refFeeWS is greater than 0.10
    if (row.refFeeSPAPI > 0 && Math.abs(row.refFeeSPAPI - row.refFeeWS) > 0.1) {
        row.Issues.push("Fees Differ");
    }

    if (row.BuyQTY === row.onHand) {
        row.Issues.push("Fully Bought");
    }

    if (row.packQuant && row.packQuant > 1) {
        row.Issues.push("Pack Item");
    }

    const avgParentSellPrice = parentAsins
        .filter((prod) => prod.sellPrice > 0)
        .reduce((acc, curr, _, { length }) => (acc += curr.sellPrice / length), 0);

    if (
        row.parentASIN &&
        row.parentASIN !== row.ASIN &&
        row.sellPrice > 0 &&
        avgParentSellPrice > 0 &&
        (row.sellPrice > avgParentSellPrice * 1.15 || avgParentSellPrice * 0.85 > row.sellPrice)
    ) {
        row.Issues.push(`SellPrice Deviation`);
    }

    if (row.isRestricted) {
        row.Issues.push(`Restricted`);
    }

    if (row.isHazmat) {
        row.Issues.push(`Hazmat`);
    }

    if (row.Supplier_MAP) {
        const parsedMapPrice = parseFloat(row.Supplier_MAP.replace(/[^0-9.-]+/g, ""));
        if (row.currentBuyBoxPrice > 0 && parsedMapPrice > row.currentBuyBoxPrice) {
            row.Issues.push(`MAP Violation`);
        } else if (row.sellPrice > 0 && parsedMapPrice > row.sellPrice) {
            row.Issues.push(`Past MAP Violation`);
        }

        if (row.currentBuyBoxPrice > 0 && row.currentBuyBoxPrice > parsedMapPrice) {
            row.Issues.push(`Above MAP`);
        }
    }

    if (row.AdsRunning) {
        row.Issues.push(`Advertised`);
    }

    if (
        (row["One Time Coupon: Absolute"] && row["One Time Coupon: Absolute"] !== "-") ||
        (row["One Time Coupon: Percentage"] && row["One Time Coupon: Percentage"] !== "-")
    ) {
        row.Issues.push(`Coupon Exists`);
    }

    // if there is a row the same UPC but with a different ASIN, push an issue
    // if (Array.from(new Set((upcAsinMap[row.UPC] ?? [row]).map((item) => item.ASIN))).length > 1) {
    if ((upcAsinMap[row.UPC] ?? [row]).length > 1) {
        row.Issues.push(`Duplicate UPC`);
    }

    // this really should not be here
    row.FracOrderAmount = totalOrderAmount > 0 ? (row.OrderAmount / totalOrderAmount) * 100 : 0;

    // parent order amount
    const parentOrderAmount = parentAsins.reduce((acc, curr) => (acc += curr.OrderAmount), 0);
    row.ParentOrderAmount = parentOrderAmount > 0 ? parentOrderAmount : 0;
    row.ParentFracOrdAmt = totalOrderAmount > 0 ? (parentOrderAmount / totalOrderAmount) * 100 : 0;
    // find parent sales, by finding the average variation sellPrice excluding -1s
    const avgParentSales = parentAsins
        .filter((prod) => prod.EstSalesTotal! > 0)
        .reduce((acc, curr, _, { length }) => (acc += curr.EstSalesTotal! / length), 0);
    const sumParentRevs = parentAsins.filter((prod) => prod.variationChecked).reduce((acc, curr) => (acc += curr.variationReviews), 0);

    // sum up the BuyQTY per size
    const parentDistributionChartData = parentAsins.reduce((acc, curr) => {
        if (curr.keepaSize) {
            acc[curr.keepaSize] = acc[curr.keepaSize] ? acc[curr.keepaSize] + curr.BuyQTY : curr.BuyQTY;
        } else {
            acc["N/A"] = acc["N/A"] ? acc["N/A"] + curr.BuyQTY : curr.BuyQTY;
        }

        return acc;
    }, {} as { [key: string]: number });

    row.parentDistributionChartData = parentDistributionChartData;

    row.ParentSellPrice =
        parentAsins.filter((item) => item.sellPrice > 0).reduce((acc, curr, _, { length }) => (acc += curr.sellPrice! / length), 0) || -1;
    row.EstParentSales = avgParentSales >= 0 ? avgParentSales * row.ParentSellPrice : -1;

    if (row.variationChecked && row.EstParentSales > 10000 && sumParentRevs < 0.75 * row.reviewCount) {
        row.Issues.push(`Use KeepaRevs`);
    }

    // find average parent ROI
    const avgParentROI = parentAsins.filter((prod) => prod.ROI2 !== -2).reduce((acc, curr, _, { length }) => (acc += curr.ROI2 / length), 0);
    row.parentAvgROI = avgParentROI;

    if (row.saved === true && row.parentAvgROI < row.desiredRoi) {
        row.Stages.push("STAGE_Y");
    }

    // find the percentage of items below desired ROI
    const belowDesiredROI = parentAsins.filter((prod) => prod.ROI2 < row.desiredRoi).length;
    row.parentBelowDesiredROI = belowDesiredROI / parentAsins.length;
    if (row.parentBelowDesiredROI > 0.4) {
        row.Issues.push(`Dangerous Parent ROI`);
    }

    row.parentOrderStage01Perc = (parentAsins.filter((prod) => prod.Stages.includes("STAGE_01")).length / parentAsins.length) * 100;

    row.parentSpendStage01 = parentAsins
        .filter((prod) => prod.Stages.includes("STAGE_01"))
        .reduce((acc, curr) => (acc += curr.EstSales! * curr.AskPrice), 0);
};
