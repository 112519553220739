import {StoreElement} from "../types/storeTypes";

export const normalize = <T>(data: T[], key: keyof T): StoreElement<T> => {
    const byId: {[key: string]: T} = {};
    const allIds: string[] = [];

    data.forEach((item: T) => {
        byId[item[key] as string] = item;
        allIds.push(item[key] as string);
    });

    return {byId, allIds};
};
